import React, { Fragment, Component } from 'react';


import classes from './HouseCard.module.scss';
// import secHeadClasses from '../LandingPageParts/sectionsHeaders.module.scss';

import { Link } from 'react-router-dom'; //NavLink
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
// import RequestHouse from "../RequestHouse";
import Modal from '../LoggedInModal';

import { connect } from 'react-redux';

// import { paymentPerMonth } from '../HousePageParts/FinancingPlan';

const HouseCard = (props) => (
    <Fragment>
        <CardDetails unit={props} />
    </Fragment>
);

class CardView extends Component {

    paymentAmountPerPeriod = (p, r, n) => {
        return Math.ceil((p * (r * Math.pow(r + 1, n) / (Math.pow(r + 1, n) - 1)))/100)*100;
    };

    paymentPerMonth = (years, cash, downpayment) => {
        const n = 12 * years;
        const r = 0.14 / 12;
        const p = cash - downpayment;

        return this.paymentAmountPerPeriod(p, r, n);
    };
    state = {
        isFavorite: false,
        isLogged: false,
    }
    componentDidMount() {
        // console.log(this.paymentPerMonth(10,1000000,200000));
        if (navigator.onLine && this.props.authUser) {
            let userId = localStorage.getItem('loggedUserId');
            this.props.firebase.getFavorites(userId)
                .then(houses => {
                    let favoritesHouses = houses.docs.map(house => house.data().unitRef.get())
                    favoritesHouses && favoritesHouses.map(house => house.then(doc => {
                        if (doc.data() && doc.data().id === this.props.unit.houseId) {
                            this.setState({
                                isFavorite: true
                            })
                        }
                    }));
                })
        } else {
            this.setState({ isFavorite: false })
        }

    }
    addToFavorites = (userId, houseId) => {
        this.props.unit.addToFavorites(userId, houseId)
    }
    deleteFromFavorites = (userId, houseId) => {
        this.props.unit.deleteFromFavorites(userId, houseId)
    }
    toggleFavorite = (change, houseId) => {
        const userId = localStorage.getItem('loggedUserId');
        this.setState({
            isFavorite: change
        })
        if (change === true) {
            this.addToFavorites(userId, houseId)
        } else {
            this.deleteFromFavorites(userId, houseId)
        }
    }
    
    render() {
        // const minDownPayment = Number(this.props.unit.price).toLocaleString('en', { useGrouping: true });
        const cashPrice = this.props.unit.price;
        const minDownPayment = Number(cashPrice * 0.2);
        const showMinDownPayment = minDownPayment.toLocaleString('en', { useGrouping: true });
        const minMonthlyPayment = Number(this.paymentPerMonth(15, cashPrice, minDownPayment));
        const showMinMonthlyPayment = minMonthlyPayment.toLocaleString('en', { useGrouping: true });
        return (
            // <div className={`col-12 col-lg-${this.props.unit.unitSize} mb-3 p-1 ${classes.cardContainer}`}>
            <div className={`${classes.cardContainer}`}>
                <div className={`card ${classes.cardStyle}`}>
                    <div className={classes.cardImgContainer}>
                        <Link to={'/house/' + this.props.unit.houseId}>
                            <img className="card-img-top" src={this.props.unit.picture} alt=".." />
                        </Link>
                    </div>
                    {/* <span id="topLeft">
                        <RequestHouse houseId={this.props.unit.houseId} />
                    </span> */}
                    <span className={`${classes.unitDeliveryDateContainer}`}>
                        {
                            this.props.unit.readyToMove
                                ?   <span className={classes.readyToMoveSpan}>
                                        <h6 className="text-uppercase">
                                            <span className="badge badge-primary py-2 px-2">
                                                <img
                                                src={require('../../assets/electricity.svg')}
                                                alt='ready to move'/>
                                                Ready To move
                                            </span>
                                        </h6>
                                    </span>
                                : this.props.unit.deliveryDate
                                    ? <span className={`${classes.unitDeliveryDate}`}>
                                        {/* <FontAwesomeIcon icon={faCalendarWeek} /> */}
                                        <img src={require('../../assets/houseCard/delivery.png')} alt="..."></img>
                                        <span className="text-uppercase">{this.props.unit.deliveryDate}</span>
                                    </span>
                                    : null
                        }
                    </span>
                    <span className={classes.topRight}>
                        <Modal
                            houseId={this.props.unit.houseId}
                            isFavorite={this.state.isFavorite}
                            toggleFavorite={this.toggleFavorite}
                        />
                    </span>
                    <div className={`card-body ${classes.cardBody}`}>
                        <div className={`clearfix ${classes.priceAndDateContainer}`}>
                            <span className={`${classes.unitPrice} ${classes.minDownPayment}`}>
                                {/* {this.props.unit.price}<span>EGP</span> */}
                                <span>
                                    {showMinDownPayment}
                                </span>
                                <span>EGP</span>
                                <p>Min down payment</p>
                            </span>
                            <span className={`${classes.unitPrice} ${classes.minMonthlyPayment}`}>
                                {/* {this.props.unit.price}<span>EGP</span> */}
                                <span>
                                    {showMinMonthlyPayment}
                                </span>
                                <span>EGP</span>
                                <p>Min monthly payment</p>
                            </span>
                        </div>
                        <div className={`clearfix ${classes.unitInfo}`}>
                            <span>
                                <img src={require('../../assets/houseCard/home.png')} alt="..."></img>
                                <span>
                                    {this.props.unit.house_type}
                                </span>
                            </span>
                            <span>
                                <img src={require('../../assets/houseCard/space.png')} alt="..."></img>
                                <span>
                                    {this.props.unit.bua} M<sup>2</sup>
                                </span>
                            </span>
                            <span>
                                <img src={require('../../assets/houseCard/bedroom.png')} alt="..."></img>
                                <span>
                                    {this.props.unit.bedrooms}
                                </span>
                            </span>
                            <span>
                                <img src={require('../../assets/houseCard/bathroom.png')} alt="..."></img>
                                <span>
                                    {this.props.unit.bathrooms}
                                </span>
                            </span>

                        </div>
                        <div className={`clearfix ${classes.addressAndDeveloper}`}>
                            <span className={classes.unitAddress}>
                                <img src={require('../../assets/houseCard/pin.svg')} alt="..."></img>
                                <p>
                                    {this.props.unit.address}
                                </p>
                            </span>
                            <span className={classes.developerLogo}>
                                {
                                    this.props.unit.developer_name ? this.props.unit.developer_name :
                                        <img src="./sodic.jpg" alt="developer logo"></img>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
});

const CardDetails = compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps),
)(CardView);

export default HouseCard;
export { CardDetails };