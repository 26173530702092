import React, { Fragment } from 'react'
import HouseCard from '../HouseCard'
import classes from './Listings.module.scss'
import { compose } from 'redux'
import { withFirebase } from '../../components/Firebase'

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
]
const Listings = props => {
  const addToFavorites = (userId, houseId) => {
    props.firebase.addToFavorites(userId, houseId)
  }
  const deleteFromFavorites = (userId, houseId) => {
    props.firebase.deleteFromFavorites(userId, houseId)
  }
  const allUnits =
    props.units &&
    props.units.map(unit => {
      const deliveryMonth = monthNames[unit.unit.delivery_date.month - 1]
      const deliveryDate = deliveryMonth + ' ' + unit.unit.delivery_date.year

      return (
        <HouseCard
          key={unit.id}
          viewType={props.viewType}
          houseId={unit.id}
          selectedClass='nonSelectedU'
          price={unit.financing.cash_price}
          developer_name={unit.unit.developer_name}
          picture={unit.unit.picture}
          deliveryDate={deliveryDate}
          readyToMove={unit.unit.ready_delivery}
          address={unit.unit.address}
          house_type={unit.unit.house_type}
          city={unit.unit.city}
          bedrooms={unit.unit.bedrooms}
          bathrooms={unit.unit.bathrooms}
          land={unit.unit.land}
          bua={unit.unit.bua}
          unitSize={props.unitSize}
          addToFavorites={addToFavorites}
          deleteFromFavorites={deleteFromFavorites}
        />
      )
    })

  let units = <Fragment>{allUnits}</Fragment>

  return (
    <div className={`container-fluid p-0 m-0`}>
      <div className={`row mx-0 p-0 mb-3 ${classes.Listings}`}>{units}</div>
    </div>
  )
}

export default compose(withFirebase)(Listings)
