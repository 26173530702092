import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import userReducer from './userReducer';
import unitReducer from './unitReducer';
import routerReducer from './routerReducer';
import landingScrollReducer from './landingScrollReducer';
import landingToBuyReducer from './landingToBuyReducer';
import searchReducer from './searchReducer';
import filterReducer from './filterReducer';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  unitState: unitReducer,
  routerState: routerReducer,
  landingNavState: landingScrollReducer,
  // landingToBuyState: landingToBuyReducer,
  searchState: landingToBuyReducer,
  searchState: searchReducer,
  filterState: filterReducer,
});
export default rootReducer;