import { addSearchParam, removeSearchParam, clearSearch } from '../Actions/searchActions';
import { ADD_SEARCH, REMOVE_SEARCH, CLEAR_SEARCH } from '../Constants/searchConstants';

const INITIAL_STATE = {
    // filters: [], // array of objects  for filters without range
    // FiltersObjectsRange: [], // array of objects for filters with range
    //developerFilter
    mobileFilter: true,
    // paymentTypes: [],
    developerPlace: null,
    developerName: null,
    locationState: {
      triggered: false,
      selected: 0,
      value: null
    },
    textlocationState: {
      triggered: false,
      selected: 0,
      value: null
    },
    developerState: {
      triggered: false,
      selected: 0,
      value: null
    },
    compoundState: {
      triggered: false,
      selected: 0,
      value: null
    },
    propertyTypeState: {
      triggered: false,
      selected: 0,
      value: []
    },
    // pricePaymentType: 'monthlyPayment',
    // true meant it's disabled
    monthlyPaymentSearch: false,
    downpaymentSearch: false,
    rangeInputDownpaymentValue: {
      min: 0,
      max: 3000000
    },
    rangeInputMonthlyPaymentValue: {
      min: 5000,
      max: 100000
    },
    priceState: {
      triggered: false,
    },
    spaceState: {
      triggered: false,
      min: 0,
      max: 0
    },
    minSpaceVal: 0,
    maxSpaceVal: 0,
    roomState: {
      triggered: false,
      selected: 0,
      value: []
    },
    bathState: {
      triggered: false,
      selected: 0,
      value: []
    },
    floorState: {
      triggered: false,
      selected: 0,
      value: []
    },
    deliveryState: {
      triggered: false,
      selected: 0,
      value: []
    },
    downpaymentMinInput: 0,
    downpaymentMaxInput: 2000000,
    downpaymentStep: 100000,

    monthlyPaymentMinInput: 0,
    monthlyPaymentMaxInput: 100000,
    monthlyPaymentStep: 1000,

    propertyTypeText: 'Property Type',
    roomsText: 'Rooms',
    // cities: [],
    // places: [],
    //Flags for knowning what is filter is firring
    filtersFlags: {
      rooms: false,
      baths: false,
      price: false
    },
    sortBy: 'Best Match',

    // More filters State
    bedrooms: {
      values: []
    },
    bathrooms: {
      values: []
    },
    delivery_date: {
      values: []
    },
    land: {
      value: { min: 0, max: 800 } //for input range space
    },
    developer_name: {
      values: []
    },
    compound_name: {
      values: []
    },
  };
function searchReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case ADD_SEARCH: {
      return addSearchParam(state, action);
    }
    case REMOVE_SEARCH: {
      // console.log(action)
      return removeSearchParam(state, action);
    }
    case CLEAR_SEARCH: {
      // console.log('search')
      return clearSearch(INITIAL_STATE);
    }
    default:
      return state;
  }
}

export default searchReducer;