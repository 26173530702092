import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../../FilterBar/FilterBar.module.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
class FilterLocation extends Component {
  render () {
    const {
      locationState,
      toggleState,
      searchFilter,
      toggleSearchHandler,
      resetFormHandler,
      cities,
      places,
      handleLocationSubmit
    } = this.props
    const allLocations = cities.map((city, i) => {
      const cityPlaces =
        places.length > 0 &&
        places[i].map(cityPlace => {
          return (
            <li
              className={`${classes.cityPlace} text-capitalize`}
              key={cityPlace}
              onClick={event => handleLocationSubmit(event, this)}
              data-value={cityPlace}
            >
              {cityPlace}
            </li>
          )
        })
      return (
        <div
          className='accordion'
          id={`locationAccordion${i}`}
          key={`location${i}`}
        >
          <div>
            <button
              className={`${classes.city } text-capitalize`}
              ref={`locationCollapseBtn${i}`}
              type='button'
              data-toggle='collapse'
              data-target={`#locationCollapse${i}`}
              aria-expanded='false'
              aria-controls={`locationCollapse${i}`}
            >
              {city}
            </button>
            <div
              id={`locationCollapse${i}`}
              ref={`locationCollapse${i}`}
              className='collapse'
              aria-labelledby='headingOne'
              data-parent={`#locationAccordion${i}`}
            >
              <ul className={classes.cityPlacesList}>{cityPlaces}</ul>
            </div>
          </div>
        </div>
      )
    })
    return (
      <div className={`${classes.optionsContainer} col-lg p-0`}>
        <button
          className={`${classes.locationToggleBtn} ${
            locationState.triggered ? `${classes.triggered}` : ''
          } ${!toggleState.location ? `${classes.active}` : ''}`}
          onClick={() => toggleSearchHandler('location')}
        >
          {/* <FontAwesomeIcon icon={faMapSigns} /> */}
          <img
            src={require('../../../assets/filterbar/location.svg')}
            alt='...'
            className={classes.homeIcon}
          ></img>
          <span>{locationState.value || `Location`}</span>
          <span
            className={classes.resetFormBtn}
            onClick={event => resetFormHandler(event, 'location', this)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </button>
        <div
          className={`${classes.searchOptions} ${classes.searchForLocations}`}
          hidden={toggleState.location}
        >
          <input
            type='text'
            className={`form-control ${classes.locationInput}`}
            name='searchItem'
            id='searchItem'
            onChange={searchFilter}
            placeholder='Enter Area'
          />
          {allLocations}
        </div>
      </div>
    )
  }
}

export default FilterLocation
