import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import './index.scss'
import classes from './reduxLanding.module.scss';

import { FilterProperty } from '../../../components/FilterBarParts'
import * as Methods from '../../../components/FilterBar/utils'

class ReduxLandingSearch extends Component {
  state = {
    textSearch: '',
    textlocationState: {
      triggered: false,
      selected: 0,
      value: null
    },
    toggleState: {
      propertyType: true
    },
    propertyTypeState: {
      triggered: false,
      selected: 0,
      value: []
    }
  }

  addNewFilter = (type, values = []) => {
    //create new filter object
    let newFilter = {
      type: type, //type based on field name in DB
      values: values //value will filter based on them
    }

    let filters = this.props.filters // existing without range  filters objects on state
    let flag = false // for triggered if filter already exists
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === newFilter.type) {
        filters[i] = newFilter
        flag = true //change flag to true, this mean the filter exists before
        break //out of loop
      }
    }
    if (!flag) {
      filters.push(newFilter)
    }

    this.props.onModifyFilters(filters);
  }

  toggleSearchHandler = type => {
    Methods.toggleSearchHandler(type, this)
  }
  handleChange = event => {
    // Methods.handleChange(event, this)
    if (event) {
      // let oldState = { ...obj.props.searchParams[[event][0].target.dataset.statetype] }
      let oldValues = this.state.propertyTypeState.value
      const inputValue = event.target.value
  
      // to know whether the value is being added or removed
      // true = added
      if (event.target.checked === true) {
        this.setState({
          propertyTypeState: {
            ...this.state.propertyTypeState,
            triggered: true,
            value: [...oldValues, inputValue]
          }
        })
      }
      // to know whether the value is being added or removed
      // false = removed
      else if (event.target.checked === false) {
        const index = oldValues.indexOf(inputValue)
        if (index > -1) {
          oldValues.splice(index, 1)
        }
        let triggered = false;
        if (oldValues.length > 0) {
          triggered = true
        }
        this.setState({
          propertyTypeState: {
            ...this.state.propertyTypeState,
            triggered,
            value: [...oldValues]
          }
        })
      }
    }
  }
  search = e => {
    e.preventDefault()
    const propertyTypeState = this.state.propertyTypeState
    const locationState = this.state.textlocationState
    // console.log("this.state.textSearch: ",this.state.textSearch);
    
    // this.props.onSearch({ propertyTypeState, locationState })

    if (this.state.propertyTypeState.triggered) {
      // this.props.onAddSearch({...propertyTypeState})
      this.props.onAddSearch({
        propertyTypeState: {
          ...this.state.propertyTypeState
        }
      })
      this.addNewFilter('house_type', this.state.propertyTypeState.value)
    }

    if (this.state.textlocationState.triggered) {
      this.props.onAddSearch({
        locationState: {
          ...this.state.textlocationState
        }
      })

      let locationValues = this.state.textlocationState.value.split(' ')
      locationValues = locationValues.map(val => val.toLowerCase())
      this.addNewFilter('city', [...locationValues])
      // this.addNewFilter('place', [...locationValues])
    }

    this.props.history.push('/search')
  }

  filterUnits = (units = [], key, values = []) => {
    let newUnits = []
    for (let index = 0; index < values.length; index++) {
      newUnits = units.filter(
        unit =>
          unit.unit[key] &&
          unit.unit[key].toLowerCase().includes(values[index].toLowerCase())
      )
      newUnits = new Set(newUnits)
      newUnits = [...newUnits]
    }
    return newUnits
  }

  handleTextInputChange = e => {
    e.preventDefault()
    if (e.target.value) {
      this.setState({
        // [e.target.id]: e.target.value
        textlocationState: {
          triggered: true,
          selected: 0,
          value: e.target.value
        }
      })
    }
  }
  onClick = e => {
    this.setState({
      clicked: !this.state.clicked
    })
  }
  handleDropDownCollapse = e => {
    if (this.state.toggleState.propertyType === false) {
      this.setState({
        toggleState : {
          propertyType : true
        }
      })
    }
  }
  render () {
    // const { textSearch } = this.state
    // const isVilad = textSearch.trim() === ''
    return (
      <div className={classes.searchContainer}>
              <div className={classes.propertyType}>
                <FilterProperty
                  propertyTypeState={this.state.propertyTypeState}
                  toggleState={this.state.toggleState}
                  toggleSearchHandler={this.toggleSearchHandler}
                  handleSubmit={this.handleSubmit}
                  resetFormHandler={this.resetFormHandler}
                  handleChange={this.handleChange}
                />
              </div>
              <div className={classes.inputSearchContainer}>
                <svg className={classes.locationIcon} viewBox='-15 0 512 512' xmlns='http://www.w3.org/2000/svg'>
                  <path d='m426.191406 307.890625h-85.539062c35.859375-56.863281 54.019531-108.464844 54.019531-153.679687 0-85.03125-69.160156-154.210938-154.171875-154.210938s-154.171875 69.179688-154.171875 154.210938c0 45.214843 18.160156 96.820312 54.019531 153.679687h-85.539062l-54.808594 204.109375h481zm-309.871094-153.679687c0-68.492188 55.707032-124.210938 124.179688-124.210938s124.179688 55.71875 124.179688 124.210938c0 44.492187-22.234376 99.851562-64.292969 160.09375-23.050781 33.039062-46.710938 59.835937-59.890625 73.996093-13.160156-14.136719-36.785156-40.894531-59.882813-74-42.058593-60.238281-64.292969-115.597656-64.292969-160.089843zm-38.511718 183.679687h82.699218c27.472657 38.359375 52.644532 66.089844 79.992188 93.109375 26.472656-26.148438 51.222656-52.941406 79.996094-93.109375h82.699218l38.695313 144.109375h-402.78125zm0 0' />
                  <path d='m240.5 242.476562c44.101562 0 79.980469-35.890624 79.980469-80 0-44.113281-35.878907-80-79.980469-80s-79.980469 35.886719-79.980469 80c0 44.109376 35.878907 80 79.980469 80zm0-130c27.5625 0 49.988281 22.429688 49.988281 50 0 27.570313-22.425781 50-49.988281 50s-49.988281-22.429687-49.988281-50c0-27.570312 22.425781-50 49.988281-50zm0 0' />
                </svg>
                <input
                  onClick={this.handleDropDownCollapse}
                  onChange={this.handleTextInputChange}
                  type='text'
                  id='textSearch'
                  placeholder='Enter Location'
                />
              </div>
                <button 
                  className={classes.searchBtn} 
                  // id='searchBtn' 
                  onClick={this.search} 
                  // disabled={isVilad}
                >
                  <span>Search</span>
                  <span>
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.filterState.filters,
})
const mapDispatchToProps = dispatch => ({
  // onSearch: obj => dispatch({ type: 'LANDING_TO_BUY', payload: obj }),
  onAddSearch: searchParam => dispatch({type: 'ADD_SEARCH', payload: searchParam}),
  onModifyFilters: filters => dispatch({type: 'MODIFY_FILTERS', payload: filters}),
  // onSearch: searchParam => dispatch({type: 'ADD_SEARCH', payload: searchParam})
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ReduxLandingSearch)
