import { modifyFilters, modifyRangeFilters, clearFilters, clearMoreFilters } from '../Actions/filterActions';
import { MODIFY_FILTERS, MODIFY_RANGE_FILTERS, CLEAR_FILTERS, CLEAR_MORE_FILTERS } from '../Constants/filterConstants';

const INITIAL_STATE = {
    filters: [], // array of objects  for filters without range
    rangeFilters: [], // array of objects for filters with range
  };
function filterReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case MODIFY_FILTERS: {
      // console.log(INITIAL_STATE)
      console.log(action)
      // console.log(action.payload)
      return modifyFilters(state, action);
    }
    case MODIFY_RANGE_FILTERS: {
      console.log(action)
      return modifyRangeFilters(state, action);
    }
    case CLEAR_FILTERS: {
      return clearFilters();
    }
    case CLEAR_MORE_FILTERS: {
      alert(33333)
      // return clearMoreFilters(state);
    }
    default:
      return state;
  }
}

export default filterReducer;