import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { withFirebase } from '../../components/Firebase'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

//import needed components
import Footer from '../../components/Footer'
// import HouseCard from '../../components/HouseCard';
import {
  FinancingPlan,
  HouseHeader,
  HouseSlider,
  AboutHouse
} from '../../components/HousePageParts'


const HousePage = props => (
  <Fragment>
    <HouseDetail {...props} />
  </Fragment>
)

class ViewDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      houseDetails: {},
      error: '',
      otherUnitsInProject: []
    }
  }
  async componentDidMount () {
    if (this.props.location.pathname.includes('house')) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }
    if (navigator.onLine) {
      let houseId = this.props.match.params.house_id
      const house = await this.props.firebase.houseDetails(houseId)
      if (house.data()) {
        this.setState({
          houseDetails: house.data()
        })
      }
    }
  }
  addToFavorites = (userId, houseId) => {
    this.props.firebase.addToFavorites(userId, houseId)
  }
  deleteFromFavorites = (userId, houseId) => {
    this.props.firebase.deleteFromFavorites(userId, houseId)
  }
  render () {
    const { houseDetails, error } = this.state
    // console.log("From New House Page: ", this.props.firebase);
    // console.log("houseDetails: ", houseDetails);
    return (
      <Fragment>
        <div className='container'>
          {/* House header */}
          <HouseHeader
            houseDetails={houseDetails}
            authUser={this.props.authUser}
            addToFavorites={this.addToFavorites}
            deleteFromFavorites={this.deleteFromFavorites}
            getFavorites={this.props.firebase.getFavorites}
          />

          <div className='row'>
            <div className='col-12 col-md-7 '>
              <div className='container-fluid p-0 mr-2'>
                <HouseSlider unitData={houseDetails.unit} />
                {/* Slider of Units */}
                <AboutHouse houseDetails={houseDetails} />
              </div>
            </div>
            <div className='col-12 col-md-5 '>
              <FinancingPlan
                houseDetails={houseDetails}
                authUser={this.props.authUser}
              />
            </div>
          </div>
          <div className='row'>{/* {ProjectUnits} */}</div>

          <p>{error && <p>{error.message}</p>}</p>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})

const HouseDetail = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(ViewDetails)

export default HousePage
export { HouseDetail }
