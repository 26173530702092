import { landingToBuy, resetSearchForm } from '../Actions/landingToBuyActions';
import { LANDING_TO_BUY, RESET_FORM } from '../Constants/landingToBuyConstants';

const INITIAL_STATE = {
    landingToBuy: {
        propertyTypeParams: [],
        locationParams: ''
    }
};
function landingToBuyReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case LANDING_TO_BUY: {
      return landingToBuy(state, action);
    }
    case RESET_FORM: {
      return resetSearchForm(state, action);
    }
    default:
      return state;
  }
}

export default landingToBuyReducer;