const applySetUsers = (state, action) => ({
    ...state,
    users: action.users,
});
const applySetUser = (state, action) => ({
    ...state,
    users: {
        ...state.users,
        [action.uid]: action.user,
    },
});

export {applySetUsers, applySetUser}