import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../../FilterBar/FilterBar.module.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import InputRange from 'react-input-range'

import './index.css'

class MoreFilters extends Component {
  state = {
    modal: false,
    bedrooms: [1, 2, 3, 4, 5, 6],
    bathrooms: [1, 2, 3, 4, 5, 6],
    delivery_dates: ['ready to move', 2020, 2021, 2022, 2023],
    show: false
  }
  // componentDidUpdate () {
  //   console.log(this.refs)
  //   // to uncheck all properties
  //   for (let e = 0; e < this.state.bedrooms.length; e++) {
  //     let _refz = 'bedrooms' + e
  //     this.refs[_refz].classList.remove(classes.numberSelect)
  //   }
  //   // to check selected properties
  //   for (let r = 0; r < this.props.searchParams.bedrooms.values.length; r++) {
  //     let x = this.state.bedrooms.indexOf(this.props.searchParams.bedrooms.values[r])
  //     let _ref = 'bedrooms' + x
  //     this.refs[_ref].classList.add(classes.numberSelect)
  //   }
  // }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }  
  // handle multi select options
  toggleClick = (value, type) => {
    if (this.props.searchParams[type].values.includes(value)) {
      console.log(11111)
      const values = [...this.props.searchParams[type].values].filter(val => val !== value)
      if (this.props.searchParams[type].values.length === 1) {
        this.props.removeFilter(type)
      }
      console.log(values)
      this.props.onAddSearch({
        [type]: {
          values
        }
      })
    } else {
      // if (!element.classList.contains(classes.numberSelect)) {
        console.log(22222)
        let values = [...this.props.searchParams[type].values]
        console.log(values)
        values.push(value)
        values.sort()
        this.props.onAddSearch({
          [type]: {
            values
          }
        })
        // console.log(values)
        // element.classList.add(classes.numberSelect)
        // let _ref = `bedrooms${i}`
        // this.refs[_ref].classList.add(classes.numberSelect)
      // } 
      // else {
      //   // بتتطبق امتى الحالة دى ؟
      //   alert(12345678654324567)
      //   // let values = this.props.searchParams[type].values
      //   // let targets = this.props.searchParams[type].targets

      //   // values.sort()
      //   // values = values.filter(item => item !== value)

      //   // targets = targets.filter(el => el !== element)

      //   // // this.setState({
      //   // //   [type]: {
      //   // //     values,
      //   // //     targets
      //   // //   }
      //   // // })
      //   // this.props.onAddSearch({
      //   //   [type]: {
      //   //     values,
      //   //     targets
      //   //   }
      //   // })
      //   // element.classList.remove(classes.numberSelect)
      // }
    }
  }
  // toggleClick = (e, value, key) => {
  //   const element = e.target
  //   if (this.props.searchParams[key].values.includes(value)) {
  //     console.log(11111)
  //     const values = [...this.props.searchParams[key].values].filter(val => val !== value)
  //     const targets = [...this.props.searchParams[key].targets].filter(tar => tar !== element)
  //     if (this.props.searchParams[key].values.length === 1) {
  //       this.props.removeFilter(key)
  //     }
  //     // this.setState({
  //     //   [key]: {
  //     //     values,
  //     //     targets
  //     //   }
  //     // })
  //     this.props.onAddSearch({
  //       [key]: {
  //         values,
  //         targets
  //       }
  //     })
  //   } else {
  //     if (!element.classList.contains(classes.numberSelect)) {
  //       console.log(22222)
  //       let values = [...this.props.searchParams[key].values]
  //       let targets = [...this.props.searchParams[key].targets]
  //       values.push(value)
  //       values.sort()
  //       targets.push(element)
  //       // this.setState({
  //       //   [key]: {
  //       //     values,
  //       //     targets
  //       //   }
  //       // })
  //       this.props.onAddSearch({
  //         [key]: {
  //           values,
  //           targets
  //         }
  //       })
  //       // console.log(values)
  //       element.classList.add(classes.numberSelect)
  //     } 
  //     // below 'else statement' caused redux devtools to crash continuously!
  //     // the weird thing that the application never run into this statement !!
  //     // else {
  //     //   // بتتطبق امتى الحالة دى ؟
  //     //   console.log(12345678654324567)
  //     //   let values = this.props.searchParams[key].values
  //     //   let targets = this.props.searchParams[key].targets

  //     //   values.sort()
  //     //   values = values.filter(item => item !== value)

  //     //   targets = targets.filter(el => el !== element)

  //     //   // this.setState({
  //     //   //   [key]: {
  //     //   //     values,
  //     //   //     targets
  //     //   //   }
  //     //   // })
  //     //   this.props.onAddSearch({
  //     //     [key]: {
  //     //       values,
  //     //       targets
  //     //     }
  //     //   })
  //     //   element.classList.remove(classes.numberSelect)
  //     // }
  //   }
  // }

  // handle single select options (developer & compound)
  handleClick = (value, key) => {
    // this.setState({
    //   [key]: {
    //     values: [value],
    //     targets: [element]
    //   }
    // })
    this.props.onAddSearch({
      [key]: {
        values: [value]
      }
    })
  }

  applyFilter = e => {
    const filters = [
      'bedrooms',
      'bathrooms',
      'delivery_date',
      'land',
      'developer_name',
      'compound_name'
    ]
    // console.log(this.props.searchParams)
    for (let index = 0; index < filters.length; index++) {
      if (filters[index] === 'land') {
        this.props.addNewRangeFilter(
          filters[index],
          this.props.searchParams[filters[index]].value.min,
          this.props.searchParams[filters[index]].value.max
        )
      } else {
        if (this.props.searchParams[filters[index]].values.length > 0) {
          this.props.addNewFilter(filters[index], [
            ...this.props.searchParams[filters[index]].values
          ])
        }
      }
    }
    this.toggleModal()
  }

  clearFilters = e => {
    const filters = [
      'bedrooms',
      'bathrooms',
      'delivery_date',
      'developer_name',
      'compound_name'
    ]
    console.log(this.props.filters)
    for (let index = 0; index < filters.length; index++) {
      // this.setState({
      //   [filters[index]]: {
      //     values: [],
      //     targets: []
      //   }
      // })
      this.props.onRemoveSearch({
        [filters[index]]: {
          values: []
        }
      })
      // this.props.clearFilters()
      // above line should change to below line if clear filters buttons should only clear "more filters"
      // this.props.removeFilter(filters[index])
      // let targets = this.props.searchParams[filters[index]].targets
      // targets.map(target => target.classList.remove(classes.numberSelect))
    }
    // this.props.filters.map(filter => {
    //   if (filters.includes(filter.type)) {
    //     this.props.removeFilter(filter.type)
    //   }
    // })
    this.props.removeMoreFilters()
    // this.props.removeFilter('bathrooms')
    // this.props.removeFilter('delivery_date')
    // this.props.removeFilter('developer_name')
    // this.props.removeFilter('compound_name')
    // this.props.clearMoreFilters()
    this.toggleModal()
  }

  render () {
    // console.log('this is props from more filters: ', this.props.remove)

    const { units, place, toggleSearchHandler } = this.props

    const allBedrooms = this.state.bedrooms.map((option, i) => {
      return (
        <div key={option} className={`col-3 col-lg-2 py-1 px-1`}>
          <span
            key={`bedrooms${i}`}
            className={`${classes.numbers}
            ${
              this.props.searchParams.bedrooms.values.includes(option)
                ? `${classes.numberSelect}`
                : ''
            }
            py-3 px-4 `}
            onClick={e => this.toggleClick(option, 'bedrooms', i)}
          >
            {option}
          </span>
        </div>
      )
    })

    const allBathrooms = this.state.bathrooms.map((option, i) => {
      return (
        <div key={option} className={`col-3 col-lg-2 py-1 px-1`}>
          <span
            key={`bathrooms${i}`}
            className={`${classes.numbers}
            ${
              this.props.searchParams.bathrooms.values.includes(option)
                ? `${classes.numberSelect}`
                : ''
            }
            py-3 px-4 `}
            onClick={e => this.toggleClick(option, 'bathrooms', i)}
          >
            {option}
          </span>
        </div>
      )
    })

    // const allDeliveryDates = this.state.delivery_dates.map((option, i) => {
    //   return (
    //     <div key={option} className={`col-3 col-lg-2 py-1 px-1`}>
    //       <span
    //         key={`bathrooms${i}`}
    //         className={`${classes.numbers}
    //         ${
    //           this.props.searchParams.bathrooms.values.includes(option)
    //             ? `${classes.numberSelect}`
    //             : ''
    //         }
    //         py-3 px-4 `}
    //         onClick={e => this.toggleClick(option, 'bathrooms', i)}
    //       >
    //         {option}
    //       </span>
    //     </div>
    //   )
    // })

    // this is developer filter developer_name
    let developers = []
    if (units) {
      if (place !== null) {
        units.forEach(unit => {
          if (unit.unit.developer_name && unit.unit.place === place) {
            developers.push(
              <span
                className={`dropdown-item ${classes.dropdownItem}`}
                key={unit.id}
                onClick={e =>
                  this.handleClick(
                    unit.unit.developer_name,
                    'developer_name'
                  )
                }
              >
                {unit.unit.developer_name}
              </span>
            )
          }
        })
      } else {
        units.forEach(unit => {
          if (unit.unit.developer_name) {
            developers.push(
              <span
                className={`dropdown-item ${classes.dropdownItem}`}
                key={unit.id}
                onClick={e =>
                  this.handleClick(
                    unit.unit.developer_name,
                    'developer_name'
                  )
                }
              >
                {unit.unit.developer_name}
              </span>
            )
          }
        })
      }
    }
    // end devolper filter

    //filter by compund
    let compounds = []
    const developerName = this.props.searchParams.developer_name.values[0]
    if (units) {
      if (developerName) {
        units.forEach(unit => {
          if (
            unit.unit.developer_name &&
            unit.unit.developer_name === developerName
          ) {
            compounds.push(
              <span
                className={`dropdown-item ${classes.dropdownItem}`}
                key={unit.id}
                onClick={e =>
                  this.handleClick(unit.unit.developer_name, 'compound_name')
                }
              >
                {unit.unit.compound_name}
              </span>
            )
          }
        })
      } else {
        units.forEach(unit => {
          if (unit.unit.developer_name) {
            compounds.push(
              <span
                className={`dropdown-item ${classes.dropdownItem}`}
                key={unit.id}
                onClick={e =>
                  this.handleClick(unit.unit.developer_name, 'compound_name')
                }
              >
                {unit.unit.compound_name}
              </span>
            )
          }
        })
      }
    }

    //end filter by compund

    return (
      <div
        className={`moreFilters ${classes.optionsContainer} col-lg p-0`}
      >
        <button
          onClick={() => {
            this.toggleModal()
            toggleSearchHandler(' ')
          }}
          className={classes.moreFilterBtn}
        >
          <img
            src={require('../../../assets/filterbar/filter.svg')}
            alt='...'
            className={classes.homeIcon}
          ></img>
          <span>More Filters</span>
        </button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className='clearfix moreFilterModal'
        >
          <ModalHeader className={`text-left p-2 pt-4 pb-5 px-lg-5 border-0 ${classes.moreHeader}`}>
            More Filters
            <FontAwesomeIcon
              className={`float-right ${classes.closeModal}`}
              onClick={this.toggleModal}
              icon={faTimes}
            />
          </ModalHeader>
          <ModalBody className=''>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix p-0 m-0 bg-light`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-center p-0 m-0`}>
                  <div className={`col-12 col-lg-4 px-0 py-2`}>
                    <span className={`${classes.label} float-left`}>
                      <img
                        src={require('../../../assets/space.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Space</span>
                    </span>
                  </div>
                  <div className={`col-12 col-lg-8 px-0 py-2 bg-secondary`}>
                    <span className={`${classes.body} float-right moreFilters`}>
                      <div className={`container-fluid m-0`}>
                        <div className={`row align-items-baseline p-0 m-0`}>
                          <div className={`col-12 p-0 m-0 col-md-2 text-center ${classes.rangeNumberFixMobLeftSide}`}>
                            <span className='rangeNumber'>
                              {this.props.searchParams.land.value.min}{' '}
                              <span className='text-dark'>
                                M<sup>2</sup>
                              </span>
                            </span>
                          </div>
                          <div className={`col-12 pr-0 m-0 col-md-8 ${classes.rangeSliderFixMob}`}>
                            <InputRange
                              maxValue={800}
                              minValue={0}
                              step={10}
                              formatLabel={value => `+${value}M<sup>2</sup>`}
                              value={this.props.searchParams.land.value}
                              onChange={value =>
                                // this.setState({
                                //   land: {
                                //     value: value
                                //   }
                                // })
                                this.props.onAddSearch({
                                  land: {
                                    value: value
                                  }
                                })
                              }
                            />
                          </div>
                          <div className={`col-12 p-0 m-0 col-md-2 ${classes.rangeNumberFixMobRightSide}`}>
                            <span className='rangeNumber'>
                              {this.props.searchParams.land.value.max}{' '}
                              <span className='text-dark'>
                                +M<sup>2</sup>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix p-0`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-center p-0 m-0`}>
                  <div className={`col-12 col-md-4 px-0 py-2`}>
                    <span className={`${classes.label} float-left`}>
                      <img
                        src={require('../../../assets/bedrooms.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Bedrooms</span>
                    </span>
                  </div>
                  <div className={`col-12 col-md-8 px-0 py-2`}>
                    <div className={`${classes.body} `}>
                      <div className={`container-fluid p-0 m-0`}>
                        <div className={`row p-0 m-0`}>
                          {allBedrooms}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix p-0 bg-light`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-center p-0 m-0`}>
                  <div className={`col-12 col-md-4 px-0 py-2`}>
                    <span className={`${classes.label} float-left`}>
                      <img
                        src={require('../../../assets/bathrooms.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Bathrooms</span>
                    </span>
                  </div>
                  <div className={`col-12 col-md-8 p-0 m-0`}>
                    <div className={`${classes.body} float-right`}>
                      <div className={`container-fluid p-0 m-0`}>
                        <div className={`row p-0 m-0`}>
                          {allBathrooms}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix p-0`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-center p-0 m-0`}>
                  <div className={`col-12 col-md-4 px-0 py-2`}>
                    <span className={`${classes.label} float-left`}>
                      <img
                        src={require('../../../assets/calendar.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Delivery</span>
                    </span>
                  </div>
                  <div className={`col-12 col-md-8 p-0 m-0`}>
                    <div className={`${classes.body} float-right`}>
                      <div className={`container-fluid p-0 m-0`}>
                        <div className={`row p-0 m-0`}>
                          <div className={`col-6 col-xl-4 py-1 px-1`}>
                            <span
                              className={`${classes.readyToMove} 
                              ${
                                this.props.searchParams.delivery_date.values.includes("ready to move")
                                  ? `${classes.numberSelect}`
                                  : ''
                              }
                              py-3 px-0`}
                              onClick={e =>
                                this.toggleClick(
                                  'ready to move',
                                  'delivery_date'
                                )
                              }
                            >
                              Ready To Move
                            </span>
                          </div>
                          <div className={`col-6  col-xl-2 py-1 px-1`}>
                            <span
                              className={`${classes.numbers} ${
                                this.props.searchParams.delivery_date.values.includes(2020)
                                  ? `${classes.numberSelect}`
                                  : ''
                              }
                              py-3 px-0`}
                              onClick={e =>
                                this.toggleClick(2020, 'delivery_date')
                              }
                            >
                              2020
                            </span>
                          </div>
                          <div className={`col-4  col-xl-2 py-1 px-1`}>
                            <span
                              className={`${classes.numbers} ${
                                this.props.searchParams.delivery_date.values.includes(2021)
                                  ? `${classes.numberSelect}`
                                  : ''
                              }
                              py-3 px-0`}
                              onClick={e =>
                                this.toggleClick(2021, 'delivery_date')
                              }
                            >
                              2021
                            </span>
                          </div>
                          <div className={`col-4  col-xl-2 py-1 px-1`}>
                            <span
                              className={`${classes.numbers} ${
                                this.props.searchParams.delivery_date.values.includes(2022)
                                  ? `${classes.numberSelect}`
                                  : ''
                              }
                              py-3 px-0`}
                              onClick={e =>
                                this.toggleClick(2022, 'delivery_date')
                              }
                            >
                              2022
                            </span>
                          </div>
                          <div className={`col-4  col-xl-2 py-1 px-1`}>
                            <span
                              className={`${classes.numbers} ${
                                this.props.searchParams.delivery_date.values.includes(2023)
                                  ? `${classes.numberSelect}`
                                  : ''
                              }
                              py-3 px-0`}
                              onClick={e =>
                                this.toggleClick(2023, 'delivery_date')
                              }
                            >
                              2023
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix p-0 bg-light`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-baseline p-0 m-0`}>
                  <div className={`col-12 col-md-4 px-0 py-2`}>
                    <span className={`${classes.label}`}>
                      <img
                        src={require('../../../assets/developer.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Developer</span>
                    </span>
                  </div>
                  <div className={`col-12 col-md-8 p-0 m-0`}>
                    <div
                      className={`${classes.body} p-2 border`}
                    >
                      <div className='dropdown show'>
                        <div
                          className={`dropdown-toggle p-2 text-left  ${classes.dropdownBtn}`}
                          role='button'
                          id='developers'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          {this.props.searchParams.developer_name.values[0] !== undefined
                            ? this.props.searchParams.developer_name.values[0]
                            : 'All Developers'}
                        </div>

                        <div
                          className={`dropdown-menu ${classes.dropdownMenu}`}
                          aria-labelledby='developers'
                        >
                          {developers}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix`}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row align-items-baseline p-0 m-0`}>
                  <div className={`col-12 col-md-4 px-0 py-2`}>
                    <span className={`${classes.label}`}>
                      <img
                        src={require('../../../assets/compound.svg')}
                        alt='..'
                        className={classes.icon}
                      />
                      <span>Compound</span>
                    </span>
                  </div>
                  <div className={`col-12 col-md-8 p-0 m-0`}>
                    <div
                      className={`${classes.body} p-2 border`}
                    >
                      <div className='dropdown show'>
                        <div
                          className={`dropdown-toggle p-2 text-left ${classes.dropdownBtn}`}
                          role='button'
                          id='compounds'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          {this.props.searchParams.compound_name.values[0] !== undefined
                            ? this.props.searchParams.compound_name.values[0]
                            : 'All Compunds'}
                        </div>

                        <div
                          className={`dropdown-menu ${classes.dropdownMenu}`}
                          aria-labelledby='compounds'
                        >
                          {compounds}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.filter} p-2 px-lg-5 py-lg-4 clearfix mt-0`}>
              <div className="row flex-row-reverse">
                <div className="col-sm-12 col-lg-3 px-sm-5 px-lg-0 text-right">
                  <button
                    onClick={this.applyFilter}
                    className={`btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold text-capitalize ${classes.applyFilters}`}>
                    Apply Filters
                  </button>     
                </div>
                <div className="col-sm-12 col-lg-3 px-sm-5 px-lg-0 pr-lg-3 text-right">
                  <button
                    onClick={this.clearFilters}
                    type='reset'
                    className={`btn btn-light rounded-sm text-uppercase btn-block my-sm-0 my-lg-4 mb-sm-4 mb-lg-0 py-3 border-0 font-weight-bold text-capitalize shadow-none ${classes.clearAll}`}>
                    Clear All
                  </button>
                </div>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default MoreFilters
