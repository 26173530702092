import React, { Component, Fragment } from 'react'
import { withFirebase } from '../../components/Firebase'
import Swal from 'sweetalert2'
import classes from './index.module.scss'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

const PasswordForgetPage = props => (
  <Fragment>
    <PasswordForgetForm {...props} />
  </Fragment>
)

const INITIAL_STATE = {
  email: ''
}

class PasswordForgetFormBase extends Component {
  constructor (props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }
  onSubmitResetPassword = event => {
    const { email } = this.state
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.props.toggle()
        this.setState({ ...INITIAL_STATE })
        Swal.fire({
          title: 'SuccessFully!',
          text: 'Check Your Email',
          icon: 'success',
          confirmButtonText: 'Continue'
        })
      })
      .catch(error => {
        Swal.fire({
          title: 'Sorry!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Try Again'
        })
      })
    event.preventDefault()
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  render () {
    const { email } = this.state
    const isInvalid = email === ''
    return (
      <div className={`px-5 pt-5 ${classes.forgetPasswordModal}`}>
        <form>
          <input
            className='form-control mr-1 rounded-0'
            name='email'
            value={this.state.email}
            onChange={this.onChange}
            type='text'
            placeholder='Email Address'
          />
          <button
            onClick={this.onSubmitResetPassword}
            className='btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold'
            disabled={isInvalid}
            type='submit'
          >
            Reset My Password
          </button>
        </form>
      </div>
    )
  }
}
export default PasswordForgetPage

const PasswordForgetForm = compose(
  withFirebase,
  withRouter
)(PasswordForgetFormBase)
export { PasswordForgetForm }
