import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faTh } from '@fortawesome/free-solid-svg-icons'
import classes from './FilterBar.module.scss'
import './Filterbar.scss'

import 'react-input-range/lib/css/index.css'

//lodash npm package

import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  FilterLocation,
  FilterProperty,
  FilterPrice,
  MoreFilters
} from '../FilterBarParts'
import * as Methods from './utils.js'

class FilterBar extends Component {
  state = {
    //developerFilter
    mobileFilter: true,
    // paymentTypes: [],
    // developerPlace: null,
    // developerName: null,
    toggleState: {
      location: true,
      propertyType: true,
      price: true,
      space: true,
      rooms: true,
      beds: true,
      baths: true,
      city: true,
      delivery: true,
      developer: true,
      compound: true,
      sortBy: true,
      clear: true
    },
    cities: [],
    places: []
    // ,
    // locationState: {
    //   triggered: false,
    //   selected: 0,
    //   value: null
    // },
    // textlocationState: {
    //   triggered: false,
    //   selected: 0,
    //   value: null
    // },
    // developerState: {
    //   triggered: false,
    //   selected: 0,
    //   value: null
    // },
    // compoundState: {
    //   triggered: false,
    //   selected: 0,
    //   value: null
    // },
    // propertyTypeState: {
    //   triggered: false,
    //   selected: 0,
    //   value: []
    // },
    // // pricePaymentType: 'monthlyPayment',
    // monthlyPaymentSearch: true,
    // downpaymentSearch: true,
    // priceState: {
    //   triggered: false,
    //   monthlyPayment: {
    //     min: 0,
    //     max: 0
    //   },
    //   downpaymentRange: {
    //     min: 0,
    //     max: 0
    //   }
    // },
    // spaceState: {
    //   triggered: false,
    //   min: 0,
    //   max: 0
    // },
    // minSpaceVal: 0,
    // maxSpaceVal: 0,
    // roomState: {
    //   triggered: false,
    //   selected: 0,
    //   value: []
    // },
    // bathState: {
    //   triggered: false,
    //   selected: 0,
    //   value: []
    // },
    // floorState: {
    //   triggered: false,
    //   selected: 0,
    //   value: []
    // },
    // deliveryState: {
    //   triggered: false,
    //   selected: 0,
    //   value: []
    // },
    // rangeInputDownpaymentValue: {
    //   min: 0,
    //   max: 3000000
    // },
    // rangeInputMonthlyPaymentValue: {
    //   min: 5000,
    //   max: 100000
    // },
    // downpaymentMinInput: 0,
    // downpaymentMaxInput: 2000000,
    // downpaymentStep: 100000,

    // monthlyPaymentMinInput: 0,
    // monthlyPaymentMaxInput: 100000,
    // monthlyPaymentStep: 1000,

    // propertyTypeText: 'Property Type',
    // roomsText: 'Rooms',
    // //Flags for knowning what is filter is firring
    // filtersFlags: {
    //   rooms: false,
    //   baths: false,
    //   price: false
    // },
    // sortBy: 'Best Match'
  }
  // state = {...this.props.searchParams}
  // componentWillMount () {
  //   const propertyTypeParams = this.props.landingToBuyParams.propertyTypeParams
  //   const locationParams = this.props.landingToBuyParams.locationParams
  //   if (
  //     this.props.landingToBuyParams.propertyTypeParams.length > 0 &&
  //     locationParams
  //   ) {
  //     this.handleChange(null, {
  //       propertyTypeParams,
  //       locationParams
  //     })
  //     this.handleSubmit(null, {
  //       propertyTypeParams,
  //       locationParams
  //     })
  //   } else if (
  //     this.props.landingToBuyParams.propertyTypeParams &&
  //     this.props.landingToBuyParams.propertyTypeParams.length > 0
  //   ) {
  //     this.handleChange(null, {
  //       propertyTypeParams
  //     })
  //     this.handleSubmit(null, {
  //       propertyTypeParams
  //     })
  //   } else if (this.props.landingToBuyParams.locationParams) {
  //     this.handleChange(null, {
  //       locationParams
  //     })
  //     this.handleSubmit(null, {
  //       locationParams
  //     })
  //   } else {
  //   }
  // }

  selectedPropertyTypes = [...this.props.searchParams.propertyTypeState.value]
  componentDidUpdate (prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.units !== prevProps.units) {
      this.props.onGetUnits([])

      this.getCities(this.props.units || []) //calling getCities method
    }
  }

  clearFilters = () => {
    // to clear the state in "searchReducer"
    this.props.onClearSearch();
    // to clear the state in "filterReducer" to render full list of units
    this.props.clearFilters();
    // to clear the state in this component (mainly 'cities' and 'places')
    this.searchFilter(null, this)
  }

  clearMoreFilters = () => {
    // to clear the state in "filterReducer" to render full list of units
    this.props.clearMoreFilters();
  }
  searchFilter = event => {
    Methods.searchFilter(event, this)
  }
  getCities = unitsData => {
    Methods.getCities(unitsData, this)
  }
  getPlaces = (unitsData, cities) => {
    Methods.getPlaces(unitsData, cities, this)
  }
  // filterBySpace = event => {
  //   Methods.filterBySpace(event, this)
  // }
  filterByPrice = event => {
    Methods.filterByPrice(event, this)
  }
  showCardsOnly = () => {
    Methods.showCardsOnly(this)
  }
  showCardsAndMaps = () => {
    Methods.showCardsAndMaps(this)
  }

  /**
   * @param {object} event
   * @fires Methods.handleSubmit
   * @listens onSubmit from search forms
   * @description This method is used to call Methods.handleSubmit
   */
  handleSubmit = (event) => {
    Methods.handleSubmit(event, this)
  }

  /**
   * @param {object} event
   * @param {string} type
   * @param {object} type
   * @fires Methods.resetFormHandler
   * @listens onClick
   * @description This method is used to call Methods.resetFormHandler
   */
  resetFormHandler = (event, type, obj) => {
    Methods.resetFormHandler(event, type, obj, this)
    // if (type === 'location') {
    //   this.props.onResetForm({
    //     propertyTypeParams: this.props.landingToBuyParams.propertyTypeParams,
    //     locationParams: ''
    //   })
    // } else if (type === 'propertyType') {
    //   this.props.onResetForm({
    //     propertyTypeParams: [],
    //     locationParams: this.props.landingToBuyParams.locationParams
    //   })
    // }
  }
  // downpaymentScaleHandler = value => {
  //   Methods.downpaymentScaleHandler(value, this)
  // }
  // monthlyPaymentScaleHandler = value => {
  //   Methods.monthlyPaymentScaleHandler(value, this)
  // }
  spaceInputHandler = event => {
    Methods.spaceInputHandler(event, this)
  }

  /**
   * @param {string} type
   * @fires Methods.toggleSearchHandler
   * @listens onClick
   * @description This method is used to call Methods.toggleSearchHandler
   */
  toggleSearchHandler = type => {
    Methods.toggleSearchHandler(type, this)
  }

  // triggerFiltersHandler = (event) => {
  //     Methods.triggerFiltersHandler(event, this)
  // }

  paymentTypeHandler = event => {
    Methods.paymentTypeHandler(event, this)
  }

  /**
   * @param {Object} value
   * @fires Methods.handleChangeDownpayment
   * @listens onChange
   * @description This method changes the scale of the input range slider
   */
  handleChangeDownpayment = value => {
    Methods.handleChangeDownpayment(value, this)
  }
  handleChangeMonthlyPayment = value => {
    Methods.handleChangeMonthlyPayment(value, this)
  }
  /**
   * @fires Methods.minifymin
   * @listens onClick
   * @description This method used to call Methods.minifymin
   */
  // minifymin = () => {
  //   Methods.minifymin(this)
  // }

  /**
   * @fires Methods.plusifymin
   * @listens onClick
   * @description This method used to call Methods.plusifymin
   */
  // plusifymin = () => {
  //   Methods.plusifymin(this)
  // }

  /**
   * @fires Methods.minifymax
   * @listens onClick
   * @description This method used to call Methods.minifymax
   */
  // minifymax = () => {
  //   Methods.minifymax(this)
  // }

  /**
   * @fires Methods.plusifymax
   * @listens onClick
   * @description This method used to call Methods.plusifymax
   */
  // plusifymax = () => {
  //   Methods.plusifymax(this)
  // }
  // mouseEnterHandler = (type) => {
  //     Methods.mouseEnterHandler(type, this)
  // }
  // mouseLeaveHandler = (type) => {
  //     Methods.mouseLeaveHandler(type, this)
  // }

  handleLocationSubmit = (event, obj) => {
    // console.log(event)
    // console.log(obj)
    this.setState({
      developerPlace: event.target.dataset.value
    })
    Methods.handleLocationSubmit(event, obj, this)
  }

  /**
   * @param {Object} event
   * @fires Methods.handleChange
   * @listens onChange
   * @description This method used to call Methods.handleChange
   */
  // handleChange = (event, searchParams = {}) => {
  //   Methods.handleChange(event, this, searchParams)
  // }
  handleChange = (event) => {
    Methods.handleChange(event, this)
  }
  // cityHandler = (event) => {
  //     Methods.cityHandler(event, this)
  // }
  handleDeveloperSubmit = (event, obj) => {
    this.setState({
      developerName: event.target.dataset.value
    })
    Methods.handleDeveloperSubmit(event, obj, this)
  }
  handleCompoundSubmit = (event, obj) => {
    Methods.handleCompoundSubmit(event, obj, this)
  }

  sortByChangeHandler = event => {
    // console.log(event.target);
    // console.log(event.target.dataset.value);
    this.setState({ sortBy: event.target.dataset.value })
  }

  mobileViewFilters = () => {
    const targets = document.getElementsByClassName('mdView')
    this.setState({
      mobileFilter: !this.state.mobileFilter
    })
    if (!this.state.mobileFilter) {
      for (let index = 0; index < targets.length; index++) {
        if (index === 2) {
          targets[index].classList.remove('d-flex')
        } else {
          targets[index].classList.remove('d-block')
        }
      }
    } else {
      for (let index = 0; index < targets.length; index++) {
        if (index === 2) {
          targets[index].classList.add('d-flex')
        } else {
          targets[index].classList.add('d-block')
        }
      }
    }
  }
  render () {
    const mql = window.matchMedia('(max-width: 992px)')
    const searchParams = {...this.props.searchParams}
    return (
      <nav className={`${classes.Filterbar}`}>
        <div className={`form-inline ${classes.formInline} row mx-0`}>
          <button
            className={`d-block d-lg-none px-3 py-3 mb-2 ${classes.mobileFilters}`}
            onClick={this.mobileViewFilters}
          >
            Filters
            <img
              src={
                this.state.mobileFilter
                  ? require('../../assets/down.png')
                  : require('../../assets/up2.png')
              }
              alt='..'
              className='float-right'
            />
          </button>
          {/* check point */}
          <div
            id='myDiv'
            className={`d-none d-lg-block mdView ${classes.searchBtnsContainer} col-lg-8 p-0`}
          >
            <div className="row mx-0">
              {/* Filter by property type */}
              <FilterProperty
                selectedPropertyTypes={this.selectedPropertyTypes}
                propertyTypeState={searchParams.propertyTypeState}
                toggleState={this.state.toggleState}
                toggleSearchHandler={this.toggleSearchHandler}
                handleSubmit={this.handleSubmit}
                resetFormHandler={this.resetFormHandler}
                handleChange={this.handleChange}
              />
              {/* Location Filter */}
              <FilterLocation
                cities={this.state.cities}
                places={this.state.places}
                handleLocationSubmit={this.handleLocationSubmit}
                locationState={searchParams.locationState}
                toggleState={this.state.toggleState}
                searchFilter={this.searchFilter}
                toggleSearchHandler={this.toggleSearchHandler}
                resetFormHandler={this.resetFormHandler}
              />
              {/* Filter by price */}
              <FilterPrice
                priceState={searchParams.priceState}
                toggleState={this.state.toggleState}
                // pricePaymentType={searchParams.pricePaymentType}
                monthlyPaymentSearch={searchParams.monthlyPaymentSearch}
                downpaymentSearch={searchParams.downpaymentSearch}
                // paymentTypes={searchParams.paymentTypes}
                downpaymentMaxInput={searchParams.downpaymentMaxInput}
                downpaymentMinInput={searchParams.downpaymentMinInput}
                downpaymentStep={searchParams.downpaymentStep}
                monthlyPaymentMinInput={searchParams.monthlyPaymentMinInput}
                monthlyPaymentMaxInput={searchParams.monthlyPaymentMaxInput}
                monthlyPaymentStep={searchParams.monthlyPaymentStep}
                rangeInputDownpaymentValue={searchParams.rangeInputDownpaymentValue}
                rangeInputMonthlyPaymentValue={
                  searchParams.rangeInputMonthlyPaymentValue
                }
                toggleSearchHandler={this.toggleSearchHandler}
                paymentTypeHandler={this.paymentTypeHandler}
                filterByPrice={this.filterByPrice}
                downpaymentScaleHandler={this.downpaymentScaleHandler}
                monthlyPaymentScaleHandler={this.monthlyPaymentScaleHandler}
                handleChangeDownpayment={this.handleChangeDownpayment}
                handleChangeMonthlyPayment={this.handleChangeMonthlyPayment}
                resetFormHandler={this.resetFormHandler}                
              />

              <MoreFilters
                searchParams={searchParams}
                filters={this.props.filters}
                onAddSearch={this.props.onAddSearch}
                onRemoveSearch={this.props.onRemoveSearch}
                onClearSearch={this.props.onClearSearch}
                addNewFilter={this.props.addNewFilter}
                removeFilter={this.props.removeFilter}
                removeMoreFilters={this.props.removeMoreFilters}
                addNewRangeFilter={this.props.addNewRangeFilter}
                removeRangeFilter={this.props.removeRangeFilter}
                units={this.props.units}
                place={searchParams.developerPlace}
                toggleSearchHandler={this.toggleSearchHandler}
                clearFilters={this.clearFilters}
                clearMoreFilters={this.clearMoreFilters}
              />
              <div className={`${classes.optionsContainer} col-lg p-0 mr-5`}>
                <button
                  className={`${classes.clearBtn} font-weight-bold`}
                  onClick={() => {
                    this.clearFilters()
                    this.toggleSearchHandler('clear')
                  }}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
          {/* check point */}
          <div
            className={`d-none d-lg-block mdView ${classes.sortedBy} col-lg-3`}
            onClick={() => {
              this.toggleSearchHandler('sortBy')
            }}
          >
            <span
              className={classes.fixedText}
              onClick={() => {
                this.toggleSearchHandler('sortBy')
              }}
            >
              Sort By:
            </span>
            <div
              onClick={() => {
                this.toggleSearchHandler('sortBy')
              }}
              className={`dropdown ${classes.dropdown}`}
            >
              <button
                className={`dropdown-toggle ${classes.dropdownBtn}`}
                type='button'
                id='sortBy'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={() => {
                  this.toggleSearchHandler('sortBy')
                }}
              >
                {searchParams.sortBy}
              </button>
              <div
                className={`dropdown-menu ${classes.dropdownMenu}`}
                aria-labelledby='sortBy'
              >
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortByDwonPaymen(this.props.units, 'desc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Highest DownPayment'
                >
                  Highest DownPayment
                </span>
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortByDwonPaymen(this.props.units, 'asc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Lowest DownPayment'
                >
                  Lowest DownPayment
                </span>
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortByMonthlyBayment(this.props.units, 'desc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Highest Monthly Payment'
                >
                  Highest Monthly Payment
                </span>
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortByMonthlyBayment(this.props.units, 'asc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Lowest Monthly Payment'
                >
                  Lowest Monthly Payment
                </span>
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortBySpace(this.props.units, 'desc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Highest Space'
                >
                  Highest Space
                </span>
                <span
                  onClick={event => {
                    this.sortByChangeHandler(event)
                    this.props.sortBySpace(this.props.units, 'asc')
                  }}
                  className={`dropdown-item ${classes.dropdownItem}`}
                  data-value='Lowest Space'
                >
                  Lowest Space
                </span>
              </div>
            </div>
          </div>
          <div className={` mdView d-lg-flex ${classes.customView} col-lg-1 p-0`}>
            {/* <div className={classes.viewTipeContainer}> */}
            <button
              className={`${
                mql.matches
                  ? this.props.viewTipe
                    ? `${classes.activatedSwitchBtn}`
                    : ``
                  : this.props.viewTipe
                  ? ''
                  : `${classes.activatedSwitchBtn}`
              }`}
              onClick={mql.matches ? this.showCardsAndMaps : this.showCardsOnly}
              data-toggle='tooltip'
              data-placement='left'
              title='List View'
            >
              <FontAwesomeIcon icon={faTh} />
            </button>
            <button
              className={`${
                mql.matches
                  ? this.props.viewTipe
                    ? ``
                    : `${classes.activatedSwitchBtn}`
                  : this.props.viewTipe
                  ? `${classes.activatedSwitchBtn}`
                  : ''
              }`}
              onClick={mql.matches ? this.showCardsOnly : this.showCardsAndMaps}
              data-toggle='tooltip'
              data-placement='left'
              title='Map View'
            >
              <FontAwesomeIcon icon={faMap} />
            </button>
            {/* </div> */}
          </div>
        </div>
      </nav>
    )
  }
}
// export const toggleSearchHandler = type => {
//   Methods.toggleSearchHandler(type, this)
// }
const mapStateToProps = state => ({
  units: state.unitState.units,
  // landingToBuyParams: state.landingToBuyState.landingToBuy,
  // landingToBuyParams: state.searchState,
  searchParams: state.searchState,
  filters: state.filterState.filters
})
const mapDispatchToProps = dispatch => ({
  onGetUnits: units => dispatch({ type: 'UNITS_GET', units }),
  onResetForm: updatedState =>
    dispatch({ type: 'RESET_FORM', payload: updatedState }),
  onAddSearch: searchParam => dispatch({type: 'ADD_SEARCH', payload: searchParam}),
  onRemoveSearch: searchParam => dispatch({type: 'REMOVE_SEARCH', payload: searchParam}),
  onClearSearch: () => dispatch({type: 'CLEAR_SEARCH'}),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(FilterBar)
