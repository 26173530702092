import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../../FilterBar/FilterBar.module.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { compose } from 'redux'
class FilterProperty extends Component {
  state = {
    propertyTypes: ['apartment', 'duplex', 'villa', 'townhouse', 'twin']
  }

  // not very good for performance, because it'll run every time we check, uncheck
  // or toggle the search button, but I can't find another option.
  // what I need is to run this script ONCE when the whole filter bar is rendered
  // [case example] : when the user selects some property types then press "apply",
  // then navigate to another page then comes back! he should find the properties he
  // previously selected, are checked (with blue background)
  componentDidUpdate () {
    // console.log(this.refs)
    if (!this.props.isLandingPage) {
      // to uncheck all properties
      for (let e = 0; e < this.state.propertyTypes.length; e++) {
        let _refz = 'propertyType' + e
        this.refs[_refz].checked = false
      }
      // to check selected properties
      for (let r = 0; r < this.props.propertyTypeState.value.length; r++) {
      let x = this.state.propertyTypes.indexOf(this.props.propertyTypeState.value[r])
      let _ref = 'propertyType' + x
      this.refs[_ref].checked = true
      }
    }
  }
  render () {
    // console.error('xxxxxxxxxxxXXxxxxxxxxxxxx')
    const { path } = this.props.match
    
    const {
      propertyTypeState,
      toggleState,
      toggleSearchHandler,
      handleSubmit,
      resetFormHandler,
      handleChange
    } = this.props;
    // console.error(propertyTypeState)
    const propertyTypeTextHandler = () => {
      let propertyTypeText = 'Property Type'
      const vals = [...propertyTypeState.value]
      if (propertyTypeState.triggered) {
        console.log(vals)
        if (vals.length === 1) {
          propertyTypeText = vals[0]
        } else if (vals.length >= 1) {
          propertyTypeText = vals[0] + ' +' + (vals.length - 1)
        }
      }
      return propertyTypeText
    }
    const allPropertyTypes = this.state.propertyTypes.map((property, i) => {
      return (
        <li key={property}>
          <input
            key={`propertyType${i}`}
            ref={`propertyType${i}`}
            data-statetype='propertyTypeState'
            type='checkbox'
            name={property}
            value={property}
            className={`property`}
            onChange={handleChange}
          ></input>
          <span></span>
          <p className="text-capitalize">{property}</p>
        </li>
      )
    })

    return (
      <div className={`${classes.optionsContainer} col-lg p-0`}>
        <button
          className={`${classes.propertyType} ${
            propertyTypeState.triggered ? `${classes.triggered}` : ''
          } ${!toggleState.propertyType ? `${classes.active}` : ''}`}
          onClick={() => {
            toggleSearchHandler('propertyType')
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 512 512'
            className={classes.homeIcon}
          >
            <g>
              <g>
                <path
                  d='M421.936,211.786v238.533h-90.064V347.155c0-9.045-7.331-16.375-16.375-16.375H200.324
			c-9.045,0-16.375,7.331-16.375,16.375v103.164H94.431V211.786H61.681v254.908c0,9.045,7.331,16.375,16.375,16.375h122.269
			c9.045,0,16.375-7.331,16.375-16.375V363.531h82.422v103.164c0,9.045,7.331,16.375,16.375,16.375h122.815
			c9.045,0,16.375-7.331,16.375-16.375V211.786H421.936z'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M506.815,255.508L269.373,33.351c-6.25-5.857-15.966-5.895-22.27-0.104L5.295,255.405
			c-6.659,6.119-7.096,16.474-0.977,23.133c3.226,3.521,7.636,5.3,12.063,5.3c3.957,0,7.931-1.43,11.075-4.318L258.085,67.635
			l226.355,211.787c6.616,6.184,16.965,5.83,23.144-0.77C513.758,272.047,513.419,261.687,506.815,255.508z'
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>

          <span>{propertyTypeTextHandler()}</span>

          {path === '/' ? null : (
            <span
              className={classes.resetFormBtn}
              onClick={event => resetFormHandler(event, 'propertyType', this)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
        </button>
        <div
          className={`${classes.searchOptions} ${classes.propertyType}`}
          hidden={toggleState.propertyType}
        >
          <form
            data-statetype='propertyTypeState'
            data-toggletype='propertyType'
            onSubmit={handleSubmit}
          >
            <ul>{allPropertyTypes}</ul>
            {path === '/' ? null : (
              <button
                className={` btn btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold ${classes.applyBtn} ${classes.fixApplyBtn} ${classes.landingApplyBtn}`}>
                Apply
              </button>
            )}
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLandingPage: state.routerState.isLandingPage
})

// export default withRouter(FilterProperty)

export default compose(
  connect(mapStateToProps, null),
  withRouter
)(FilterProperty)