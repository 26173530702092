const landingToBuy = (state, action) => ({
    ...state,
    landingToBuy: action.payload,
});

const resetSearchForm = (state, action) => ({
    ...state,
    landingToBuy: action.payload
});

export { landingToBuy, resetSearchForm }