import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../components/Firebase'
import * as ROUTES from '../../constants/routes'
// import FooterPage from '../../components/Footer'
import classes from '../SignIn/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { SignInLink } from '../SignIn'
import JoinFooter from '../../components/JoinFooter'


const INITIAL_STATE = {
  email: '',
  fullname: '',
  phone: '',
  password: '',
  hidePassword: true,
  hiddePasswordconfirm: true,
  passwordconfirm: '',

  fullnameStatus: false,
  emailStatus: false,
  phoneStatus: false,
  passwordStatus: false,
  passwordconfirmStatus: false
}

const SignUpFormBase = props => {
  return (
    <div className='container-fluid p-0 m-0' id='signup'>
      <div className='row p-0 m-0'>
        <FormOfSignUp {...props} />
        <div
          className={`col-12 col-lg-4 p-0 m-0 ${classes.sideFooter} sideFooter`}
        >
          <JoinFooter />
        </div>
      </div>
    </div>
  )
}

export class FormOfSignUp extends Component {
  constructor (props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }
  componentDidMount () {
  
    

    const mql = window.matchMedia('(max-width: 992px)')
    if (this.props.location.pathname.includes('signup') && mql.matches) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    } else {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }
  }

  onSubmit = event => {
    event.preventDefault()

    const { fullname, email, password, phone } = this.state
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        Swal.fire({
          title: 'Congratulation!',
          text: 'Your account should be Created',
          icon: 'success',
          confirmButtonText: 'Continue'
        })
        let confirmButton = Swal.getConfirmButton()
        confirmButton.addEventListener('click', () => {
          this.props.toggle && this.props.toggle()
          this.props.history.push(ROUTES.SEARCH)
        })

        localStorage.removeItem('loggedUserId')
        localStorage.setItem('loggedUserId', authUser.user.uid)
        

        this.props.firebase
          .createNewUser(authUser.user.uid, fullname, phone, email)
          .catch(err => {
            Swal.fire({
              title: 'Sorry!',
              text: err.message,
              icon: 'error',
              confirmButtonText: 'Try Again'
            })
          })
      })
      .catch(error => {
        Swal.fire({
          title: 'Sorry!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Try Again'
        })
      })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  toggleShow = () => {
    if (this.state.password) {
      this.setState({ hidePassword: !this.state.hidePassword })
    }
  }
  toggleShowPassword = () => {
    this.setState({
      hiddePasswordconfirm: !this.state.hiddePasswordconfirm
    })
  }

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  checkStatus = e => {
    this.setState({
      [e.target.name + 'Status']: true
    })
  }
  render () {
    const {
      email,
      fullname,
      phone,
      password,
      passwordconfirm,
      hiddePasswordconfirm,
      hidePassword,

      fullnameStatus,
      emailStatus,
      phoneStatus,
      passwordStatus,
      passwordconfirmStatus
    } = this.state

    const confirmFullName = fullname !== '' && fullname.trim().length > 0
    const confirmPhone = phone !== '' && phone.length === 11

    const confirmEmail = email !== '' && this.validateEmail(email)
    const confirmedPasswordOne = password !== '' && password.length >= 8
    const confirmedPasswordTwo =
      passwordconfirm !== '' && password === passwordconfirm

    const isInvalid =
      !confirmPhone ||
      !confirmEmail ||
      !confirmFullName ||
      !confirmedPasswordOne ||
      !confirmedPasswordTwo

    const validation = (e, validateStatus) => {
      let target = e.target
      if (validateStatus) {
        target.classList.remove('confirm')
        target.classList.add('confirmed')
      } else {
        target.classList.remove('confirmed')
        target.classList.add('confirm')
      }
    }
    const { modal } = this.props
    return (
      <div
        className={`${
          !modal ? 'col-12 col-lg-8 px-5 py-0 pb-lg-3 ' : 'col-12'
        } ${classes.leftFormContainer}`}
      >
        <div
          className={`container-fluid m-0 p-0 ${classes.innerContainerFluid}`}
        >
          <div className={`row px-0 pb-0 m-0 ${!modal ? 'pt-4' : 'pt-0'}`}>
            <div className={`col-12`}>
              <h1
                className={`text-center pt-4 ${!modal ? 'text-left' : null} ${
                  classes.header
                }`}
              >
                Welcome To Sakneen
              </h1>
            </div>
            <div
              className={`col-12 col-md-12 col-lg-12 text-center ${
                !modal ? 'text-left' : null
              }`}
            >
              <p className='m-0 pb-2'>Signup now and start your free trial</p>
            </div>
            {!modal && (
              <div
                className={`col-12 col-md-12 col-lg-12 text-center ${
                  !modal ? 'text-left' : 'pb-0'
                }`}
              >
                <SignInLink style={classes.signupLink} />
              </div>
            )}
          </div>
          <div className={`col-12 p-0 m-0`}>
            <form onSubmit={this.onSubmit}>
              <div className={`container-fluid p-0 m-0`}>
                <div className={`row p-0 m-0`}>
                  <div className={`col-12 col-lg-6`}>
                    <div className={`form-group p-0 m-0 ${classes.formGroup}`}>
                      <input
                        className={`form-control rounded-0`}
                        name='fullname'
                        value={fullname}
                        aria-describedby='helpFullName'
                        onBlur={e => {
                          validation(e, confirmFullName)
                          this.checkStatus(e)
                        }}
                        onChange={this.onChange}
                        type='text'
                      />
                      <label
                        className={`${
                          fullname ? `${classes.hiddenLabel}` : ''
                        }`}
                      >
                        Full Name
                      </label>
                      {fullnameStatus &&
                        (confirmFullName ? null : (
                          <small
                            id='helpFullName'
                            className='form-text minPassword'
                          >
                            Fullname Can not be empty
                          </small>
                        ))}
                    </div>
                  </div>
                  <div className={`col-12 col-lg-6`}>
                    <div className={`form-group p-0 m-0 ${classes.formGroup}`}>
                      <input
                        className={`form-control rounded-0`}
                        name='email'
                        value={email}
                        aria-describedby='helpEmail'
                        onBlur={e => {
                          validation(e, confirmEmail)
                          this.checkStatus(e)
                        }}
                        onChange={this.onChange}
                        type='text'
                      />
                      <label
                        className={`${email ? `${classes.hiddenLabel}` : ''}`}
                      >
                        E-mail
                      </label>
                      {emailStatus &&
                        (confirmEmail ? null : (
                          <small
                            id='helpEmail'
                            className='form-text minPassword'
                          >
                            Email not correct
                          </small>
                        ))}
                    </div>
                  </div>
                  <div className={`col-12`}>
                    <div className={`form-group p-0 m-0 ${classes.formGroup}`}>
                      <input
                        className={`form-control rounded-0`}
                        name='phone'
                        value={phone}
                        aria-describedby='helpPhone'
                        onBlur={e => {
                          validation(e, confirmPhone)
                          this.checkStatus(e)
                        }}
                        onChange={this.onChange}
                        type='tel'
                      />
                      <label
                        className={`${phone ? `${classes.hiddenLabel}` : ''}`}
                      >
                        Phone number
                      </label>
                      {phoneStatus &&
                        (confirmPhone ? null : (
                          <small
                            id='helpPhone'
                            className='form-text minPassword'
                          >
                            Phone Number Must be 11 digits
                          </small>
                        ))}
                    </div>
                  </div>
                  <div className={`col-12 col-lg-6`}>
                    <div className={`form-group p-0 m-0 ${classes.formGroup}`}>
                      <input
                        className={`form-control rounded-0`}
                        aria-describedby='helpPassword'
                        name='password'
                        value={password}
                        onBlur={e => {
                          validation(e, confirmedPasswordOne)
                          this.checkStatus(e)
                        }}
                        onChange={this.onChange}
                        type={hidePassword ? 'password' : 'text'}
                      />
                      <label
                        className={`${
                          password ? `${classes.hiddenLabel}` : ''
                        }`}
                      >
                        Password
                      </label>
                      <FontAwesomeIcon
                        onClick={this.toggleShow}
                        hidden={!hidePassword}
                        icon={faEye}
                      />
                      <FontAwesomeIcon
                        onClick={this.toggleShow}
                        hidden={hidePassword}
                        icon={faEyeSlash}
                      />
                      {passwordStatus &&
                        (confirmedPasswordOne ? null : (
                          <small
                            id='helpPassword'
                            className='form-text minPassword'
                          >
                            Password must be at least 8 charaters
                          </small>
                        ))}
                    </div>
                  </div>
                  <div className={`col-12 col-lg-6`}>
                    <div className={`form-group p-0 m-0  ${classes.formGroup}`}>
                      <input
                        className={`form-control rounded-0`}
                        name='passwordconfirm'
                        value={passwordconfirm}
                        onBlur={e => {
                          validation(e, confirmedPasswordTwo)
                          this.checkStatus(e)
                        }}
                        onChange={this.onChange}
                        type={hiddePasswordconfirm ? 'password' : 'text'}
                      />
                      <label
                        className={`${
                          passwordconfirm ? `${classes.hiddenLabel}` : ''
                        }`}
                      >
                        Password confirm
                      </label>
                      <FontAwesomeIcon
                        onClick={this.toggleShowPassword}
                        hidden={!hiddePasswordconfirm}
                        icon={faEye}
                      />
                      <FontAwesomeIcon
                        onClick={this.toggleShowPassword}
                        hidden={hiddePasswordconfirm}
                        icon={faEyeSlash}
                      />
                      {passwordconfirmStatus &&
                        (confirmedPasswordTwo ? null : (
                          <small
                            id='helpPassword'
                            className='form-text minPassword'
                          >
                            confirm password must be like password
                          </small>
                        ))}
                    </div>
                  </div>
                  <div className={`col-12 mt-3`}>
                    <div className={`remember-me mr-2 ${classes.rememberMe}`}>
                      <input type='checkbox'></input>
                      <span></span>
                      <p className={classes.remember}>Remember Me</p>
                    </div>
                  </div>
                  <div className={`col-12  ${classes.signUpSection}`}>
                    <button
                      className='btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold'
                      disabled={isInvalid}
                    >
                      SIGN UP
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
const SignUpLink = props => (
  <p className={props.style}>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

const SignUpPage = compose(withRouter, withFirebase)(SignUpFormBase)
export default SignUpPage
export { SignUpLink }
