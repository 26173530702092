import React, { Component, Fragment } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../Firebase'
import { withRouter } from 'react-router-dom'
import classes from './index.module.scss'
import secHeadClasses from '../sectionsHeaders.module.scss'
import { Link } from 'react-router-dom'
// import HouseCard from '../../HouseCard'
import HouseCard from '../../nonBootstrapHouseCard'
import * as ROUTES from '../../../constants/routes'
const FeatureListingPage = props => (
  <Fragment>
    <FeatureListing {...props} />
  </Fragment>
)

class FeatureListingBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      FeatureListing: []
    }
  }
  addToFavorites = (userId, houseId) => {
    this.props.firebase.addToFavorites(userId, houseId)
  }
  deleteFromFavorites = (userId, houseId) => {
    this.props.firebase.deleteFromFavorites(userId, houseId)
  }
  render () {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ]
    let allUnits = []
    const units = this.props.allUnits
    for (let index = 0; index < 4; index++) {
      const deliveryMonth =
        monthNames[units[index].unit.delivery_date.month - 1]
      const deliveryDate =
        deliveryMonth + ' ' + units[index].unit.delivery_date.year

      allUnits.push(
        <HouseCard
          key={units[index].id}
          houseId={units[index].id}
          selectedClass='nonSelectedU'
          price={units[index].financing.cash_price}
          house_type={units[index].unit.house_type}
          picture={units[index].unit.picture}
          address={units[index].unit.address}
          readyToMove={units[index].unit.ready_delivery}
          deliveryDate={deliveryDate}
          city={units[index].unit.city}
          bedrooms={units[index].unit.bedrooms}
          bathrooms={units[index].unit.bathrooms}
          land={units[index].unit.land}
          bua={units[index].unit.bua}
          unitSize={3}
          addToFavorites={this.addToFavorites}
          deleteFromFavorites={this.deleteFromFavorites}
        />
      )
    }
    return (
      <div className={`container-fluid ${classes.FeaturedList}`}>
        <div className='row text-center'>
          <div
            className={`col-12 ${secHeadClasses.headingContainer} ${secHeadClasses.featuredListingsHeaderImgCon}`}
          >
            <div className={`${secHeadClasses.headingImgContainer}`}>
              <img
                src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                alt='Sakneen logo'
              ></img>
            </div>
            <h1 className={`text-center ${secHeadClasses.headingText}`}>
              Featured Listings
            </h1>
          </div>
        </div>
        {/* <div className={`row ${classes.featuresList}`}> */}
        <div className={`${classes.featuresList}`}>
          {allUnits}
        </div>
        <div className='row '>
          <div className='col-12 text-center text-uppercase'>
            <Link to={ROUTES.SEARCH} className={`btn  ${classes.moreBtn}`}>
              MORE...
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const FeatureListing = compose(withRouter, withFirebase)(FeatureListingBase)

export default FeatureListingPage
export { FeatureListing }
