import { isLandingPage } from '../Actions/routerActions';
import { IS_LANDING_PAGE } from '../Constants/routerConstants';

const INITIAL_STATE = {
    isLandingPage: false
};
function routerReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case IS_LANDING_PAGE: {
      return isLandingPage(state, action);
    }
    default:
      return state;
  }
}

export default routerReducer;