import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../Firebase'
import { withRouter, Link } from 'react-router-dom'
import classes from './index.module.scss'

import * as ROUTES from '../../../constants/routes'

const ServicesPage = props => (
  <Fragment>
    <Services {...props} />
  </Fragment>
)

const servicesBase = () => {
  return (
    // <div className='container-fluid text-center'>
    // <div className='text-center'>
    <div>
      {/* <div className={`row ${classes.serviceCardsContainer}`}> */}
      <div className={`${classes.serviceCardsContainer}`}>
        {/* <div className={`${classes.singleServiceCardCon}`}> */}
          <div className={classes.serviceCard}>
            <div className={classes.imgSection}>
              <div
                className={`${classes.serviceBackgroundLogo} ${classes.serviceOneBackLogo}`}
              >
                <img
                  src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                  alt='Sakneen logo'
                ></img>
              </div>
              <img
                className={`${classes.serviceImg} ${classes.serviceImgOne}`}
                src={require('../../../assets/landingPage/finish.png')}
                alt='Sakneen logo'
              ></img>
            </div>
            <div className={classes.textSection}>
              <p>FINISH AND FURNISH</p>
              <p>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.
              </p>
              <Link to={ROUTES.FINISH_AND_FURNISH} className={classes.explore}>
                EXPLORE
              </Link>
            </div>
          </div>
        {/* </div> */}
        {/* <div className={`${classes.singleServiceCardCon}`}> */}
          <div className={classes.serviceCard}>
            <div className={classes.imgSection}>
              <div
                className={`${classes.serviceBackgroundLogo} ${classes.serviceTwoBackLogo}`}
              >
                <img
                  src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                  alt='Sakneen logo'
                ></img>
              </div>
              <img
                className={`${classes.serviceImg} ${classes.serviceImgTwo}`}
                src={require('../../../assets/landingPage/extend.png')}
                alt='Sakneen logo'
              ></img>
            </div>
            <div className={classes.textSection}>
              <p>EXTEND PAYMENTS</p>
              <p>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.
              </p>
              <Link to={ROUTES.EXTEND_PAYMENT} className={classes.explore}>
                EXPLORE
              </Link>
            </div>
          </div>
        {/* </div> */}
        {/* <div className={`${classes.singleServiceCardCon}`}> */}
          <div className={classes.serviceCard}>
            <div className={classes.imgSection}>
              <div
                className={`${classes.serviceBackgroundLogo} ${classes.serviceThreeBackLogo}`}
              >
                <img
                  src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                  alt='Sakneen logo'
                ></img>
              </div>
              <img
                className={`${classes.serviceImg} ${classes.serviceImgThree}`}
                src={require('../../../assets/landingPage/sale.png')}
                alt='Sakneen logo'
              ></img>
            </div>
            <div className={classes.textSection}>
              <p>SALE AND LEASEBACK</p>
              <p>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.
              </p>
              <Link to={ROUTES.SALES_AND_LEASEBACK} className={classes.explore}>
                EXPLORE
              </Link>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  )
}

const Services = compose(withRouter, withFirebase)(servicesBase)

export default ServicesPage
export { Services }
