import React, { Component } from 'react'
import styles from './index.module.scss'
import $ from 'jquery/dist/jquery.js'

class JoinFooter extends Component {
  render () {
    return (
      <div className={`container-fluid p-0 m-0`}>
        <div className={`row m-0 p-0`}>
          <div id="stict" className={`col-12 py-5 m-0 text-center ${styles.Footer}`}>
            <img
              className={`py-md-2 py-lg-5  ${styles.logo}`}
              src={require('../../assets/footerlogo.png')}
              alt='logo'
            />
            <p className={`py-0 py-md-2 py-lg-4 px-2 ${styles.paragraph}`}>
              Which way?, holding her hand on the top of her head to hide a
              smile
            </p>
            {/* <div className={`py-1  ${styles.socailLogos}`}>
              <a href='http://www.fb.com'>
                <img src={require('../../assets/fb.svg')} alt='fb' />
              </a>
              <a href='http://www.twitter.com'>
                <img src={require('../../assets/tw.svg')} alt='tw' />
              </a>
              <a href='http://www.instagrm.com'>
                <img src={require('../../assets/inst.svg')} alt='inst' />
              </a>
            </div> */}
            <div className={` ${styles.bg}`}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default JoinFooter


$(document).ready(function () {


    function fixSigninSignupFooter() {
      $('#stict').css({
        height: $(window).height()-$('.navbar').height()-16.5
      })  
    }

    $(window).resize(function () {
      fixSigninSignupFooter();
    });
    fixSigninSignupFooter()

});