export const markerClusterOptions = {  
  styles: [
    {
      height: 56,
      url: require('../../assets/MapClusteringIcon-56.png'),
      backgroundColor: 'red',
      width: 56,      
      textColor: 'blue',
      textSize: 18,      
    },
    {
      height: 64,
      url: require('../../assets/MapClusteringIcon-64.png'),
      backgroundColor: 'red',
      width: 64,      
      textColor: 'blue',
      textSize: 26,      
    },
    {
      height: 96,
      url: require('../../assets/MapClusteringIcon-96.png'),
      backgroundColor: 'red',
      width: 96,      
      textColor: 'blue',
      textSize: 37,      
    }
  ]
}
