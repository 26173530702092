import React, { Fragment, Component } from 'react'
import { compose } from 'redux'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import styles from './index.module.scss'
///
import { Modal, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap'
const RequestHousePage = props => (
  <Fragment>
    <RequestHouseView {...props} />
  </Fragment>
)

class RequestHouse extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false, //for toggle modal
      user_type: 'buyer',
      userFullName: '', //required
      email: '', // entered by user if user not logged in and will take auto if user logged in
      phone: '' // entered by user
    }
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }
  requestToBuyHouse = requestDetails => {
    this.props.firebase.requestToBuyHouse(requestDetails).then(() => {
      Swal.fire({
        title: 'Congratulation!',
        text: 'We received your request, wait a call from sell team',
        icon: 'success',
        confirmButtonText: 'Continue'
      })
    })
  }
  //on submit when user id not logged in
  onSubmit = event => {
    event.preventDefault()

    const requestDetails = {
      ...this.state,
      unit_id: this.props.houseId
    }
    this.requestToBuyHouse(requestDetails)
    //close modal
    this.toggle()
    this.setState({
      user_id: '',
      userFullName: '',
      email: '',
      phone: ''
    })
  }
  //on change
  onChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  //when user is logged in
  applyToBuyUnit = houseId => {
    let userId = localStorage.getItem('loggedUserId')
    this.props.firebase.getUserData(userId).then(user => {
      const requestDetails = {
        user_type: 'buyer',
        userFullName: user.data().fullName,
        email: user.data().email,
        phone: user.data().phone, // entered by user
        unit_id: houseId
      }
      this.requestToBuyHouse(requestDetails)
    })
  }

  render () {
    const { userFullName, email, phone } = this.state
    //check user enter his data
    let isValid = userFullName !== '' && email !== '' && phone !== ''
    return (
      <Fragment>
        {this.props.authUser ? (
          <button
            className={`btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold`}
            onClick={() => this.applyToBuyUnit(this.props.houseId)}
          >
            APPLY
          </button>
        ) : (
          <button
            className={`btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold`}
            onClick={this.toggle}
          >
            APPLY
          </button>
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <span
            className={`text-bold py-2 px-2 ${styles.close}`}
            onClick={this.toggle}
          >
          <img
              src={require('../../assets/close-icon.svg')}
              alt='...'
          ></img>
          </span>
          <h5
            className={`text-center pb-2 pt-4 px-3 text-bold text-uppercase ${styles.header}`}
          >
            Request House
          </h5>
          <ModalBody>
            <Form onSubmit={this.onSubmit} className='py-2 px-3'>
              <FormGroup>
                <Label for='userFullName'>Full Name</Label>
                <Input
                  type='text'
                  name='userFullName'
                  id='userFullName'
                  placeholder='Enter you name'
                  onChange={this.onChange}
                  className='mb-2'
                />
                <Label for='email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='email'
                  placeholder='Enter you email'
                  onChange={this.onChange}
                  className='mb-2'
                />
                <Label for='phone'>Phone Number</Label>
                <Input
                  type='tel'
                  name='phone'
                  id='phone'
                  placeholder='Enter you phone number'
                  onChange={this.onChange}
                  className='mb-2'
                />
              </FormGroup>

              <input
                disabled={!isValid}
                className={`btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold`}
                value='REQUEST'
                type='submit'
              />
            </Form>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})

const RequestHouseView = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(RequestHouse)

export default RequestHousePage
export { RequestHouseView }
