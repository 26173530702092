import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome } from '@fortawesome/free-solid-svg-icons';


import './index.css'
class LandingSearch extends Component {
    state = {
        searchOption: 'city',
        textSearch: '',
        clicked: false,
    }
    search = (e) => {
        e.preventDefault()
        const textSearch = this.state.textSearch.split(" ");

        const units = this.filterUnits(this.props.units, this.state.searchOption, textSearch)

        this.props.history.push({
            pathname: "/search",
            state: {
                units: units
            }
        });
    }

    filterUnits = (units = [], key, values = []) => {
        let newUnits = [];
        for (let index = 0; index < values.length; index++) {
            newUnits = units.filter(unit =>
                unit.unit[key] && unit.unit[key].toLowerCase().includes(values[index].toLowerCase())
            )
            newUnits = new Set(newUnits);
            newUnits = [...newUnits]
        }
        return newUnits;
    }

    handleChange = (e) => {
        e.preventDefault()
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    onClick = (e) => {
        // e.preventDefault();
        const target = document.getElementById('searchOption')
        console.log(target);

        this.setState({
            clicked: !this.state.clicked
        })
    }
    selectOnClick = (e) => {
        console.log("Milad", e);
        
    }
    render() {
        const { searchOption, textSearch, clicked } = this.state;
        const isVilad = textSearch.trim() === '';
        return (
            <Fragment>
                <div className="searchContainer  pr-2 pl-0 clearfix" >
                    <select onClick={this.selectOnClick} className="p-1 pl-5 pt-2 pb-0 float-left" id="searchOption" onChange={this.handleChange}>
                        <option value='city'>City</option>
                        <option value='address'>Address</option>
                        <option value='developer_name'>Developer</option>
                        <option value='house_type'>Property Type</option>
                    </select>
                    <img
                        className="dropDwon"
                        // onClick={this.onClick}
                        src={
                            clicked ? require('../../../assets/up.png') : require('../../../assets/dropdown.png')
                        } alt=".." />
                    <input
                        className="p-1 pt-2 pb-2 mr-2 float-left"
                        type="text"
                        id="textSearch"
                        onChange={this.handleChange}
                        placeholder={
                            searchOption === 'city'
                                ? "Enter Location, City, Neghborhood"
                                : searchOption === 'address'
                                    ? "Enter Address"
                                    : searchOption === 'developer_name'
                                        ? "Enter Developer  name"
                                        : searchOption === 'house_type'
                                            ? "Enter Property Type (e.g. villa, duplex)"
                                            : null
                        }
                    />

                    <button
                        id="searchBtn"
                        className="pt-1 pb-1 pl-5 pr-5 mt-3 mb-1 font-weight-lighter"
                        onClick={this.search}
                        disabled={isVilad}
                    >
                        Search
                </button>
                </div>
            </Fragment>
        )
    }
}

export default compose(withRouter)(LandingSearch);