import React, { Component } from 'react'
import mapStyles from './mapStyle.json'
import { markerClusterOptions } from './markerClusterOptions'
import './infoStyle.css'

class Map extends Component {
  state = {
    zoom: 7,
    center: { lat: 30.00155763217503, lng: 31.2376148243418 }
  }
  componentDidMount () {
    this.initMap()
  }
  componentDidUpdate (prevState) {
    if (prevState.units !== this.props.units) {
      const center = this.props.units.map(unit => {
        return {
          lat: +unit.unit.location._lat,
          lng: +unit.unit.location._long
        }
      })

      let bounds = new window.google.maps.LatLngBounds()
      for (var i = 0; i < center.length; i++) {
        bounds.extend(center[i])
      }
      if (center.length > 0) {
        if (this.clusters) {
          this.clusters.clearMarkers()
          this.map.fitBounds(bounds)
          this.map.setZoom(3)
        }
      } else {
        if (this.clusters) {
          this.clusters.clearMarkers()
          this.map.setCenter(this.state.center)
          this.map.setZoom(this.state.zoom)
        }
      }
      this.forceUpdate()
    }
  }
  initMap = () => {
    const NEW_ZEALAND_BOUNDS = {
      north: 32.55824856295643,
      south: 20.0,
      west: 24.83520507812499,
      east: 34.5
    }
    const CAIRO = { lat: 30.00155763217503, lng: 31.2376148243418 }

    this.map = new window.google.maps.Map(document.getElementById('myMap'), {
      center: CAIRO,
      restriction: {
        latLngBounds: NEW_ZEALAND_BOUNDS,
        strictBounds: false
      },
      zoom: this.state.zoom,
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
      gestureHandling: 'greedy',
      controlSize: 20,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
        style: window.google.maps.ZoomControlStyle.LARGE
      }
    })

    this.map.addListener('click', e => {
      if (this.lastWindow) this.lastWindow.close()
      let position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      console.log(position)
    })
    // this.map.addListener('load', e => {

    // })
    this.map.addListener('zoom_changed', () => {
      if (this.lastWindow) this.lastWindow.close()
    })
  }
  /**
   * @fires componentDidMount
   * @listens
   * @param
   * @returns
   */
  ZoomOnClickMarker = position => {
    let zoom = this.state.zoom

    if (zoom < 22) {
      zoom += 5
    } else  {
      zoom = 7
    } 
    this.setState({
      zoom: zoom,
      center: position
    })
    if (this.lastWindow) this.lastWindow.close()

    this.map.setCenter(position)
    this.map.setZoom(zoom)
  }
  paymentPerMonth = (years, cash, downpayment) => {
    const n = 12 * years
    const r = 0.14 / 12
    const p = cash - downpayment

    return this.paymentAmountPerPeriod(p, r, n)
  }
  paymentAmountPerPeriod = (p, r, n) => {
    return (
      Math.ceil(
        (p * ((r * Math.pow(r + 1, n)) / (Math.pow(r + 1, n) - 1))) / 100
      ) * 100
    )
  }

  CardOnMap = (marker, unit) => {
    let minDownPayment = Number(unit.unit.cash_price * 0.2)
    const monthlyPayment = this.paymentPerMonth(
      15,
      unit.unit.cash_price,
      minDownPayment
    ).toLocaleString('en', {
      useGrouping: true
    })
    minDownPayment = minDownPayment.toLocaleString('en', {
      useGrouping: true
    })
    const contentString = `
    <div class="container-fluid m-0 p-0 cardOnMap">
        <div class="row p-0 m-0"> 
          <div class="col-12 col-lg-5 p-0 m-0"> 
              <img class="w-100 h-100 HouseImage" src=${
                unit.unit.picture
              } alt=${unit.unit.address} />
          <a  class="houseLink text-decoration-none text-uppercase py-2" target="_blank" href=${'/house/' +
            unit.id}> <span>Details</span> </a>
          </div>
          <div class="col-12 col-lg-7 p-0 p-lg-2 m-0 pt-lg-3 px-3 py-2"> 
            <div class="container-fluid p-0 m-0">
              <div class="row p-0 m-0">
                <div class="col-12 pl-0 col-lg-6 p-lg-0">
                  <p>
                    <span class="h5"> ${minDownPayment} </span> <br/>
                    <span class="text-secondary">
                      Min Down Payment
                    </span>
                  </p>                  
                </div>
                <div class="col-12 pl-0 col-lg-6 p-lg-0">
                  <p>
                  <span class="h5">${monthlyPayment}</span><br/> 
                    <span class="text-secondary">Monthly Payment </span> 
                  </p>                            
                </div>
              </div>
              <div class="row p-0 m-0">
                <div class="col-6 col-xl-3 p-0 m-0">
                    <p>
                      <img src=${require('../../assets/houseCard/home.png')} />
                      ${unit.unit.house_type} 
                    </p>
                </div>
                <div class="col-6 col-xl-3 p-0 m-0">
                  <p>
                    <img src=${require('../../assets/houseCard/space.png')} />
                    ${unit.unit.bua}
                  </p>
                </div>
                <div class="col-6 col-xl-3 p-0 m-0">
                    <p>
                      <img src=${require('../../assets/houseCard/bedroom.png')} />
                      ${unit.unit.bedrooms}
                    </p>
                </div>
                <div class="col-6 col-xl-3 p-0 m-0">
                    <p>
                    <img src=${require('../../assets/houseCard/bathroom.png')} />
                    ${unit.unit.bathrooms}
                  </p>
                </div>
                <div class="col-12 d-none d-md-block">
                <hr/>
                </div>            
                <div class=" col-8 p-0 m-0">     
                  <p class="p-0 m-0">
                    <img src=${require('../../assets/houseCard/location.png')} />
                    ${unit.unit.address}
                  </p>           
                </div>
                <div class=" col-4 p-0 m-0 clearfix">
                  <p class="float-right p-0 m-0"><img src=${require('../../assets/houseCard/location.png')} /></p>
                </div>          
              </div>
              
            </div>
          </div>
        </div>
    </div>
    `
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString
    })

    if (this.lastWindow) this.lastWindow.close()
    if (this.state.zoom > 7) {
      infowindow.open(this.map, marker)
    }
    this.lastWindow = infowindow
  }

  makeMarkerIcon (markerColor) {
    const markerImage = new window.google.maps.MarkerImage(
      'http://chart.googleapis.com/chart?chst=d_map_spin&chld=1.15|0|' +
        markerColor +
        '|40|_|%E2%80%A2',
      new window.google.maps.Size(21, 34),
      new window.google.maps.Point(0, 0),
      new window.google.maps.Point(10, 34),
      new window.google.maps.Size(21, 34)
    )
    return markerImage
  }
  removeMarkers = () => {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null)
    }
  }
  createMarkers = units => {
    const defaultIcon = this.makeMarkerIcon('2419be')
    // const units = this.props.units
    if (this.markers) {
      this.removeMarkers()
    }
    this.markers = units.map(unit => {
      const location = {
        lat: +unit.unit.location._lat,
        lng: +unit.unit.location._long
      }
      let marker = new window.google.maps.Marker({
        position: location,
        icon: defaultIcon,
        map: this.map
      })
      marker.addListener('click', () => {
        this.ZoomOnClickMarker(location)
        this.CardOnMap(marker, unit)
      })
      return marker
    })
  }
  markerCluster = () => {
    if (this.clusters) {
      this.clusters.clearMarkers()
    }
    this.clusters = new window.MarkerClusterer(
      this.map,
      this.markers,
      markerClusterOptions
    )
  }
  render () {
    const { units } = this.props
    if (units) {
      this.createMarkers(units)
      this.markerCluster()
    }
    return (
      <div
        className='GoogleMap'
        style={{ width: '100%', height: '100%' }}
        id={'myMap'}
      />
    )
  }
}

export default Map
