import { withAuthorization } from '../../components/Session'

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

const HomePage = () => (
  <div>
    <h1>Upload File</h1>
    <HomeForm />
  </div>
);

class UploadFileFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      error: ''
    }

  }

  onSubmit = event => {
    event.preventDefault();
    // console.log(this.state.selectedFile)
    this.props.firebase
      .doPut(this.state.selectedFile, localStorage.getItem('loggedUserId'))
      .then(() => {
        this.props.history.push(ROUTES.LANDING);
        // this.setState({
        //   selectedFile: new File()
        // })
      })
      .catch(error => {
        this.setState({ error });
      });
  };
  
  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  };

  render() {
    const {
      error,
    } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <input type="file" name="file" onChange={this.onChangeHandler} />
        <button type="submit">
          UPLOAD
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
const condition = authUser => !!authUser


const HomeForm = compose(
  withRouter,
  withFirebase,
)(UploadFileFormBase);

export default withAuthorization(condition)(HomePage);
export { HomeForm };