import React, { Component, Fragment } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faHome,
//   faLeaf,
//   faLandmark,
//   faBed,
//   faBath
// } from '@fortawesome/free-solid-svg-icons'
import './index.css'
import { Modal } from 'reactstrap'
import PhotoGallery from '../PhotoGallery'

class HouseSlider extends Component {
  state = {
    modal: false
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }
  render () {
    const { unitData } = this.props
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ]
    const {
      house_type,
      bua,
      land,
      bedrooms,
      bathrooms,
      ready_delivery,
      delivery_date,
      address
    } = Boolean(unitData) && unitData

    const photos = unitData
      ? unitData.photos.map((photo, index) => {
          if (index === 0) {
            return (
              <div className='carousel-item active' key={photo + Date.now}>
                <img
                  onClick={this.toggle}
                  className='d-block w-100'
                  src={photo}
                  alt=''
                />
              </div>
            )
          } else {
            return (
              <div className='carousel-item' key={Math.random()}>
                <img
                  onClick={this.toggle}
                  className='d-block w-100'
                  src={photo}
                  alt=''
                />
              </div>
            )
          }
        })
      : null
    return (
      <Fragment>
        <Modal
          className='PhotoGalleyModal'
          fade={false}
          isOpen={this.state.modal}
          toggle={this.toggle}
        >
          <PhotoGallery HouseData={unitData} />
        </Modal>
        <div className='row pb-3 housePage'>
          <div className='col-12'>
            <div
              id='carouselExampleIndicators'
              className='carousel slide'
              data-ride='carousel'
            >
              <ol className='carousel-indicators d-none d-lg-flex'>
                <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='0'
                  className='active'
                ></li>
                <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='1'
                ></li>
                <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='2'
                ></li>
                <li
                  data-target='#carouselExampleIndicators'
                  data-slide-to='3'
                ></li>
              </ol>
              <div className='carousel-inner '>{photos}</div>
              <a
                className='carousel-control-prev-custom d-none d-lg-block'
                href='#carouselExampleIndicators'
                role='button'
                data-slide='prev'
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='sr-only'>Previous</span>
              </a>
              <a
                className='carousel-control-next-custom d-none d-lg-block'
                href='#carouselExampleIndicators'
                role='button'
                data-slide='next'
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='sr-only'>Next</span>
              </a>
            </div>
          </div>
        </div>
        <div className='row align-items-center pt-5 pb-3'>
          <div className='col-12 col-md-8'>
            <h2 className='font-weight-bold m-0 py-2 head-color'>{address}</h2>
          </div>
          <div className='col-12 col-md-4 text-center'>
            {ready_delivery ? (
              // <p className='p-2 mt-2 SakneenColor'>Ready To move</p>
              <h6 className='text-uppercase'>
                <span className='badge badge-primary py-2 px-2 font-weight-bold readyToMove'>
                  Ready To move
                </span>
              </h6>
            ) : delivery_date ? (
              <h6 className='text-uppercase'>
                <span className='badge badge-primary py-2 px-2 font-weight-bold readyToMove'>
                  {delivery_date.day} {monthNames[delivery_date.month - 1]},{' '}
                  {delivery_date.year}
                </span>
              </h6>
            ) : // <h2 className='p-2 mt-2 text-center bg-dark text-white-50'>
            //   {delivery_date.day} {monthNames[delivery_date.month - 1]},{' '}
            //   {delivery_date.year}
            // </h2>
            null}
          </div>
        </div>
        <div className='row py-3'>
          <div className='col-4 col-lg-2 about-img'>
          <img
              src={require('../../../assets/houseCard/home-blue.svg')}
              alt='...'
          ></img> <span>{house_type}</span>
          </div>
          <div className='col-4 col-lg-2 about-img'>
          <img
              src={require('../../../assets/houseCard/space-blue.svg')}
              alt='...'
          ></img>
            <span>
              {bua} M <sup>2</sup>
            </span>
          </div>
          <div className='col-4 col-lg-2 about-img'>
          <img
              src={require('../../../assets/houseCard/map-blue.svg')}
              alt='...'
          ></img>
            <span>
              {land && land - bua} M <sup>2</sup>
            </span>
          </div>
          <div className='col-4 col-lg-2 about-img'>
          <img
              src={require('../../../assets/houseCard/bedroom-blue.svg')}
              alt='...'
          ></img> <span>{bedrooms}</span>
          </div>
          <div className='col-4 col-lg-2 about-img'>
          <img
              src={require('../../../assets/houseCard/bathroom-blue.svg')}
              alt='...'
          ></img> <span>{bathrooms}</span>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default HouseSlider
