import React from 'react';

import { withAuthorization } from '../../components/Session';
import { Link } from 'react-router-dom'; //NavLink
import { compose } from 'recompose';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

//integrated redux store with Acount component for protecting 
import { connect } from 'react-redux';

const AccountPage = ({ authUser }) => (

  <div>
    <h1>Account: {authUser.email}</h1>
    <PasswordForgetForm />
    <PasswordChangeForm />
    <Link className="btn btn-success" to={'/favorites'}>Favorites Houses</Link>
  </div>

);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition),
)(AccountPage);