import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyDB-eauEIRh03d0mBwi6CSwurFyK3lxHAM',
  authDomain: 'sakneen-test.firebaseapp.com',
  databaseURL: 'https://sakneen-test.firebaseio.com',
  projectId: 'sakneen-test',
  storageBucket: 'sakneen-test.appspot.com',
  messagingSenderId: '465624459938',
  appId: '1:465624459938:web:b85e9760dc174594b145fb',
  measurementId: 'G-6QWREVGB9H'
}

class Firebase {
  constructor () {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.storage = app.storage()

    this.storageRef = app.storage().ref('/users')

    //units
    this.unit = app.firestore()

    this.unitRef = app.firestore().collection('units') //testUnit
    //favorites
    this.favoritesRef = app.firestore().collection('favorites')

    //requests
    this.requestsRef = app.firestore().collection('requests')

    //users
    this.usersRef = app.firestore().collection('users')

    //requests services
    this.servicesRef = app.firestore().collection('services')
  }

  // *** Auth API ***
  //Sign up Method
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  //create user in firestore database
  createNewUser = (uid, fullName, phone, email) => {
    return this.usersRef.doc(uid).set({
      fullName: fullName,
      phone: phone,
      email: email,
      userRole: '1' //not admin user if userRole is (0) will be admin user can access control panel
    })
  }

  // sign in method
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  //sign out method
  doSignOut = () => {
    localStorage.removeItem('loggedUserId')
    this.auth.signOut()
  }
  //reset password method
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)
  //update/change password method
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  //*** User API ***
  user = uid => this.db.ref(`users/${uid}`)
  users = () => this.db.ref('users')

  //Get user data from firestroe
  getUserData = userId => this.usersRef.doc(userId).get()
  // console.log(userId);

  // *** Storage API ***
  doPut = async (file, uid) => {
    const fileType = file.type === 'application/pdf'
    if (fileType) {
      await this.storageRef
        .child(uid)
        .child(uid + '-proof1.pdf')
        .put(file)
    }
  }

  /**
   * @param no paramters
   * @description this is api for getting all units from database
   */
  getUnits = () => this.unitRef.get()

  //Search units API
  searchUnit = () => this.unitRef.get()

  //house detail
  houseDetails = houseId => this.unitRef.doc(houseId).get()

  //favorites
  addToFavorites = (user_id, unit_id) =>
    this.usersRef
      .doc(user_id)
      .collection('favorites')
      .doc(unit_id)
      .set({
        unitRef: this.unit.doc('/units/' + unit_id),
        datetime_added: Date.now(),
        unit_id: unit_id
      })

  // delete house from favorites
  deleteFromFavorites = (user_id, unit_id) =>
    this.usersRef
      .doc(user_id)
      .collection('favorites')
      .doc(unit_id)
      .delete()

  // get user favorites house
  getFavorites = user_id =>
    this.usersRef
      .doc(user_id)
      .collection('favorites')
      .get()

  //Request to buy house api
  requestToBuyHouse = request =>
    this.requestsRef.doc().set({
      unit_id: this.unit.doc('/units/' + request.unit_id),
      user_type: request.user_type,
      userFullName: request.userFullName,
      email: request.email,
      phone: request.phone,
      datetime_requested: Date.now()
    })

  //services Api
  requestSevice = info => {
    const { type } = info

    let service = {}

    if (type === 'finishAndFurnish') {
      const {
        location,
        address,
        isCompound,
        haveContractor,
        compundName,
        contractorName,
        user
      } = info
      service = {
        type,
        details: {
          location,
          address
        }
      }
      if (isCompound) {
        service.details.compundName = compundName
      }
      if (haveContractor === 'yes') {
        service.details.contractorName = contractorName
      }
      if (user) {
        service.user = user
      }
      this.servicesRef.doc().set(service)
    } else if (type === 'extendsPayment') {
      const {
        location,
        address,
        isCompound,
        compundName,
        totalExpectedPayment,
        totalPaymentPaid,
        dateOfDwonPayment,
        expectedFinalDatePayment,
        user
      } = info
      service = {
        type,
        details: {
          location,
          address,
          totalExpectedPayment,
          totalPaymentPaid,
          dateOfDwonPayment,
          expectedFinalDatePayment
        }
      }

      if (isCompound) {
        service.details.compundName = compundName
      }

      if (user) {
        service.user = user
      }

      this.servicesRef.doc().set(service)
    } else if (type === 'saleAndLeaseback') {
      const { location, address, isCompound, compundName, user } = info
      service = {
        type,
        details: {
          location,
          address
        }
      }
      if (isCompound) {
        service.details.compundName = compundName
      }
      if (user) {
        service.user = user
      }
      this.servicesRef.doc().set(service)
    }
  }
}

export default Firebase
