import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom' //NavLink
import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes'
// import { AuthUserContext } from '../Session';
import classes from './index.module.scss'
// import arabic from '../../assets/landingPage/language/arabic.png'
import logo from '../../assets/logoheader.png'
import whiteLogo from '../../assets/landingPage/whiteLogo.png'
// import { FormOfSignIn } from '../../pages/SignIn'
import Modal from '../LoggedInModal'
//Redux connect method for connect navigation component to global store
import { connect } from 'react-redux'

class Navigation extends Component {
  state = {
    showCollapsedNav: false
  }
  responsiveLandingNavHandler = e => {
    this.setState({
      showCollapsedNav: !this.state.showCollapsedNav
    })
    e.stopPropagation()
  }
  doCollapseNavHandler = e => {
    this.setState({
      showCollapsedNav: false
    })
    e.stopPropagation()
  }
  render () {
    const NavigationAuth = props => (
      <nav
        className={`navbar navbar-expand-lg 
        ${classes.upperNav} 
        ${props.isLandingPage ? `${classes.landingNav}` : ''} 
        ${props.isNavFixed ? `${classes.fixedNav}` : ''}
        ${this.state.showCollapsedNav ? `${classes.nonTransparentNav}` : ''}
        `}
      >
        <Link
          className={`navbar-brand ${classes.navbarBrand}`}
          onClick={this.doCollapseNavHandler}
          to={ROUTES.LANDING}
        >
          <img className={classes.logo} src={logo} alt='...' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span
            onClick={this.responsiveLandingNavHandler}
            className={`navbar-toggler-icon ${classes.navbarTogglerIcon}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div
          className={`collapse navbar-collapse ${
            this.state.showCollapsedNav ? 'show' : ''
          }`}
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav ml-auto'>
            {/* <li className={`nav-item ${classes.navItem}`}>
              <Link className="nav-link" to={ROUTES.LANDING}>SELL</Link>
            </li> */}
            <li className={`${classes.navItem}`}>
              {/* <p>placeholder to adjust elements inside ul</p> */}
              <Link
                className={`navbar-brand ${classes.fixedLandingLogo}`}
                to={ROUTES.LANDING}
                onClick={this.responsiveLandingNavHandler}
              >
                <img src={logo} alt='...' />
                <img src={whiteLogo} alt='...' />
              </Link>
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.buyItem}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                activeClassName={classes.activeNav}
                className='nav-link'
                to={ROUTES.SEARCH}
              >
                BUY
              </NavLink>
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.favItem}`}>
              <NavLink
                activeClassName={classes.activeNav}
                className='nav-link'
                to={ROUTES.FAVORITES}
              >
                FAVORITES
              </NavLink>
            </li>
            {/* <li className={`nav-item ${classes.flagContainer}`}>
              <NavLink className='nav-link' to={ROUTES.LANDING}>
                <img className={classes.flag} src={arabic} alt='' />
              </NavLink>
            </li>  */}
            <li className={`nav-item ${classes.get_aproved}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                to={ROUTES.FINISH_AND_FURNISH}
              >
                Tashteeb Financing
              </NavLink>
            </li>
            <SignOutButton
              authUser={this.props.authUser}
              styles={[classes.navItem, classes.favItem]}
            />
          </ul>
        </div>
      </nav>
    )
    const NavigationNonAuth = props => (
      <nav
        className={`navbar navbar-expand-lg 
        ${classes.upperNav} 
        ${props.isLandingPage ? `${classes.landingNav}` : ''} 
        ${props.isNavFixed ? `${classes.fixedNav}` : ''}
        ${this.state.showCollapsedNav ? `${classes.nonTransparentNav}` : ''}
        `}
      >
        <Link
          className={`navbar-brand ${classes.navbarBrand}`}
          onClick={this.doCollapseNavHandler}
          to={ROUTES.LANDING}
        >
          <img className={classes.logo} src={logo} alt='' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span
            onClick={this.responsiveLandingNavHandler}
            className={`navbar-toggler-icon ${classes.navbarTogglerIcon}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <div
          className={`collapse navbar-collapse ${
            this.state.showCollapsedNav ? 'show' : ''
          }`}
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav ml-auto'>
            <li className={`${classes.navItem}`}>
              {/* <p>placeholder to adjust elements inside ul</p> */}
              <Link
                onClick={this.responsiveLandingNavHandler}
                className={`navbar-brand ${classes.fixedLandingLogo}`}
                to={ROUTES.LANDING}
              >
                <img src={logo} alt='...' />
                <img src={whiteLogo} alt='...' />
              </Link>
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.buyItem}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                activeClassName={classes.activeNav}
                className='nav-link'
                to={ROUTES.SEARCH}
              >
                BUY
              </NavLink>
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.favItem}`}>
              {props.authUser ? (
                <NavLink
                  activeClassName={classes.activeNav}
                  className='nav-link'
                  to={ROUTES.FAVORITES}
                ></NavLink>
              ) : (
                <NavLink
                  // activeClassName={classes.activeNav}
                  className='nav-link'
                  to='#'
                >
                  <Modal fromNav={true} />
                </NavLink>
              )}
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.signInItem}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                activeClassName={classes.activeNav}
                className='nav-link'
                to={ROUTES.SIGN_IN}
              >
                SIGN IN
              </NavLink>
            </li>
            <li className={`nav-item ${classes.navItem} ${classes.signUpItem}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                activeClassName={classes.activeNav}
                className='nav-link'
                to={ROUTES.SIGN_UP}
              >
                JOIN
              </NavLink>
            </li>
            {/* <li className={`nav-item ${classes.flagContainer}`}>
              <NavLink className='nav-link' to={ROUTES.LANDING}>
                <img className={classes.flag} src={arabic} alt='flag' />
              </NavLink>
            </li> */}
            <li className={`nav-item ${classes.get_aproved}`}>
              <NavLink
                onClick={this.responsiveLandingNavHandler}
                to={ROUTES.FINISH_AND_FURNISH}
              >
                Tashteeb Financing
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    )
    return this.props.authUser ? (
      <NavigationAuth
        authUser={this.props.authUser}
        isLandingPage={this.props.isLandingPage}
        isNavFixed={this.props.isNavFixed}
      />
    ) : (
      <NavigationNonAuth
        authUser={this.props.authUser}
        isLandingPage={this.props.isLandingPage}
        isNavFixed={this.props.isNavFixed}
      />
    )
  }
}
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  isLandingPage: state.routerState.isLandingPage,
  isNavFixed: state.landingNavState.isNavFixed
})
export default connect(mapStateToProps)(Navigation)
