import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { withFirebase } from '../../components/Firebase'
import { withRouter } from 'react-router-dom'
import HouseCard from '../../components/HouseCard'
import Classes from '../../pages/Landing/index.module.scss'
import Swal from 'sweetalert2'

import { LoadingContainer } from '../../components/Loading'
import { connect } from 'react-redux'

const FavoritesPage = props => (
  <Fragment>
    <hr />
    <FavoritesView {...props} />
  </Fragment>
)

class Favorites extends Component {
  constructor (props) {
    super(props)
    this.state = {
      favoritesHouses: [],
      // userId: localStorage.getItem('loggedUserId'),
      loading: false
    }
  }
  deleteFromFavorites = (userId, houseId) => {
    const house = this.state.favoritesHouses.filter(
      house => house.id === houseId
    )
    const newHouses = this.state.favoritesHouses.filter(
      house => house.id !== houseId
    )
    this.setState({
      favoritesHouses: newHouses
    })
    this.props.firebase.deleteFromFavorites(userId, houseId).catch(err => {
      this.setState({
        favoritesHouses: this.state.favoritesHouses.concat(house)
      })
      Swal.fire({
        title: 'Sorry!',
        text: err.message,
        icon: 'error',
        confirmButtonText: 'Try again!'
      })
    })
  }

  componentDidMount () {
    if (this.props.location.pathname.includes('favorites')) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }
    let houseData = []
    let userId = localStorage.getItem('loggedUserId')
    this.props.firebase.getFavorites(userId).then(houses => {
      let favoritesHouses = houses.docs.map(house => house.data().unitRef.get())

      favoritesHouses.length === 0 &&
        this.setState({
          loading: true
        })

      favoritesHouses.map(house =>
        house.then(doc => {
          houseData = this.state.favoritesHouses
          houseData.push(doc.data())

          this.setState({
            favoritesHouses: [...houseData],
            loading: true
          })
        })
      )
    })
  }
  render () {
    if (!this.state.loading) {
      return <LoadingContainer />
    }
    const allUnits = this.state.favoritesHouses.map(unit => {
      return (
        <HouseCard
          key={unit.id}
          fromFavorites={true}
          houseId={unit.id}
          selectedClass='nonSelectedU'
          price={unit.financing.cash_price}
          house_type={unit.unit.house_type}
          picture={unit.unit.picture}
          address={unit.unit.address}
          city={unit.unit.city}
          bedrooms={unit.unit.bedrooms}
          bathrooms={unit.unit.bathrooms}
          land={unit.unit.land}
          bua={unit.unit.bua}
          unitSize={3}
          deleteFromFavorites={this.deleteFromFavorites}
        />
      )
    })
    return (
      <div className={`container-fluid ${Classes.FeaturedList}`}>
        <div className='row text-center'>
          <div className='col-12'>
            <h1 className='text-center mt-4 p-4 lead'>Favorites Houses </h1>
          </div>
        </div>
        <div className='row m-4'>{allUnits}</div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  units: state.unitState.units,
  authUser: state.sessionState.authUser
})

const FavoritesView = compose(withRouter, withFirebase)(Favorites)

export default connect(mapStateToProps)(FavoritesPage)
export { FavoritesView }
