import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../../FilterBar/FilterBar.module.scss'
import InputRange from 'react-input-range'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './index.css'


class FilterPrice extends Component {
  render () {
    const {
      priceState,
      toggleState,
      pricePaymentType,
      monthlyPaymentSearch,
      downpaymentSearch,
      rangeInputMonthlyPaymentValue,
      // paymentTypes,
      rangeInputDownpaymentValue,
      filterByPrice,

      toggleSearchHandler,
      resetFormHandler,
      paymentTypeHandler,
      handleChangeDownpayment,

      // monthlyPaymentScaleHandler,
      handleChangeMonthlyPayment
    } = this.props

    const numberShortner = number => {
      if (number >= 1000000) {
        number = number / 1000000 + 'M'
      } else if (number >= 1000) {
        number = number / 1000 + 'K'
      }
      return number
    }

    const priceRangeTextHandler = () => {
      let priceRangeText = 'Price Range'
      let downpayText = ''
      let monthlyText = ''
      const downPay = { ...rangeInputDownpaymentValue }
      const monthly = { ...rangeInputMonthlyPaymentValue }
      if (priceState.triggered) {
        priceRangeText = ''
        if (downPay.min !== 0 || downPay.max !== 0) {
          downPay.min = numberShortner(downPay.min)
          downPay.max = numberShortner(downPay.max)
          downpayText = (
            <span className={classes.downpayText}>
              {/* <span>Downpayment</span> */}
              <span>
                {downPay.min} : {downPay.max}
              </span>
              <span> - </span>
            </span>
          )
        }
        if (monthly.min !== 0 || monthly.max !== 0) {
          monthly.min = numberShortner(monthly.min)
          monthly.max = numberShortner(monthly.max)
          monthlyText = (
            <span className={classes.monthlyText}>
              {/* <span>Monthly</span> */}
              <span>
                {monthly.min} : {monthly.max}
              </span>
            </span>
          )
        }
        priceRangeText = (
          <span>
            {downpayText}
            {monthlyText}
          </span>
        )
      }
      return priceRangeText
    }

    const minDownpayment = Number(
      rangeInputDownpaymentValue.min
    ).toLocaleString('en', {
      useGrouping: true
    })

    const maxDownpayment = Number(
      rangeInputDownpaymentValue.max
    ).toLocaleString('en', {
      useGrouping: true
    })

    const minMonthlyPayment = Number(
      rangeInputMonthlyPaymentValue.min
    ).toLocaleString('en', {
      useGrouping: true
    })

    const maxMonthlyPayment = Number(
      rangeInputMonthlyPaymentValue.max
    ).toLocaleString('en', {
      useGrouping: true
    })

    return (
      <div className={`${classes.optionsContainer} col-lg p-0`}>
        <button
          className={` ${classes.priceToggleBtn} ${
            priceState.triggered ? `${classes.triggered}` : ''
          } ${!toggleState.price ? `${classes.active}` : ''}`}
          onClick={() => toggleSearchHandler('price')}
        >
          <img
            src={require('../../../assets/filterbar/price.svg')}
            className={classes.homeIcon}
            alt='...'
          ></img>
          <span>{priceRangeTextHandler()}</span>
          <span
            className={classes.resetFormBtn}
            onClick={event =>
              resetFormHandler(
                event,
                pricePaymentType === 'monthlyPayment'
                  ? 'monthlyPayment'
                  : 'downpayment',
                this
              )
            }
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </button>
        <div
          className={`${classes.searchOptions} ${classes.priceRange}`}
          hidden={toggleState.price}
        >
          <form onSubmit={filterByPrice}>
            <div
              className={`${classes.cashSection} ${
                downpaymentSearch ? '' : `${classes.disabled}`
              }`}
            >
              <div className={`${classes.priceSearchPaymentType} mb-3`}>
                <input
                  type='checkbox'
                  name='priceSearchPaymentType'
                  value='downpaymentSearch'
                  checked={downpaymentSearch}
                  onChange={event => paymentTypeHandler(event)}
                />
                <span></span>
                <p>Downpayment</p>
              </div>
              <InputRange
                maxValue={3000000}
                minValue={0}
                step={100000}
                value={rangeInputDownpaymentValue}
                disabled={!downpaymentSearch}
                onChange={value => handleChangeDownpayment(value)}
              />
              <div className={`${classes.cashScaleValues} mb-2`}>
                <span>{minDownpayment}</span> -<span>{maxDownpayment}</span>
                <sub className='text-primary px-1'>EGP</sub>
              </div>
            </div>
            <div
              className={`${classes.cashSection} ${
                monthlyPaymentSearch ? '' : `${classes.disabled}`
              }`}
            >
              <div className={`${classes.priceSearchPaymentType} mb-3`}>
                <input
                  type='checkbox'
                  name='priceSearchPaymentType'
                  value='monthlyPaymentSearch'
                  checked={monthlyPaymentSearch}
                  onChange={event => paymentTypeHandler(event)}
                  // onClick={() => {
                  //   monthlyPaymentScaleHandler(rangeInputMonthlyPaymentValue)
                  // }}
                />
                <span></span>
                <p>Monthly Payment</p>
              </div>
              <InputRange
                maxValue={100000}
                minValue={5000}
                step={5000}
                value={rangeInputMonthlyPaymentValue}
                disabled={!monthlyPaymentSearch}
                onChange={value => handleChangeMonthlyPayment(value)}
              />
              <div className={`${classes.cashScaleValues} mb-2`}>
                <span>{minMonthlyPayment}</span> -
                <span>{maxMonthlyPayment}</span>
                <sub className='text-primary px-1'>EGP</sub>
              </div>
            </div>
            <button
              disabled={!downpaymentSearch  && !monthlyPaymentSearch}
              className={`btn btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold ${classes.applyBtn} ${classes.fixApplyBtn}`}>
              Apply
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default FilterPrice
