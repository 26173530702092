import { isNavFixed } from '../Actions/landingScrollActions';
import { FIXED_NAV } from '../Constants/landingScrollConstants';

const INITIAL_STATE = {
    isNavFixed: false
};
function landingScrollReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case FIXED_NAV: {
      return isNavFixed(state, action);
    }
    default:
      return state;
  }
}

export default landingScrollReducer;