import React, { Component } from 'react'
import './index.scss'

class PhotoGallery extends Component {
  componentDidMount () {
    const { HouseData } = this.props
    console.log('HouseData:', HouseData)
  }
  render () {
    const { HouseData } = this.props
    const photos = HouseData
      ? HouseData.photos.map((photo, index) => {
          if (index === 0) {
            return (
              <div className='carousel-item active' key={photo + Date.now}>
                <img
                  onClick={this.toggle}
                  className='d-block w-100'
                  src={photo}
                  alt=''
                />
              </div>
            )
          } else {
            return (
              <div className='carousel-item' key={Math.random()}>
                <img
                  onClick={this.toggle}
                  className='d-block w-100'
                  src={photo}
                  alt=''
                />
              </div>
            )
          }
        })
      : null
    return (
      <div
        id='carouselExampleControls'
        className='carousel slide'
        data-ride='carousel'
      >
        <div className='carousel-inner'>{photos}</div>
        <a
          className='carousel-control-prev'
          href='#carouselExampleControls'
          role='button'
          data-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='sr-only'>Previous</span>
        </a>
        <a
          className='carousel-control-next'
          href='#carouselExampleControls'
          role='button'
          data-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='sr-only'>Next</span>
        </a>
      </div>
    )
  }
}
export default PhotoGallery
