export const LANDING = '/';
export const SEARCH = '/search';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const HOUSE = '/house';
export const ADMIN = '/admin';
export const FAVORITES = '/favorites';
export const PASSWORD_FORGET = '/pw-forget';

export const EXTEND_PAYMENT = '/extends-payment';
export const SALES_AND_LEASEBACK = '/sales-and-leaseback';
export const FINISH_AND_FURNISH = '/finish-and-furnish';