const addSearchParam = (state, action) => ({
    ...state,
    ...action.payload
});

const removeSearchParam = (state, action) => ({
    ...state,
    ...action.payload
});

const clearSearch = (initialState) => ({
    ...initialState
});

export { addSearchParam, removeSearchParam, clearSearch }