import { applySetUnits,applyGetUnits } from '../Actions/unitActions';
import { UNITS_SET } from '../Constants/unitConstants';

const INITIAL_STATE = {
  units: null,
};
function unitReducer(state=INITIAL_STATE , action) {
  switch (action.type) {
    case UNITS_SET: {
      return applySetUnits(state, action);
    }
    case 'UNITS_GET': {
      return applyGetUnits(state, action);
    }
    default:
      return state;
  }
}

export default unitReducer;