import React, { Component } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import Classes from './index.module.scss'
import logo from '../../assets/footerlogo.png'

class FooterPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contnet: []
    }
  }
  render () {
    return (
      <div className={`container-fluid p-0 m-0 ${Classes.Footer}`}>
        <div className='row p-0 m-0'>
          <div className='col-sm-12 col-md-5 col-lg-4 p-0 m-0'>
            <div className={`${Classes.leftSide}`}>
              <img
                className={`${Classes.logo}`}
                src={logo}
                alt='Sakneen Logo'
              />
              <p className={`${Classes.text}`}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum
              </p>
              {/* <div className={`${Classes.icons}`}>
                <a href='http://www.fb.com'>
                  <img src={require('../../assets/fb.svg')} alt='fb' />
                </a>
                <a href='http://www.twitter.com'>
                  <img src={require('../../assets/tw.svg')} alt='tw' />
                </a>
                <a href='http://www.instagrm.com'>
                  <img src={require('../../assets/inst.svg')} alt='inst' />
                </a>
                <p className={`${Classes.copyRight}`}>
                  All rights reserved to sakneen.com
                </p>
              </div> */}
            </div>
          </div>
          <div className='col-sm-12 col-md-7 col-lg-8 p-0 m-0 d-none d-lg-block'>
            <div className={`${Classes.rightSide}`}></div>
          </div>
        </div>
      </div>
    )
  }
}

const Footer = compose(withRouter, withFirebase)(FooterPage)

export default Footer
