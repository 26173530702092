import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { a, b, c, e } from '../DevelopersLogo';

import classes from './Developers.module.scss';
import secHeadClasses from '../sectionsHeaders.module.scss';

const DevolpersPage = (props) => (
    <Fragment>
        <Devolpers {...props} />
    </Fragment>
);

class DevolpersBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devolpers: []
        }

    }

    render() {
        return (
            <div className={`container-fluid text-center ${classes.developersSection}`}>
                <div className="row text-center">
                    <div className={`col-12 ${secHeadClasses.headingContainer}`}>  
                        <div className={secHeadClasses.headingImgContainer}>
                            <img
                                src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                                alt='Sakneen logo'>
                            </img>
                        </div>
                        <h1 className={`text-center ${secHeadClasses.headingText}`}> Trusted by the World's Best Companies</h1>
                    </div>
                </div>
                <div className={`row ${classes.trustedLogosContainer}`}>
                {/* <div className={`${classes.trustedLogosContainer}`}> */}
                    {/* <div className="col-12 col-md-3"> */}
                    <div>
                        <img src={a} alt="..." />
                    </div>
                    {/* <div className="col-12 col-md-2"> */}
                    <div>
                        <img src={b} alt="..." />
                    </div>
                    {/* <div className="col-12 col-md-2"> */}
                    <div>
                        <img src={c} alt="..." />
                    </div>
                    {/* <div className="col-12 col-md-2"> */}
                    <div>
                        <img src={e} alt="..." />
                    </div>
                    {/* <div className="col-12 col-md-3"> */}
                    <div>
                        <img src={e} alt="..." />
                    </div>
                </div>
            </div>
        )
    }
}

const Devolpers = compose(
    withRouter,
    withFirebase,
)(DevolpersBase);

export default DevolpersPage;
export { Devolpers };