import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import classes from './index.module.scss';

import { ReduxLandingSearch } from '..';

const LandingPageHeader = (props) => (
    <Fragment>
        <LandingHeader {...props} />
    </Fragment>
);

class landingHeaderBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonals: []
        }

    }

    render() {
        return (
            <div className={`container-fluid ${classes.headerOverAll}`}>
                <div className="row">                   
                    <div className="col-12 p-0">
                        <div className={classes.headerContainer}>
                            <div className={classes.aboveLayerContent}>
                                <img className={classes.MidLogo} src={require('../../../assets/landingPage/whiteLogo.png')} alt="Sakneen logo"></img>
                                <p className={classes.moveIn}>
                                    Move in Today, Pay over 10-15 Years.
                                </p>
                            </div>
                            <div className={`${classes.search}`}>
                                <ReduxLandingSearch
                                    units={this.props.units}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.headerBackGroundLogoContainer}>
                        <img className={classes.headerBackGroundLogo} src={require('../../../assets/landingPage/bkgH2.png')} alt="Sakneen logo"></img>
                    </div>
                </div>
            </div>
        )
    }
}

const LandingHeader = compose(
    withRouter,
    withFirebase,
)(landingHeaderBase);

export default LandingPageHeader;
export { LandingHeader };