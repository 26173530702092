import React from 'react'
import ReactDOM from 'react-dom'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './index.css'
import './custom.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import * as serviceWorker from './serviceWorker'

import App from './pages/App'
import Firebase, { FirebaseContext } from './components/Firebase'

//redux integration with app
import { Provider } from 'react-redux'
import store from './redux/Store' //import store creation

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
)
serviceWorker.unregister()
