import React, { Component } from 'react'
import { compose } from 'recompose'
import { withFirebase } from '../../Firebase'
import { withRouter } from 'react-router-dom'
import './index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.min.js'
import Slider from 'react-slick'

import secHeadClasses from '../sectionsHeaders.module.scss'

class TestimonalBase extends Component {
  render () {
    const settings = {
      centerMode: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      centerPadding: '25%',
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    }
    return (
      <div className='container-fluid testimonal d-none d-lg-block'>
        <div className='row text-center'>
          <div className={`col-12 ${secHeadClasses.headingContainer}`}>
            <div className={secHeadClasses.headingImgContainer}>
              <img
                src={require('../../../assets/landingPage/headerBackGroundLogo2.png')}
                alt='Sakneen logo'
              ></img>
            </div>
            <h1 className={`text-center ${secHeadClasses.headingText}`}>
              Testimonials
            </h1>
          </div>
        </div>        
        <div className='row p-0 m-0'>
          <div className='col-lg-12 m-auto text-center slideContainer'>
            <Slider {...settings}>
              <div className='card'>
                <img
                  className='img-thumbnail'
                  src={require('../../../assets/landingPage/avatar.png')}
                  alt='...'
                />
                <p className='m-0 p-0 heading'>
                  Lorem Ipsum is simply dummy text of the printing
                </p>
                <p className='m-0 p-0 body'>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </p>
                <p className='text-uppercase text-center footer'>
                  Milad Ezzat - <span className='text-capitalize'>Trader</span>
                </p>
              </div>
              <div className='card'>
                <img
                  className='img-thumbnail'
                  src={require('../../../assets/landingPage/avatar.png')}
                  alt='...'
                />
                <p className='m-0 p-0 heading'>
                  Lorem Ipsum is simply dummy text of the printing
                </p>
                <p className='m-0 p-0 body'>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </p>
                <p className='text-uppercase text-center footer'>
                  Milad Ezzat - <span className='text-capitalize'>Trader</span>
                </p>
              </div>
              <div className='card'>
                <img
                  className='img-thumbnail'
                  src={require('../../../assets/landingPage/avatar.png')}
                  alt='...'
                />
                <p className='m-0 p-0 heading'>
                  Lorem Ipsum is simply dummy text of the printing
                </p>
                <p className='m-0 p-0 body'>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </p>
                <p className='text-uppercase text-center footer'>
                  Milad Ezzat - <span className='text-capitalize'>Trader</span>
                </p>
              </div>
              <div className='card'>
                <img
                  className='img-thumbnail'
                  src={require('../../../assets/landingPage/avatar.png')}
                  alt='...'
                />
                <p className='m-0 p-0 heading'>
                  Lorem Ipsum is simply dummy text of the printing
                </p>
                <p className='m-0 p-0 body'>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </p>
                <p className='text-uppercase text-center footer'>
                  Milad Ezzat - <span className='text-capitalize'>Trader</span>
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

const Testimonial = compose(withRouter, withFirebase)(TestimonalBase)

export default Testimonial
