import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { SignUpLink } from '../SignUp'
import Modal from '../../components/ForgetPasswordModal'
import { withFirebase } from '../../components/Firebase'
import * as ROUTES from '../../constants/routes'
import classes from './index.module.scss'
import Swal from 'sweetalert2'
import './index.scss'
import { withAuthorization } from '../../components/Session'
import { connect } from 'react-redux'
import JoinFooter from '../../components/JoinFooter'


const SignInBase = props => {
  return (
    <div className={`container-fluid p-0 m-0`} id='signin'>
      <div className='row p-0 m-0'>
        <FormOfSignIn {...props} />
        <div
          className={`col-12 col-lg-4 p-0 m-0 ${classes.sideFooter} ${
            props.isModal ? `${classes.insideModal}` : ''
          } sideFooter`}
        >
          <JoinFooter />
          {/* footer */}
        </div>
      </div>
    </div>
  )
}
const INITIAL_STATE = {
  email: '',
  password: '',
  hidePassword: true,
  error: null,

  emailStatus: false,
  passwordStatus: false
}
export class FormOfSignIn extends Component {
  constructor (props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }
  componentDidMount () {

    const mql = window.matchMedia('(max-width: 992px)')
    if (this.props.location.pathname.includes('signin') && mql.matches) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    } else {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }
  }
  onSubmit = event => {
    event.preventDefault()
    const { email, password } = this.state
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {   

        localStorage.removeItem('loggedUserId')
        localStorage.setItem('loggedUserId', authUser.user.uid)
        this.setState({ ...INITIAL_STATE })
        this.props.toggle && this.props.toggle()
        this.props.history.push(ROUTES.SEARCH)
      })
      .catch(error => {
        Swal.fire({
          title: 'Sorry!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Try Again'
        })
      })
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  toggleShow = () => {
    if (this.state.password) {
      this.setState({ hidePassword: !this.state.hidePassword })
    }
  }
  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  checkStatus = e => {
    this.setState({
      [e.target.name + 'Status']: true
    })
  }
  render () {
    const {
      email,
      password,
      hidePassword,
      emailStatus,
      passwordStatus
    } = this.state
    const { modal } = this.props

    const emailValidate = email !== '' && this.validateEmail(email)

    const passwordValidate = password !== '' && password.length >= 8

    const isInvalid = !emailValidate || !passwordValidate

    const validation = (e, validateStatus) => {
      let target = e.target
      if (validateStatus) {
        target.classList.remove('confirm')
        target.classList.add('confirmed')
      } else {
        target.classList.remove('confirmed')
        target.classList.add('confirm')
      }
    }
    return (
      <div
        className={`${!modal ? 'col-12 col-lg-8 p-5 pb-0 pb-lg-3' : 'col-12'} ${
          classes.leftFormContainer
        }`}
      >
        <div
          className={`container-fluid p-0 m-0 ${classes.innerContainerFluid}`}
        >
          <div className={`row p-0 m-0`}>
            <div className={`col-12`}>
              <h1
                className={`text-center pt-4  ${!modal ? 'text-left' : null} ${
                  classes.header
                }`}
              >
                Welcome To Sakneen
              </h1>
            </div>
            <div
              className={`col-12 col-md-12 col-lg-12 text-center ${
                !modal ? 'text-left' : null
              }`}
            >
              <p className='m-0 pb-2'>Signup now and start your free trial</p>
            </div>
            {!modal && (
              <div
                className={`col-12 col-md-12 col-lg-12 text-center ${
                  !modal ? 'text-left' : null
                }`}
              >
                <SignUpLink style={classes.signupLink} />
              </div>
            )}
            <div className={`col-12 p-0 m-0`}>
              <form onSubmit={this.onSubmit}>
                <div className={`container-fluid p-0 m-0`}>
                  <div className={`row p-0 m-0`}>
                    <div
                      className={
                        !modal ? `col-12 col-md-12 col-lg-6` : `col-12`
                      }
                    >
                      <div
                        className={`form-group m-0 p-0  ${classes.formGroup}`}
                      >
                        <input
                          className={`form-control mr-1 rounded-0
                            `}
                          name='email'
                          value={email}
                          aria-describedby='helpEmail'
                          onBlur={e => {
                            validation(e, emailValidate)
                            this.checkStatus(e)
                          }}
                          onChange={this.onChange}
                          type='text'
                        />
                        <label
                          className={`${email ? `${classes.hiddenLabel}` : ''}`}
                        >
                          E-mail
                        </label>
                        {emailStatus &&
                          (emailValidate ? null : (
                            <small
                              id='helpEmail'
                              className='form-text minPassword'
                            >
                              Email should be correct Format
                            </small>
                          ))}
                      </div>
                    </div>
                    <div
                      className={
                        !modal ? `col-12 col-md-12 col-lg-6` : `col-12`
                      }
                    >
                      <div
                        className={`form-group m-0 p-0  ${classes.formGroup}`}
                      >
                        <input
                          className={`form-control ml-1 rounded-0
                            `}
                          name='password'
                          value={password}
                          onChange={this.onChange}
                          onBlur={e => {
                            validation(e, passwordValidate)
                            this.checkStatus(e)
                          }}
                          aria-describedby='helpPassword'
                          type={hidePassword ? 'password' : 'text'}
                        />
                        <label
                          className={`${
                            password ? `${classes.hiddenLabel}` : ''
                          }`}
                        >
                          Password
                        </label>
                        <FontAwesomeIcon
                          onClick={this.toggleShow}
                          hidden={!hidePassword}
                          icon={faEye}
                        />
                        <FontAwesomeIcon
                          onClick={this.toggleShow}
                          hidden={hidePassword}
                          icon={faEyeSlash}
                        />
                        {passwordStatus &&
                          (passwordValidate ? null : (
                            <small
                              id='helpPassword'
                              className='form-text minPassword'
                            >
                              Password must be at least 8 characters
                            </small>
                          ))}
                      </div>
                    </div>
                    <div className={`col-12 col-md-6 my-3`}>
                      <div className={`remember-me mr-2 ${classes.rememberMe}`}>
                        <input type='checkbox'></input>
                        <span></span>
                        <p className={classes.remember}>Remember Me</p>
                      </div>
                    </div>
                    <div
                      className={`col-12 col-md-6 my-3 clearfix ${classes.forgetPassword}`}
                    >
                      <div className={`float-md-right`}>
                        <Modal />
                      </div>
                    </div>
                    <div className={`col-12 ${classes.signUpSection}`}>
                      <button
                        className='btn btn-primary rounded-sm text-uppercase btn-block py-3 border-0 font-weight-bold my-4'
                        disabled={isInvalid}
                      >
                        SIGN IN
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const SignInLink = () => (
  <p>
    You have account? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
  </p>
)
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})

const condition = authUser => !!!authUser

const SingInPage = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps),
  withAuthorization(condition)
)(SignInBase)

export default SingInPage
export { SignInLink }
