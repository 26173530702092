import React, { Fragment, Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Navigation from '../../components/Navigation'

import LandingPage from '../Landing'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import HomePage from '../Home'
import AccountPage from '../Account'
import HousePage from '../House'
import Favorites from '../Favorites'
import Buy from '../Buy'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../../components/Session'
import {
  ExtendsPayment,
  SaleAndLeaseback,
  FinishAndFurnish
} from '../../components/servicesPages'

class App extends Component {
  render () {
    return (
      <Router>
        <Fragment>
          <Navigation />
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.SEARCH} component={Buy} />
          <Route path={ROUTES.FAVORITES} component={Favorites} />
          <Route path={ROUTES.HOUSE + '/:house_id'} component={HousePage} />
          <Route path={ROUTES.EXTEND_PAYMENT} component={ExtendsPayment} />
          <Route
            path={ROUTES.SALES_AND_LEASEBACK}
            component={SaleAndLeaseback}
          />
          <Route
            path={ROUTES.FINISH_AND_FURNISH}
            component={FinishAndFurnish}
          />
        </Fragment>
      </Router>
    )
  }
}

export default  withAuthentication(App)
