import React, { Component, Fragment } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import PasswordForgetPage from '../../pages/PasswordForget'

class ModalBase extends Component {
  state = {
    modal: false //for toggle modal
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }
  render () {
    return (
      <Fragment>
        <Link className='pl-2' to='#' onClick={this.toggle}>
          Forget password
        </Link>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader className='px-0 px-5' toggle={this.toggle}>
            Reset Password
          </ModalHeader>
          <ModalBody>
            <PasswordForgetPage toggle={this.toggle} />
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default ModalBase
