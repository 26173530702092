import React, { Component, Fragment } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { FormOfSignIn } from '../../pages/SignIn'
import { FormOfSignUp } from '../../pages/SignUp'
import './index.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import classes from '../HouseCard/HouseCard.module.scss'
import { withFirebase } from '../Firebase'

import styles from './index.module.scss'

class ModalBase extends Component {
  state = {
    modal: false, //for toggle modal
    view: true
  }
  componentDidMount () {
    this.setState({
      modal: this.props.modal
    })
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }
  signInView = () => {
    this.setState({
      view: true
    })
  }
  signUpView = () => {
    this.setState({
      view: false
    })
  }
  render () {
    return (
      <Fragment>
        {this.props.fromNav ? (
          <span className='text-uppercase' onClick={this.toggle}>
            Favorites
          </span>
        ) : this.props.authUser ? (
          <FontAwesomeIcon
            className={`${classes.favorites}   
              ${this.props.isFavorite ? classes.favorite : null}  
            `}
            icon={faHeart}
            onClick={() => {
              this.props.toggleFavorite(
                !this.props.isFavorite,
                this.props.houseId
              )
            }}
            size='2x'
          />
        ) : (
          <FontAwesomeIcon
            className={`${classes.favorites}`}
            icon={faHeart}
            onClick={this.toggle}
            size='2x'
          />
        )}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={`loggedInModal ${styles.makeMoreSpacy}`}
        >
          <div className={`container-fluid p-0 m-0 text-center`}>
            <div className={`row p-0 m-0`}>
              <div className={`col-6 p-0 m-0`}>
                <p
                  className={` px-0 py-3 m-0 font-weight-bold ${
                    styles.modalBtn
                  } ${
                    this.state.view ? styles.activeBtn : styles.notactiveBtn
                  }`}
                  onClick={this.signInView}
                >
                  Sign in
                </p>
              </div>
              <div className={`col-6 p-0 m-0`}>
                <p
                  className={`px-0 py-3 m-0 font-weight-bold ${
                    styles.modalBtn
                  } ${
                    this.state.view ? styles.notactiveBtn : styles.activeBtn
                  }`}
                  onClick={this.signUpView}
                >
                  Register
                </p>
              </div>
            </div>
          </div>
          <ModalBody className={``}>
            <div className={`container-fluid p-0 m-0`}>
              <div className={`row p-0 m-0`}>
                {this.state.view ? (
                  <FormOfSignIn
                    {...this.props}
                    modal={true}
                    toggle={this.toggle}
                  />
                ) : (
                  <FormOfSignUp
                    {...this.props}
                    modal={true}
                    toggle={this.toggle}
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(ModalBase)
