import React, { Component, Fragment } from 'react'
import styles from './index.module.scss'

class AboutHouse extends Component {
  render () {
    const { houseDetails } = this.props
    const { description, floorplan, isHaveFloorPlan } =
      Boolean(houseDetails.unit) && houseDetails.unit
    return (
      <Fragment>
        <div className={`row pb-2 ${styles.abouthouse}`}>
          <div className='col-12'>
            <h4 className='pt-5 pb-4'>About the property</h4>
            <p className={`${styles.fixP} pb-3`}>{description}</p>
          </div>
        </div>
        {isHaveFloorPlan ? (
          <div className='row'>
            <div className='col-12 pb-5'>
              <h2 className='pt-1 pb-5'>Floorplan</h2>
              <img
                className='img-fluid w-50  bg-success shadow-sm'
                src={floorplan}
                alt='..'
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}
export default AboutHouse
