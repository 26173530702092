import React, { Component, Fragment } from 'react'
import { withFirebase } from '../../components/Firebase'
import { withRouter } from 'react-router-dom'

import FilterBar from '../../components/FilterBar'
// import MapsContainer from '../../components/MapsContainer'
import MapsContainer from '../../components/Maps'
import Listings from '../../components/Listings/Listings.js'
import classes from './index.module.scss'

import { connect } from 'react-redux'
import { compose } from 'redux'

import { UNITS_SET } from '../../redux/Constants/unitConstants'

import lodash from 'lodash'

import { LoadingContainer } from '../../components/Loading'

const Buy = () => (
  <Fragment>
    <UnitView />
  </Fragment>
)
class BuyBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      viewMapAndCards: true, //for trigger view (cards only or cards and map)
      // filters: [], // array of objects  for filters without range
      // FiltersObjectsRange: [], // array of objects for filters with range
      loading: false,
      units: []
    }
  }
  /**
   * @param no paramters
   * @fires getUnits() api, to get all units from database
   * @listens this method listen when component mounted
   * @description componentDidMount, it is life cycle method that fire after mounted component
   */
  async componentDidMount () {

    const mql = window.matchMedia('(min-width: 780px)')
    if (this.props.location.pathname === '/search' && mql.matches) {
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }

    let units = await this.props.firebase.getUnits()

    if (!units.length) {
      this.setState({ loading: true })
    }
    let allUnits = units.docs.map(doc => {
      // return{
      //   id: doc.id,
      //   financing:{
      //     cash_price: doc.data().cash_price,
      //     developer_fin: doc.data().developer_fin,
      //     mortgage_fin:doc.data().mortgage_fin
      //   }
      // seller:{

      // }
      // }
      return {
        id: doc.id, //document id (firestore id)
        unit: doc.data().unit, // unit data
        seller: doc.data().seller, // unit seller data
        financing: doc.data().financing //unit financing data
        // ...doc.data()
      }
    })
    // console.log(allUnits)

    this.setState({
      units: allUnits
    })

    if (this.props.location.state) {
      allUnits = this.props.location.state.units
    }

    this.props.onSetUnits(allUnits)
  }
  //clear search Landing results
  clearFilters = () => {
    this.props.onClearFilters();
  }

  clearMoreFilters = () => {
    this.props.onClearMoreFilters();
  }

  /**
   * @param no paramters
   * @fires none
   * @listens onClick on Card and Maps button in filter Bar
   * @returns none
   * @description this method  will change the state of viewMapCard  from true to false
   */
  showCardsOnly = () => {
    this.setState({
      viewMapAndCards: false
    })
  }

  /**
   * @param no paramters
   * @fires none
   * @listens onClick on Card and Maps button in filter Bar
   * @returns none
   * @description this method  will change the state of viewMapAndCard from false to true
   */
  showCardsAndMaps = () => {
    this.setState({
      viewMapAndCards: true
    })
  }
  // view object that contain to method of custom view
  view = {
    showCardsOnly: this.showCardsOnly,
    showCardsAndMaps: this.showCardsAndMaps
  }
  // adz = () => {
  //   for (let i = 0; i < 4; i++) {
  //     this.addNewFilter()
  //   }
  // }
  /**
   * @fires none
   * @listens Apply button it sent as props  in FilterBare component
   * @param {String} type
   * @param {Array} values
   * @description this method for adding filter object to array that filtered the data by without range filter
   */
  addNewFilter = (type, values = []) => {
    //create new filter object
    let newFilter = {
      type: type, //type based on field name in DB
      values: values //value will filter based on them
    }

    let filters = this.props.filters // existing without range  filters objects on state
    // console.log(filters)
    let flag = false // for triggered if filter already exists
    // console.log(newFilter)
    // console.log(filters)
    //loop on all filters exist
    for (let i = 0; i < filters.length; i++) {
      console.log('XXXXXXXXXXXXXXXXXX')
      //for change filter with new values of already exists
      if (filters[i].type === newFilter.type) {
        filters[i] = newFilter
        // this.props.onEditFilter(newFilter, i)
        flag = true //change flag to true, this mean the filter exists before
        break //out of loop
      }
    }
    // if filter not exists, will push on filters array
    if (!flag) {
      filters.push(newFilter)
      // this.props.onAddFilter(newFilter)
    }

    //change filters on state to new filters
    // this.setState({
    //   filters: filters
    // })
    // console.log(filters)
    this.props.onModifyFilters(filters);
  }

  /**
   * @fires none
   * @listens resetFormHandler it sent as props  in FilterBare component
   * @param {String} type
   * @description This method will remove the filter from filters array
   */
  removeFilter = type => {
    //delete filter based on type filter
    console.log(type)
    console.log(this.props.filters)
    let newFilters = this.props.filters.filter(filter => filter.type !== type)
    // let newFilters = this.props.filters.filter(filter => filter.type !== type)
    console.log(newFilters)

    if (type === 'city' || type === 'place') {
      newFilters = newFilters
        .filter(filter => filter.type !== 'city')
        .filter(filter => filter.type !== 'place')
    }
    // //change filters to newFilter after remove filter
    // // this.setState({
    // //   filters: newFilters
    // // })
    // console.log(newFilters)
    this.props.onModifyFilters(newFilters);
  }

  removeMoreFilters = () => {
    let newFilters = this.props.filters.filter(filter => filter.type !== 'bedrooms')
    .filter(filter => filter.type !== 'bathrooms')
    .filter(filter => filter.type !== 'delivery_date')
    .filter(filter => filter.type !== 'developer_name')
    .filter(filter => filter.type !== 'compound_name')
    console.log(newFilters)
    this.props.onModifyFilters(newFilters);
  }

  /**
   * @fires none
   * @listens Apply button it sent as props  in FilterBare component
   * @param {String} type
   * @param {Number} min
   * @param {Number} max
   * @description this method for adding filter object to array that filtered the data by with range filter
   */
  addNewRangeFilter = (type, min, max) => {
    //create new filter object
    let newFilter = {
      type: type, //type of filter based on field name in database
      min: min, //min value should be value have it
      max: max //max value should be have it
    }

    //all filters allready exist
    let filters = this.props.rangeFilters
    let flag = false // to trigger if filter exist

    for (let i = 0; i < filters.length; i++) {
      //for change filter with new values of already exists
      if (filters[i].type === newFilter.type) {
        filters[i] = newFilter
        flag = true
        break
      }
    }
    //if filter not exist in array
    if (!flag) {
      filters.push(newFilter)
    }

    // this.setState({
    //   FiltersObjectsRange: filters
    // })
    this.props.onModifyRangeFilters(filters);
  }

  /**
   * @fires none
   * @listens resetFormHandler it sent as props  in FilterBare component
   * @param {String} type
   * @description This method will remove the filter from filters array for filter with range
   */
  removeRangeFilter = type => {
    if (type === 'monthlyPayment' || type === 'downpayment') {
      // const types = ['downpayment', 'monthlyPayment']
      // let newFilters = [...this.props.rangeFilters].filter(
      //   filter => filter.type !== types[0]
      // )
      // newFilters = newFilters.filter(filter => filter.type !== types[1])
      let newFilters = this.props.rangeFilters.filter(
        filter => filter.type !== 'monthlyPayment' && filter.type !== 'downpayment'
      )
      // this.setState({
      //   FiltersObjectsRange: newFilters
      // })
      this.props.onModifyRangeFilters(newFilters);
    } else {
      let newFilters = this.state.FiltersObjectsRange.filter(
        filter => filter.type !== type
      )
      // this.setState({
      //   FiltersObjectsRange: newFilters
      // })
      this.props.onModifyRangeFilters(newFilters);
    }
  }

  /**
   * @fires none
   * @listens myfilter
   * @param {Array[Object]} filtersObjects
   * @param {Array} units
   * @description This method with filter unit based on filter with range values
   */
  FilterWithRange = (filtersObjects, units) => {
    // console.log(filtersObjects)
    //set unit to unitData
    let unitsData = units

    //filter based on array of objects filter
    filtersObjects.map(filter => {
      let filterType = filter.type // filter type
      let minValue = filter.min // min value
      let maxValue = filter.max
      if (filterType === 'downpayment') {
        unitsData = this.addDownpayment(units)
      } else if (filterType === 'monthlyPayment') {
        // console.log('monthlyPayment')
        unitsData = this.addmonthlyPayment(units)
      }
                                 
      //filter units based on filter type and min , max value if unit.unit[type] greater than or equal min value and less than or equal  max value will return unit data
      let newFilteredUnits = unitsData.filter(
        unit =>
          unit.unit[filterType] >= minValue && unit.unit[filterType] <= maxValue
      )

      unitsData = newFilteredUnits //set filtered units to unitsData again for filter based on onthor filter
      return {} //return empty object for prevent error return need in map
    })

    return [...new Set(unitsData)] // return filtered data after apply all filter with range
  }

  /**
   * @fires none
   * @listens myfilter
   * @param {Array[Object]} filtersObjects
   * @param {Array} units
   * @description This method with filter unit based on filter without range values
   */
  FilterWithoutRange = (filtersObjects, units) => {
    //set units to unitData
    let unitsData = units
    let swap = [] //for swap unit to change data
    //filter based on filters objects
    filtersObjects.map(filter => {
      let filterType = filter.type //set filter type on filterType variable
      let filterValues = filter.values //set filter values on filterValues variable

      //check if user apply filter with values or not
      if (filterValues.length > 0) {
        //filter based on values of filter values

        filterValues.map(filterValue => {
          //check if filter type id deliver_date

          if (filterType === 'delivery_date') {
            //if filter type is deliver_date
            //check if filter value is ready to move
            if (filterValue === 'ready to move') {
              // if filter value is ready to move
              //filter based on ready_delivery field in DB
              let newFilteredUnits = unitsData.filter(
                unit => unit.unit['ready_delivery'] === true
              )
              swap.push(...newFilteredUnits) //push new filterd on swap
            } else {
              //if filter value is not deliver_date
              //filter based on delivery_date year
              let newFilteredUnits = unitsData.filter(
                unit => +unit.unit['delivery_date']['year'] === +filterValue
              )
              swap.push(...newFilteredUnits) //push new units to swap
              swap = [...new Set(swap)] //create set() for remove duplicated units
            }
          } else {
            if (
              +filterValue === 6 &&
              (filterType === 'bedrooms' || filterType === 'bathrooms')
            ) {
              let newFilteredUnits = unitsData.filter(
                unit => unit.unit[filterType] >= filterValue
              )
              swap.push(...newFilteredUnits) // push new unit to swap
            } else {
              // if filter type not delivey_date
              //filter based on filterType
              let newFilteredUnits = unitsData.filter(
                unit => unit.unit[filterType] === filterValue
              )
              swap.push(...newFilteredUnits) // push new unit to swap
            }
          }
          return {}
        })
        unitsData = swap // swap to unitData for appling other filter
        swap = [] // reset swap array (set swap to empty array)
      }
      return {}
    })
    return [...new Set(unitsData)] // retern filtered units after applying all filters
  }

  /**
   * @fires FilterWithRange
   * @fires FilterWithoutRange
   * @listens render
   * @param {Array[object]} FiltersObjects
   * @param {Array[object]} FiltersObjectsRange
   * @param {Array} originUnits
   * @param {Array} units
   * @description This methos will apply  filters based on filterbojects if with range or not
   */
  myfilter = (
    FiltersObjects,
    FiltersObjectsRange,
    originUnits = [],
    units = []
  ) => {
    //check FiltersObjects and FiltersObjectsRange are empty with, return origin data
    if (FiltersObjects.length === 0 && FiltersObjectsRange.length === 0) {
      return originUnits
    } else if (FiltersObjects.length === 0 && FiltersObjectsRange.length > 0) {
      //if FiltersObjectsRange not empty and FiltersObjects is empty, apply FilterWithRange method
      return this.FilterWithRange(FiltersObjectsRange, units)
    } else if (FiltersObjects.length > 0 && FiltersObjectsRange.length === 0) {
      //if FiltersObjects is not empty and FiltersObjectsRange is empty , apply FilterWithoutRange mthod
      return this.FilterWithoutRange(FiltersObjects, units)
    } else {
      /**
       * if FiltersObjects and FiltersObjectsRange
       * are both not empty, apply FilterWithoutRange and
       * apply FilterWithRange based retruned data from FilterWithoutRange
       */
      let newFiltered = this.FilterWithoutRange(FiltersObjects, units)
      return this.FilterWithRange(FiltersObjectsRange, newFiltered)
    }
  }

  paymentAmountPerPeriod = (p, r, n) => {
    return (
      Math.ceil(
        (p * ((r * Math.pow(r + 1, n)) / (Math.pow(r + 1, n) - 1))) / 100
      ) * 100
    )
  }
  paymentPerMonth = (years, cash, downpayment) => {
    const n = 12 * years
    const r = 0.14 / 12
    const p = cash - downpayment
    return this.paymentAmountPerPeriod(p, r, n)
  }
  orderedSort = (sorted, type) => {
    if (type === 'asc') {
      return this.setState({
        units: sorted
      })
    } else if ('desc') {
      sorted.reverse()
      return this.setState({
        units: sorted
      })
    }
  }
  sortBySpace = (units, type) => {
    const sorted = lodash.sortBy(units, ['unit.land'])
    this.orderedSort(sorted, type)
  }
  addDownpayment = units => {
    const newUnits = units.map(unit => {
      return {
        ...unit,
        unit: {
          ...unit.unit,
          downpayment: unit.unit.cash_price * 0.2
        }
      }
    })
    return newUnits
  }
  sortByDwonPaymen = (units, type) => {
    const newUnits = this.addDownpayment(units)
    const sorted = lodash.sortBy(newUnits, ['unit.downpayment'])
    this.orderedSort(sorted, type)
  }

  addmonthlyPayment = units => {
    const newUnits = units.map(unit => {
      return {
        ...unit,
        unit: {
          ...unit.unit,
          monthlyPayment: this.paymentPerMonth(
            15,
            unit.unit.cash_price,
            unit.unit.cash_price * 0.2
          )
        }
      }
    })
    return newUnits
  }
  sortByMonthlyBayment = (units, type) => {
    const newUnits = this.addmonthlyPayment(units)
    const sorted = lodash.sortBy(newUnits, ['unit.monthlyPayment'])
    this.orderedSort(sorted, type)
  }

  render () {
    const mql = window.matchMedia('(max-width: 992px)')

    const allUnits = this.state.units

    let units = this.myfilter(
      this.props.filters,
      this.props.rangeFilters,
      allUnits,
      allUnits
    )
    const VIEW = mql.matches ? (
      !this.state.viewMapAndCards ? (
        <Fragment>
          <div className={`col-12 col-lg-7 col-xl-6 ${classes.mapContainer}`}>
            <MapsContainer units={units} />
          </div>
          <div
            className={`col-12 col-lg-5  p-0 p-md-auto col-xl-6 d-none d-lg-block ${classes.listsCard}`}
          >
            <Listings units={units} unitSize='3' />
          </div>
        </Fragment>
      ) : (
        <div className={`col-12 ${classes.listsCard}`}>
          <Listings
            viewType={this.state.viewMapAndCards}
            units={units}
            unitSize='3'
          />
        </div>
      )
    ) : this.state.viewMapAndCards ? (
      <Fragment>
        <div className={`col-12 col-lg-7 col-xl-6 ${classes.mapContainer}`}>
          <MapsContainer units={units} />
        </div>
        <div
          className={`col-12 col-lg-5  p-0 p-md-auto col-xl-6 d-none d-lg-block ${classes.listsCard}`}
        >
          <Listings units={units} unitSize='3' />
        </div>
      </Fragment>
    ) : (
      <div className={`col-12 ${classes.listsCard}`}>
        <Listings
          viewType={this.state.viewMapAndCards}
          units={units}
          unitSize='3'
        />
      </div>
    )
    if (!this.state.loading) {
      return <LoadingContainer />
    }
    return (
      <div className='container-fluid'>
        <FilterBar
          view={this.view}
          viewTipe={this.state.viewMapAndCards}
          addNewFilter={this.addNewFilter}
          removeFilter={this.removeFilter}
          removeMoreFilters={this.removeMoreFilters}
          addNewRangeFilter={this.addNewRangeFilter}
          removeRangeFilter={this.removeRangeFilter}
          clearFilters={this.clearFilters}
          clearMoreFilters={this.clearMoreFilters}
          sortBySpace={this.sortBySpace}
          sortByDwonPaymen={this.sortByDwonPaymen}
          sortByMonthlyBayment={this.sortByMonthlyBayment}
        />
        <div className='row'>{VIEW}</div>
      </div>
    )
  }
}
//get units from redux stor
const mapStateToProps = state => ({
  units: state.unitState.units,
  authUser: state.sessionState.authUser,
  filters: state.filterState.filters,
  rangeFilters: state.filterState.rangeFilters
})
const mapDispatchToProps = dispatch => ({
  onSetUnits: units => dispatch({ type: UNITS_SET, units }),
  onModifyFilters: filters => dispatch({type: 'MODIFY_FILTERS', payload: filters}),
  onModifyRangeFilters: rangeFilters => dispatch({type: 'MODIFY_RANGE_FILTERS', payload: rangeFilters}),
  onClearFilters: () => dispatch({type: 'CLEAR_FILTERS'}),
  onClearMoreFilters: () => dispatch({type: 'CLEAR_MORE_FILTERS'})
})

const UnitView = compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(BuyBase)
export default Buy
export { UnitView }
