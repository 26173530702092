import React, { Component, Fragment } from 'react'
// import { compose } from 'recompose';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirebase } from '../../components/Firebase'
import { withRouter } from 'react-router-dom'
import LandingPageHeader from '../../components/LandingPageParts/LandingHeader'
import Testimonials from '../../components/LandingPageParts/Testimonials'
import Footer from '../../components/Footer'
import Services from '../../components/LandingPageParts/Services'
import Developers from '../../components/LandingPageParts/Developers'
import FeatureListing from '../../components/LandingPageParts/FeatureListing'
import classes from './index.module.scss'
const LandingPage = () => (
  <Fragment>
    <UnitView />
  </Fragment>
)
class LandingBase extends Component {
  constructor (props) {
    super(props)
    this.state = {
      units: []
    }
  }
  componentWillMount () {
    this.props.onInitLandingPage()
  }
  async componentDidMount () {
    if (this.props.location.pathname === '/') {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }
    this.props.removefixedNav()
    window.addEventListener('scroll', this.handleScroll)

    let units = await this.props.firebase.getUnits()
    const allUnits = units.docs.map(doc => {
      return {
        id: doc.id,
        unit: doc.data().unit,
        seller: doc.data().seller,
        financing: doc.data().financing
      }
    })

    this.setState({
      units: allUnits
    })
  }

  componentWillUnmount () {
    this.props.onLeaveLandingPage()
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    var afterHeader = document.getElementById('afterHeader')
    var sticky = afterHeader.offsetTop

    if (window.pageYOffset >= sticky) {
      this.props.onfixedNav()
    } else {
      this.props.removefixedNav()
    }
  }

  render () {
    return (
      <Fragment>
        <LandingPageHeader units={this.state.units} />
        <div
          id='afterHeader'
          className={`${classes.afterHeader} ${
            this.props.isNavFixed ? `${classes.topPadding}` : ''
          }`}
        >
          <Services />
          <Developers />
          {this.state.units.length > 0 && (
            <FeatureListing allUnits={this.state.units} />
          )}
          <Testimonials />
          <Footer />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  isNavFixed: state.landingNavState.isNavFixed
})
const mapDispatchToProps = dispatch => ({
  onInitLandingPage: () => dispatch({ type: 'IS_LANDING_PAGE', payload: true }),
  onLeaveLandingPage: () =>
    dispatch({ type: 'IS_LANDING_PAGE', payload: false }),
  onfixedNav: () => dispatch({ type: 'FIXED_NAV', payload: true }),
  removefixedNav: () => dispatch({ type: 'FIXED_NAV', payload: false })
})

const UnitView = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFirebase
)(LandingBase)

export default LandingPage
export { UnitView }
