// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// // import logger from 'redux-logger';
// import rootReducer from '../Reducers';

// const middlewares = [thunk];

// const store = createStore(
//     rootReducer, {},
//     compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//         applyMiddleware(...middlewares)));
// export default store;
import configureStore from './configureStore'
const store = configureStore()

export default store