import React, { Component, Fragment } from 'react'
import styles from './index.module.scss'
import '../fix.scss'
import Swal from 'sweetalert2'
import { withFirebase } from '../../Firebase'
import { connect } from 'react-redux'

class ExtendsPayment extends Component {
  state = {
    location: '',
    address: '',

    compundFlage: false,
    compundName: '',

    totalExpectedPayment: '',
    totalPaymentPaid: '',

    dateOfDwonPayment: '',
    expectedFinalDatePayment: '',

    month: '',
    year: '',

    eMonth: '',
    eYear: '',

    fullName: '',
    email: '',
    phone: ''
  }
  componentDidMount () {
    this.months()
    this.years()
    this.eMonths()
    this.eYears()

    if (this.props.location.pathname.includes('extends-payment')) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  isCompund = e => {
    this.setState({
      compundFlage: e.target.checked
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    e.target.reset()
    if (this.props.authUser) {
      this.props.firebase
        .getUserData(this.props.authUser.uid)
        .then(userData => {
          this.props.firebase.requestSevice({
            type: 'extendsPayment',
            location: this.state.location,
            address: this.state.address,
            isCompound: this.state.compundFlage,
            compundName: this.state.compundName,
            totalExpectedPayment: this.state.totalExpectedPayment,
            totalPaymentPaid: this.state.totalPaymentPaid,
            dateOfDwonPayment: this.state.dateOfDwonPayment,
            expectedFinalDatePayment: this.state.expectedFinalDatePayment,

            user: userData.data()
          })
          Swal.fire({
            title: 'Congratulation!',
            text:
              'Your request was sent, we will talk with you after less than 24 hours',
            icon: 'success',
            confirmButtonText: 'Continue'
          })
        })
    } else {
      this.props.firebase.requestSevice({
        type: 'extendsPayment',
        location: this.state.location,
        address: this.state.address,
        isCompound: this.state.compundFlage,
        compundName: this.state.compundName,
        totalExpectedPayment: this.state.totalExpectedPayment,
        totalPaymentPaid: this.state.totalPaymentPaid,
        dateOfDwonPayment: this.state.dateOfDwonPayment,
        expectedFinalDatePayment: this.state.expectedFinalDatePayment
      })
      Swal.fire({
        title: 'Congratulation!',
        text:
          'Your request was sent, we will talk with you after less than 24 hours',
        icon: 'success',
        confirmButtonText: 'Continue'
      })
    }
  }

  years = operation => {
    const date = new Date()
    switch (operation) {
      case 'increase':
        this.setState({
          year: this.state.year + 1
        })
        break

      case 'decrease':
        this.setState({
          year: this.state.year - 1
        })
        break
      default:
        this.setState({
          year: date.getFullYear()
        })
        break
    }
  }
  months = operation => {
    const date = new Date()
    switch (operation) {
      case 'increase':
        this.state.month < 11 &&
          this.setState({
            month: this.state.month + 1
          })
        break

      case 'decrease':
        this.state.month > 0 &&
          this.setState({
            month: this.state.month - 1
          })
        break
      default:
        this.setState({
          month: date.getMonth()
        })
        break
    }
  }

  eYears = operation => {
    const date = new Date()
    switch (operation) {
      case 'increase':
        this.setState({
          eYear: this.state.eYear + 1
        })
        break

      case 'decrease':
        this.setState({
          eYear: this.state.eYear - 1
        })
        break
      default:
        this.setState({
          eYear: date.getFullYear()
        })
        break
    }
  }
  eMonths = operation => {
    const date = new Date()
    switch (operation) {
      case 'increase':
        this.state.eMonth < 11 &&
          this.setState({
            eMonth: this.state.eMonth + 1
          })
        break

      case 'decrease':
        this.state.eMonth > 0 &&
          this.setState({
            eMonth: this.state.eMonth - 1
          })
        break
      default:
        this.setState({
          eMonth: date.getMonth()
        })
        break
    }
  }
  render () {
    const MONTH = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ]

    const {
      location,
      address,

      compundFlage,
      month,
      year,
      eMonth,
      eYear
    } = this.state

    const monthValue = MONTH[month]
    const eMonthValue = MONTH[eMonth]
    const isValid = location === '' || address.trim() === ''
    const { authUser } = this.props
    return (
      <div className='container-fluid makeItSticky'>
        <div className='row p-lg-5'>
          <div className='col-12 col-md-7'>
            <form onSubmit={this.handleSubmit} className='form'>
              <div className='container-fluid p-0 m-0 pl-lg-5 pr-lg-5'>
                <div className='row p-0 m-0'>
                  <div className='col-12 p-0 m-0'>
                    <h1 className={`text-left text-uppercase ${styles.header}`}>
                      Extends Payments
                    </h1>
                    <p className={`text-left pt-2 ${styles.paragraph}`}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                      <br /> Quisque velit enim, iaculis ut lectus quis,
                      faucibus.
                    </p>
                  </div>
                </div>
                <div className='row pt-5'>
                  {!authUser && (
                    <Fragment>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='fullName'
                            type='text'
                            className='form-control'
                            placeholder='Full Name'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='email'
                            type='email'
                            className='form-control'
                            placeholder='Enter you email'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='phone'
                            className='form-control'
                            type='tel'
                            placeholder='Enter your phone number'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  <div className='col-12'>
                    <div className={`form-group ${styles.location}`}>
                      <select
                        onChange={this.handleChange}
                        id='location'
                        className='form-control'
                      >
                        <option value='' disabled={true}>
                          Choose Locaion
                        </option>
                        <option value='neighborhood'>Neighborhood</option>
                        <option value='giza'>Giza</option>
                        <option value='cairo'>Cairo</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className={`form-group ${styles.location}`}>
                      <input
                        id='address'
                        className='form-control'
                        placeholder='Street, Area'
                        disabled={!location}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className={`form-check ${styles.input} pl-0`}>
                      <label
                        className={`form-check-label ${styles.label}`}
                        htmlFor='compundFlage'
                      >
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='compundFlage'
                          onChange={this.isCompund}
                        />
                        <span>Is Compound</span>
                      </label>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className={`form-group ${styles.compund}`}>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Compund Name'
                        id='compundName'
                        onChange={this.handleChange}
                        disabled={!compundFlage}
                      />
                    </div>
                  </div>
                  <div className='col-12 mt-5'>
                    <div className={`form-group ${styles.location}`}>
                      <input
                        type='text'
                        id='totalExpectedPayment'
                        className='form-control'
                        onChange={this.handleChange}
                        placeholder='Total of Payments expected on house'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className={`form-group ${styles.location}`}>
                      <input
                        type='text'
                        id='totalPaymentPaid'
                        onChange={this.handleChange}
                        className='form-control'
                        placeholder='Total of payments already paid'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className={`form-group clearfix ${styles.date}`}>
                      <div className='row py-3 px-3 align-items-center'>
                        <div className='col-sm-12 col-md-6'>
                          <span>Date Of Downpayment</span>
                        </div>
                        <div
                          className={`col-sm-12 col-md-6 px-0 text-center ${styles.fixPickerMob}`}
                        >
                          <span onClick={() => this.months('decrease')}>
                            <img
                              src={require('../../../assets/chevronLeft.svg')}
                              alt='date picker button'
                            />
                          </span>
                          <span className={`${styles.fixPicker}`}>
                            {' '}
                            {monthValue}{' '}
                          </span>
                          <span onClick={() => this.months('increase')}>
                            <img
                              src={require('../../../assets/chevronRight.svg')}
                              alt='date picker button'
                            />
                          </span>

                          <span onClick={() => this.years('decrease')}>
                            <img
                              src={require('../../../assets/chevronLeft.svg')}
                              alt='date picker button'
                            />
                          </span>
                          <span className={`${styles.fixPicker}`}>
                            {' '}
                            {year}{' '}
                          </span>
                          <span onClick={() => this.years('increase')}>
                            <img
                              src={require('../../../assets/chevronRight.svg')}
                              alt='date picker button'
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className={`form-group ${styles.date}`}>
                      <div className='row py-3 px-3 align-items-center'>
                        <div className='col-sm-12 col-md-6 pr-0'>
                          <span>Expected final payment date</span>
                        </div>
                        <div
                          className={`col-sm-12 col-md-6 px-0 text-center ${styles.fixPickerMob}`}
                        >
                          <span onClick={() => this.eMonths('decrease')}>
                            <img
                              src={require('../../../assets/chevronLeft.svg')}
                              alt='date picker button'
                            />
                          </span>
                          <span className={`${styles.fixPicker}`}>
                            {' '}
                            {eMonthValue}{' '}
                          </span>
                          <span onClick={() => this.eMonths('increase')}>
                            <img
                              src={require('../../../assets/chevronRight.svg')}
                              alt='date picker button'
                            />
                          </span>

                          <span onClick={() => this.eYears('decrease')}>
                            <img
                              src={require('../../../assets/chevronLeft.svg')}
                              alt='date picker button'
                            />
                          </span>
                          <span className={`${styles.fixPicker}`}>
                            {' '}
                            {eYear}{' '}
                          </span>
                          <span onClick={() => this.eYears('increase')}>
                            <img
                              src={require('../../../assets/chevronRight.svg')}
                              alt='date picker button'
                            />
                          </span>
                        </div>
                      </div>
                      <span className='float-right'></span>
                    </div>
                  </div>
                  <div className='col-12'>
                    <button
                      disabled={isValid}
                      className='btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold'
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-12 col-md-5 mb-5 order-first order-md-last text-center mainOptionsFixMob'>
            <img
              className='sticky-top'
              src={require('../../../assets/landingPage/extend.png')}
              alt='avatar'
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})
export default connect(mapStateToProps)(withFirebase(ExtendsPayment))
