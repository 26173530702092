const modifyFilters = (state, action) => {
    console.log(action.payload)
    return ({
    ...state,
    // // filters : [...state.filters, ...action.payload]
    // filters: [{
    //     type : 'xxx',
    //     values : [1,4]
    // }],
    filters : [...action.payload],
    // filters: []
    // ...action.payload
})}
;

const modifyRangeFilters = (state, action) => ({
    ...state,
    // rangeFilters : [...state.rangeFilters, ...action.payload]
    rangeFilters : [...action.payload]
});


const clearFilters = () => ({
    filters: [],
    rangeFilters: []
});

const clearMoreFilters = (state) => ({
    ...state,
    filters: state.filters.filter(x => x !== 'delivery_date')
});

export { modifyFilters, modifyRangeFilters, clearFilters, clearMoreFilters }