import React from 'react'
import classes from './index.module.scss'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
const SignOutButton = props => (
  <li
    className={`nav-item ${classes.signOut} ${props.styles[0]} ${props.styles[1]}`}
    onClick={() => {
      props.firebase.doSignOut()
    }}
  >
    <span className='nav-link text-danger'>Sign Out</span>
  </li>
)

export default compose(withFirebase, withRouter)(SignOutButton)
