import React, { Component } from 'react'
import styles from './index.module.scss'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import RequestHouse from '../../RequestHouse'

class FinancingPlan extends Component {
  state = {
    quarterlyOrMonthly: true, //change plan view monthly or quartely

    years: 10,
    percentageDownpayment: 0.2
  }

  calculateDownpayment = (cash, percentage) => {
    return Math.abs(cash * percentage)
  }

  paymentAmountPerPeriod = (p, r, n) => {
    return Math.abs(p * ((r * Math.pow(r + 1, n)) / (Math.pow(r + 1, n) - 1)))
  }

  paymentPerMonth = (years, cash, downpayment) => {
    const n = 12 * years
    const r = 0.14 / 12
    const p = cash - downpayment

    return this.paymentAmountPerPeriod(p, r, n)
  }
  paymentPerQuarter = (years, cash, downpayment) => {
    const n = 4 * years

    const r = 0.14 / 4
    const p = cash - downpayment

    return this.paymentAmountPerPeriod(p, r, n)
  }
  /**
   * @param none
   * @fires none
   * @listens onClick button monthly
   * @returns none
   * @description this method will show monthly view pay plan
   */
  changePaymentToMonthly = () => {
    this.setState({
      quarterlyOrMonthly: true
    })
  }
  /**
   * @param none
   * @fires none
   * @listens onClick button Quarterly
   * @returns none
   * @description this method will show Quarterly view pay plan
   */
  changePaymentToQuarterly = () => {
    this.setState({
      quarterlyOrMonthly: false
    })
  }
  downpaymentOperation = type => {
    switch (type) {
      case 'increase':
        if (this.state.percentageDownpayment < 1) {
          this.setState({
            percentageDownpayment: this.state.percentageDownpayment + 0.05
          })
        }
        break
      case 'decrease':
        if (this.state.percentageDownpayment > 0.2) {
          this.setState({
            percentageDownpayment: this.state.percentageDownpayment - 0.05
          })
        }

        break
      default:
        break
    }
  }
  durationOperation = type => {
    switch (type) {
      case 'increase':
        if (this.state.years < 15) {
          this.setState({
            years: this.state.years + 1
          })
        }
        break
      case 'decrease':
        if (this.state.years > 5) {
          this.setState({
            years: this.state.years - 1
          })
        }
        break
      default:
        break
    }
  }
  render () {
    const { quarterlyOrMonthly, years, percentageDownpayment } = this.state

    //houseId for request house to buy it
    // console.log(this.props)
    const developer_fin =
      this.props.houseDetails.financing &&
      this.props.houseDetails.financing.developer_fin
    let developer_dp = ''

    if (developer_fin) {
      developer_dp = this.props.houseDetails.financing.developer_dp
    }
    // console.log(developer_fin)

    const houseId = this.props.match.params.house_id

    const cash =
      this.props.houseDetails.financing &&
      +this.props.houseDetails.financing.cash_price

    let downpayment = null
    let paymentPerPeriod = null
    let annualPayment = null
    let totalPayment = null

    if (cash) {
      downpayment = this.calculateDownpayment(+cash, percentageDownpayment)
      // console.log(downpayment);

      paymentPerPeriod = quarterlyOrMonthly
        ? developer_fin
          ? this.paymentPerMonth(years, +cash, developer_dp)
          : this.paymentPerMonth(years, +cash, downpayment)
        : developer_fin
        ? this.paymentPerQuarter(years, +cash, developer_dp)
        : this.paymentPerQuarter(years, +cash, downpayment)

      // console.log("Milad:",paymentPerPeriod);

      annualPayment = quarterlyOrMonthly
        ? paymentPerPeriod * 12
        : paymentPerPeriod * 4
      // console.log(+cash);

      totalPayment = +downpayment + years * annualPayment
    }
    return (
      <div className='container-fluid p-0'>
        <div className={`${styles.FinancingPlan}  px-5 px-sm py-5`}>
          <div className='row align-items-baseline mb-3'>
            <div className='col-12 p-0'>
              <p className='font-weight-light p-0 m-0 timeHeader'>
                Down payment
              </p>
            </div>
            <div className='col-sm-8 col-md-12 col-lg-8 p-0'>
              <p className='h4'>
                <strong>
                  {developer_fin
                    ? Number(developer_dp).toLocaleString('en', {
                        useGrouping: true
                      })
                    : Number(downpayment).toLocaleString('en', {
                        useGrouping: true
                      })}
                </strong>{' '}
                EGP
              </p>
            </div>
            {developer_fin === false ? (
              <div className='col-sm-4 col-md-12 col-lg-4 p-0'>
                <p className={`p-0 m-0 text-center ${styles.fixIDBtn}`}>
                  <span
                    className={`${styles.lessThan}`}
                    onClick={() => this.downpaymentOperation('decrease')}
                  >
                    <img
                      src={require('../../../assets/lessThan.png')}
                      alt='...'
                    />
                  </span>
                  <span
                    className={`${styles.greaterThan}`}
                    onClick={() => this.downpaymentOperation('increase')}
                  >
                    <img
                      src={require('../../../assets/greaterThan.png')}
                      alt='...'
                    />
                  </span>
                </p>
              </div>
            ) : null}
          </div>
          <div className='row align-items-baseline mb-3'>
            <div className='col-12 p-0'>
              <p className='font-weight-light p-0 m-0 timeHeader'>Duration</p>
            </div>
            <div className='col-sm-8 col-md-12 col-lg-8 p-0'>
              <p className='h4'>
                <strong>{years}</strong> YEARS
              </p>
            </div>
            {developer_fin === false ? (
              <div className='col-sm-4 col-md-12 col-lg-4 p-0'>
                <p className={`p-0 m-0 text-center ${styles.fixIDBtn}`}>
                  <span
                    className={`${styles.lessThan}`}
                    onClick={() => this.durationOperation('decrease')}
                  >
                    <img
                      src={require('../../../assets/lessThan.png')}
                      alt='...'
                    />
                  </span>
                  <span
                    className={`${styles.greaterThan}`}
                    onClick={() => this.durationOperation('increase')}
                  >
                    <img
                      src={require('../../../assets/greaterThan.png')}
                      alt='...'
                    />
                  </span>
                </p>
              </div>
            ) : null}
          </div>
          <div className='row mt-5 text-center '>
            <div
              className={`col-6 pt-3 pb-3  text-white ${styles.planButton}
                        ${
                          this.state.quarterlyOrMonthly
                            ? styles.activeBtn
                            : null
                        }`}
              onClick={this.changePaymentToMonthly}
            >
              Monthly
            </div>
            <div
              className={`col-6 pt-3 pb-3  text-white ${styles.planButton}
                        ${
                          this.state.quarterlyOrMonthly
                            ? null
                            : styles.activeBtn
                        }`}
              onClick={this.changePaymentToQuarterly}
            >
              Quarterly
            </div>
          </div>
        </div>
        <div className='p-3 bg-white'>
          <div className='row py-4 px-0 text-center'>
            <div className='col-6 m-0 pr-0'>
              <p className='text-dark font-weight-light p-0 m-0'>
                {quarterlyOrMonthly ? 'Monthly ' : 'Quarterly'} Payment
              </p>
              <p className={`h4 ${styles.paymentPerPeriod}`}>
                {Number(paymentPerPeriod).toLocaleString('en', {
                  useGrouping: true,
                  maximumFractionDigits: 0
                })}
                <span> EGP</span>
              </p>
            </div>
            <div className='col-6'>
              <p className='text-dark font-weight-light p-0 m-0'>
                Annual Payment
              </p>
              <p className={`h4 ${styles.paymentPerPeriod}`}>
                {Number(annualPayment).toLocaleString('en', {
                  useGrouping: true,
                  maximumFractionDigits: 0
                })}
                <span> EGP</span>
              </p>
            </div>
          </div>
        </div>
        <div className='p-3 mt-1 bg-white'>
          <div className='row pt-4 pb-4 tex-center'>
            <div className='col-12'>
              <p className='text-center text-dark p-0 m-0 font-weight-bold'>
                Total Payment
              </p>
              <p className={`text-center h4 p-0 m-0 ${styles.totalColor}`}>
                <strong className={`${styles.totalColor}`}>
                  {Number(totalPayment).toLocaleString('en', {
                    useGrouping: true,
                    maximumFractionDigits: 0
                  })}
                </strong>{' '}
                EGP
              </p>
            </div>
            <div className='col-12'>
              <RequestHouse houseId={houseId} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export paymentPerMonth;
export default compose(withRouter)(FinancingPlan)

// export paymentPerMonth;
