import React, { Component, Fragment } from 'react'
import styles from './index.module.scss'
import '../fix.scss'
import { withFirebase } from '../../Firebase'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

class FinishAndFurnish extends Component {
  state = {
    location: 'neighborhood',
    address: '',

    compundFlage: false,
    compundName: '',

    contractorName: '',
    hasContractor: 'no',

    fullName: '',
    email: '',
    phone: ''
  }
  componentDidMount () {
    if (this.props.location.pathname.includes('finish-and-furnish')) {
      document.getElementsByTagName('html')[0].style.overflowY = 'auto'
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  isCompund = e => {
    this.setState({
      compundFlage: e.target.checked
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    e.target.reset()
    if (this.props.authUser) {
      this.props.firebase
        .getUserData(this.props.authUser.uid)
        .then(userData => {
          this.props.firebase.requestSevice({
            type: 'finishAndFurnish',
            location: this.state.location,
            address: this.state.address,
            isCompound: this.state.compundFlage,
            haveContractor: this.state.hasContractor,
            compundName: this.state.compundName,
            contractorName: this.state.contractorName,
            user: userData.data()
          })
          Swal.fire({
            title: 'Congratulation!',
            text:
              'Your request was sent, we will talk with you after less than 24 hours',
            icon: 'success',
            confirmButtonText: 'Continue'
          })
        })
    } else {
      this.props.firebase.requestSevice({
        type: 'finishAndFurnish',
        location: this.state.location,
        address: this.state.address,
        isCompound: this.state.compundFlage,
        haveContractor: this.state.hasContractor,
        compundName: this.state.compundName,
        contractorName: this.state.contractorName,
        user: {
          fullName: this.state.fullName,
          email: this.state.email,
          phone: this.state.phone
        }
      })
      Swal.fire({
        title: 'Congratulation!',
        text:
          'Your request was sent, we will talk with you after less than 24 hours',
        icon: 'success',
        confirmButtonText: 'Continue'
      })
    }
  }
  haveContractor = trueth => {
    this.setState({
      hasContractor: trueth
    })
  }

  render () {
    const { location, address, compundFlage, hasContractor } = this.state
    const isValid = location === '' || address.trim() === ''
    const isChecked = hasContractor === 'yes' ? true : false
    const { authUser } = this.props

    return (
      <div className='container-fluid makeItSticky'>
        <div className='row p-0 p-lg-5'>
          <div className='col-12 col-md-6'>
            <form onSubmit={this.handleSubmit} className='form'>
              <div className='container-fluid p-0 m-0 pl-lg-5 pr-lg-5'>
                <div className='row p-0 m-0'>
                  <div className='col-12 p-0 m-0'>
                    <h1 className={`text-left text-uppercase ${styles.header}`}>
                      Finish and furnish
                    </h1>
                    <p className={`text-left pt-2 ${styles.paragraph}`}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                      <br /> Quisque velit enim, iaculis ut lectus quis,
                      faucibus.
                    </p>
                  </div>
                </div>
                <div className='row pt-5'>
                  {!authUser && (
                    <Fragment>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='fullName'
                            type='text'
                            className='form-control'
                            placeholder='Full Name'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='email'
                            type='email'
                            className='form-control'
                            placeholder='Enter you email'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className={`form-group ${styles.location}`}>
                          <input
                            id='phone'
                            className='form-control'
                            type='tel'
                            placeholder='Enter your phone number'
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  <div className='col-12'>
                    <div className={`form-group ${styles.location}`}>
                      <select
                        onChange={this.handleChange}
                        id='location'
                        className='form-control'
                      >
                        <option value='neighborhood'>Neighborhood</option>
                        <option value='giza'>Giza</option>
                        <option value='cairo'>Cairo</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className={`form-group ${styles.location}`}>
                      <input
                        id='address'
                        className='form-control'
                        placeholder='Street, Area'
                        disabled={!location}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className={`form-check ${styles.input} pl-0`}>
                      <label
                        className={`form-check-label ${styles.label}`}
                        htmlFor='compundFlage'
                      >
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='compundFlage'
                          onChange={this.isCompund}
                        />
                        <span>Is Compound</span>
                      </label>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className={`form-group ${styles.compund}`}>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Compund Name'
                        id='compundName'
                        onChange={this.handleChange}
                        disabled={!compundFlage}
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='container-fluid p-0 m-0'>
                      <div className='row p-0 m-0'>
                        <div className='col-12 p-0'>
                          <h4 className='m-0 py-5'>
                            Have you already chose a contractor to finish the
                            house?
                          </h4>
                        </div>
                        <div className='col-2 p-0 m-0'>
                          <label
                            className={`form-check-label ${styles.label} p-0`}
                            htmlFor='no'
                          >
                            <input
                              type='radio'
                              name='contractor'
                              className='form-check-input'
                              id='no'
                              checked={!isChecked}
                              onChange={() => this.haveContractor('no')}
                            />

                            <span>No</span>
                          </label>
                          <br />

                          <label
                            className={`form-check-label ${styles.label} p-0`}
                            htmlFor='yes'
                          >
                            <input
                              type='radio'
                              name='contractor'
                              className='form-check-input'
                              id='yes'
                              checked={isChecked}
                              onChange={() => this.haveContractor('yes')}
                            />
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className='col-10'>
                          <div className={`form-group ${styles.location}`}>
                            <input
                              id='contractorName'
                              className='form-control'
                              placeholder='contractor name'
                              disabled={hasContractor === 'yes' ? false : true}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <button
                      disabled={isValid}
                      className='btn btn-primary rounded-sm text-uppercase btn-block my-4 py-3 border-0 font-weight-bold'
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='col-12 col-md-6 mb-5 order-first order-md-last text-center mainOptionsFixMob'>
            <img
              className='sticky-top'
              src={require('../../../assets/landingPage/extend.png')}
              alt='avatar'
            />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
})
export default connect(mapStateToProps)(withFirebase(FinishAndFurnish))
