import lodash from 'lodash' //lodash library
/**
 * @fires getCities
 * @listens onChange
 * @param {Object} event event that fire when change input value
 * @param {Object} obj it is th this object of calling it
 * @description This method for filter places and cities, when user write on input area location
 */

//  event default value is null, because i'm calling it from
//  clear filters, and no event there.
export const searchFilter = (event, obj) => {
  if (event) {
    let textFilter = event.target.value.toLowerCase() //set input value to textFilter variable
    let units = obj.props.units // set units from object props to unit variable
    
    
    //filter units of cities  and places based on textFilter
    let filteredUnits =
    units &&
    units.filter(
      unit =>
      unit.unit.city.toLowerCase().includes(textFilter) ||
      unit.unit.place.toLowerCase().includes(textFilter)
      )
      //call getCities method and pass filteredUnits as param to it
      obj.getCities(filteredUnits)
  } else {
    obj.getCities(obj.props.units)
  }
}

/**
 * @param {Array} unitsData
 * @param {Object} obj
 * @fires getPlaces
 * @listens searchFilter
 * @description This method categorized cities
 */
export const getCities = (unitsData = [], obj) => {
  let cities = [] //initial array for cities
  //loop all unitsData
  for (let index = 0; index < unitsData.length; index++) {
    //check unitsData[index].unit.city is already exist in cities array
    if (!lodash.includes(cities, unitsData[index].unit.city.toLowerCase())) {
      //if city not exist in array push it on cities array
      cities.push(unitsData[index].unit.city.toLowerCase())
      //set cities to new cities after  categorized
      // obj.setState({
      //   cities: cities
      // })
      // obj.props.onAddSearch({
      //   cities: cities
      // })
      obj.setState({
        cities: cities
      })
      //calling getPlaces method to categorized places
      obj.getPlaces(unitsData, cities)
    }
  }
}
/**
 * @param {Array} unitsData
 * @param {Array} citiesData
 * @param {Object} obj
 * @fires none
 * @listens getCities
 * @description tThis method categorized places based on them cities
 */
export const getPlaces = (unitsData = [], citiesData = [], obj) => {
  let address = [] //initial array for address for places and cities
  let places = [] // initial array for places
  let units = unitsData //set unitData to units
  let cities = citiesData // set citiesData array to cities variable

  //set cities as keys on address array
  for (let index = 0; index < cities.length; index++) {
    address[cities[index].toLowerCase()] = []
  }

  //nested loop for cities and units
  //loop for all cities
  for (let index = 0; index < cities.length; index++) {
    //loop for all units
    for (let j = 0; j < units.length; j++) {
      //check if cities of index equal units[j].city
      if (cities[index].toLowerCase() === units[j].unit.city.toLowerCase()) {
        //if equal check if address[cities[index]] not contain this place
        if (
          !address[cities[index].toLowerCase()].includes(
            units[j].unit.place.toLowerCase()
          )
        ) {
          // if not exist, push it to address based on its city
          address[cities[index].toLowerCase()].push(
            units[j].unit.place.toLowerCase()
          )
        }
      }
    }
  }

  //change places state
  for (let index = 0; index < cities.length; index++) {
    places.push(address[cities[index]])
    // obj.setState({
    //   places: places
    // })
    // obj.props.onAddSearch({
    //   places: places
    // })
    obj.setState({
      places: places
    })
  }
}

/**
 * @param {*} event
 * @param {*} obj
 * @fires toggleSearchHandler
 * @fires addNewRangeFilter
 * @listens Apply button
 * @description
 */
export const filterByPrice = (event, obj) => {
  event.preventDefault()
  // let oldState = { ...obj.state['priceState'] }
  let oldState = { ...obj.props.searchParams['priceState'] }
  // obj.setState({
  //   priceState: {
  //     ...oldState,
  //     triggered: true
  //   }
  // })
  obj.props.onAddSearch({
    priceState: {
      ...oldState,
      triggered: true
    },
    rangeInputDownpaymentValue: {
      min: obj.props.searchParams.rangeInputDownpaymentValue.min,
      max: obj.props.searchParams.rangeInputDownpaymentValue.max
    },
    rangeInputMonthlyPaymentValue: {
      min: obj.props.searchParams.rangeInputMonthlyPaymentValue.min,
      max: obj.props.searchParams.rangeInputMonthlyPaymentValue.max
    }
  })
  obj.toggleSearchHandler('price') //closing filter price view
  // calling addNewRangeFilter mthod to new filter
  // if (obj.props.searchParams.paymentTypes.length > 0) {
    // if (obj.props.searchParams.paymentTypes.includes('monthlyPaymentSearch')) {
    if (obj.props.searchParams.monthlyPaymentSearch) {
      obj.props.addNewRangeFilter(
        'monthlyPayment',
        obj.props.searchParams.rangeInputMonthlyPaymentValue.min,
        obj.props.searchParams.rangeInputMonthlyPaymentValue.max
      )
    }
    // if (obj.props.searchParams.paymentTypes.includes('downpaymentSearch')) {
    if (obj.props.searchParams.downpaymentSearch) {
      obj.props.addNewRangeFilter(
        'downpayment',
        obj.props.searchParams.rangeInputDownpaymentValue.min,
        obj.props.searchParams.rangeInputDownpaymentValue.max
      )
    }
  // }
}

/**
 * @param {*} obj
 * @fires showCardsOnly() from props
 * @listens onClick on Card view button filter Bar
 * @returns none
 * @description this method fires to view cards only
 */
export const showCardsOnly = obj => {
  obj.props.view.showCardsOnly()
}

/**
 * @param no paramters
 * @fires showCardsAndMaps() from props
 * @listens onClick on Card and Maps button in filter Bar
 * @returns none
 * @description this method fires to view cards and maps
 */
export const showCardsAndMaps = obj => {
  obj.props.view.showCardsAndMaps()
}

//this method contain filter by bedrooms and bathrooms number

/**
 * @param {object} event
 * @param {string} type
 * @param {object} obj
 * @param {object} obj2
 * @fires removeFilter() from props
 * @listens onClick on (x) button inside search buttons
 * @returns none
 * @description this method fires to reset form value
 */
export const handleSubmit = (event, obj) => {
  if (event) {
    event.preventDefault()
    // let oldState = { ...obj.state[[event][0].target.dataset.statetype] }
    let oldState = { ...obj.props.searchParams[[event][0].target.dataset.statetype]} 
    // obj.setState({
    //   [event.target.dataset.statetype]: {
    //     ...oldState,
    //     triggered: oldState.value.length > 0
    //   }
    // })
    obj.props.onAddSearch({
      [event.target.dataset.statetype]: {
        ...oldState,
        triggered: oldState.value.length > 0
      }
    })

    obj.toggleSearchHandler(event.target.dataset.toggletype)

    //add filters
    const filterType = event.target.dataset.statetype
    if (filterType === 'propertyTypeState') {
      // let values = obj.state[event.target.dataset.statetype].value
      let values = obj.props.searchParams[event.target.dataset.statetype].value
      let newValues = values.map(value => value.toLowerCase())
      obj.props.addNewFilter('house_type', [...newValues])
    }
  } 
  // old code when connecting landing to buy search
  // else if (searchParams.propertyTypeParams || searchParams.locationParams) {
  //   if (
  //     searchParams.propertyTypeParams &&
  //     searchParams.propertyTypeParams.length > 0
  //   ) {
  //     let propertyTypeValues = searchParams.propertyTypeParams.map(value =>
  //       value.toLowerCase()
  //     )
  //     obj.props.addNewFilter('house_type', [...propertyTypeValues])
  //   }
  //   if (searchParams.locationParams) {
  //     let locationValues = searchParams.locationParams.split(' ')
  //     locationValues = locationValues.map(val => val.toLowerCase())
  //     handleLocationSubmit(null, obj, obj, [...locationValues])
  //   }
  // }
}

/**
 * @param {object} event
 * @param {string} type
 * @param {object} obj
 * @param {object} obj2
 * @fires removeFilter() from props
 * @listens onClick on (x) button inside search buttons
 * @returns none
 * @description this method fires to reset form value
 */
export const resetFormHandler = (event, type, obj, obj2) => {
  console.log(type);
  console.log(obj);
  if (type === 'room') {
    obj2.props.removeFilter('bedrooms')
  } else if (type === 'bath') {
    obj2.props.removeFilter('bathrooms')
  } else if (type === 'location') {
    obj2.props.removeFilter('place');
    obj2.props.onRemoveSearch(
      {[type + 'State']: {
        triggered: false,
        selected: 0,
        value: null
      }}
    );
  } else if (type === 'propertyType') {
    obj2.props.removeFilter('house_type');
    obj2.props.onRemoveSearch(
      {[type + 'State']: {
        triggered: false,
        selected: 0,
        value: []
      }}
    );
  } else if (type === 'floor') {
    obj2.props.removeFilter('floor')
  } else if (type === 'spaceState') {
    //remove space filter
    obj2.props.removeRangeFilter('land')
  } else if (type === 'delivery') {
    obj2.props.removeFilter('delivery_date')
  } else if (type === 'developer') {
    obj2.props.removeFilter('developer_name')
  } else if (type === 'compound') {
    obj2.props.removeFilter('compound_name')
  } else if (type === 'monthlyPayment') {
    obj2.props.removeRangeFilter('monthlyPayment')
  } else if (type === 'downpayment') {
    obj2.props.removeRangeFilter('downpayment');
    obj2.props.onRemoveSearch(
      {
        monthlyPaymentSearch: true,
        downpaymentSearch: true,
        priceState: {
          triggered: false,
          monthlyPayment: {
            min: 0,
            max: 0
          },
          downpaymentRange: {
            min: 0,
            max: 0
          }
        }
      }
    );
  }
  if (type === 'location') {
    obj2.props.removeFilter('place')

    // obj2.setState({
    //   [type + 'State']: {
    //     triggered: false,
    //     selected: 0,
    //     value: null
    //   },
    //   developerPlace: null
    // })
    obj2.props.onAddSearch({
      [type + 'State']: {
        triggered: false,
        selected: 0,
        value: null
      },
      developerPlace: null
    })
  } else if (type === 'spaceState') {
    // obj2.setState({
    //   [type]: {
    //     triggered: false,
    //     min: 0,
    //     max: 0
    //   }
    // })
    obj2.props.onAddSearch({
      [type]: {
        triggered: false,
        min: 0,
        max: 0
      }
    })
  } else if (type === 'priceState') {
    // obj2.setState({
    //   priceState: {
    //     triggered: false,
    //     monthlyPayment: {
    //       min: 0,
    //       max: 0
    //     },
    //     downpaymentRange: {
    //       min: 0,
    //       max: 0
    //     }
    //   }
    // })
    obj2.props.onAddSearch({
      priceState: {
        triggered: false,
        monthlyPayment: {
          min: 0,
          max: 0
        },
        downpaymentRange: {
          min: 0,
          max: 0
        }
      }
    })
  } else if (type === 'developer') {
    // obj2.setState({
    //   [type + 'State']: {
    //     triggered: false,
    //     selected: 0,
    //     value: null
    //   },
    //   developerName: null
    // })
    obj2.props.onAddSearch({
      [type + 'State']: {
        triggered: false,
        selected: 0,
        value: null
      },
      developerName: null
    })
  } else if (type === 'compound') {
    // obj2.setState({
    //   [type + 'State']: {
    //     triggered: false,
    //     selected: 0,
    //     value: null
    //   }
    // })
    obj2.props.onAddSearch({
      [type + 'State']: {
        triggered: false,
        selected: 0,
        value: null
      }
    })
  } else {
    if (type === 'monthlyPayment' || type === 'downpayment') {
      // obj2.setState({
      //   priceState: {
      //     triggered: false,
      //     monthlyPayment: {
      //       min: 0,
      //       max: 0
      //     },
      //     downpaymentRange: {
      //       min: 0,
      //       max: 0
      //     }
      //   }
      // })
      obj2.props.onAddSearch({
        priceState: {
          triggered: false,
          monthlyPayment: {
            min: 0,
            max: 0
          },
          downpaymentRange: {
            min: 0,
            max: 0
          }
        }
      })
    } else {
      for (let r = 0; r < obj.state[type + 's'].length; r++) {
        let _ref = [type] + r
        obj.refs[_ref].checked = false
      }
      // obj2.setState({
      //   [type + 'State']: {
      //     triggered: false,
      //     selected: 0,
      //     value: []
      //   }
      // })
      obj2.props.onAddSearch({
        [type + 'State']: {
          triggered: false,
          selected: 0,
          value: []
        }
      })
    }
  }
  event.stopPropagation()
}

// export const downpaymentScaleHandler = (value, obj) => {
//   if (obj.state.downpaymentMaxInput <= 100000000) {
//     if (value.max < 1000000) {f
//       obj.setState({ downpaymentMaxInput: 1200000 })
//       obj.setState({ downpaymentStep: 50000 })
//     } else if (value.max === obj.state.downpaymentMaxInput) {
//       obj.setState({ downpaymentStep: 100000 })
//       obj.setState({
//         downpaymentMaxInput: obj.state.downpaymentMaxInput + 2000000
//       })
//     }
//   }
//   if (value.min > obj.state.downpaymentStep) {
//     obj.setState({ downpaymentMinInput: value.min - obj.state.downpaymentStep })
//   }
//   obj.setState({
//     priceState: {
//       downpaymentRange: {
//         min: value.min,
//         max: value.max
//       }
//     }
//   })
// }

// export const monthlyPaymentScaleHandler = (value, obj) => {
//   if (obj.state.monthlyPaymentMaxInput <= 100000) {
//     if (value.max < 100000) {
//       obj.setState({ monthlyPaymentMaxInput: 50000 })
//       obj.setState({ monthlyPaymentStep: 1000 })
//     } else if (value.max === obj.state.monthlyPaymentMaxInput) {
//       obj.setState({ monthlyPaymentStep: 1000 })
//       obj.setState({
//         monthlyPaymentMaxInput: obj.state.monthlyPaymentMaxInput + 50000
//       })
//     }
//   }
//   if (value.min > obj.state.monthlyPaymentStep) {
//     obj.setState({
//       monthlyPaymentMinInput: value.min - obj.state.monthlyPaymentStep
//     })
//   }

//   obj.setState({
//     priceState: {
//       monthlyPayment: {
//         min: value.min,
//         max: value.max
//       }
//     }
//   })
// }

export const spaceInputHandler = (event, obj) => {
  // obj.setState({ [event.target.dataset.type]: event.target.value })
  obj.props.onAddSearch({ [event.target.dataset.type]: event.target.value })
}

/**
 * @param {string} type
 * @param {Object} obj
 * @fires setState
 * @listens toggleSearchHandler method
 * @description This method used to change the state of search types to show or hide search options
 */
export const toggleSearchHandler = (type, obj) => {
  let oldToggleState = { ...obj.state.toggleState }
  for (let singleToggler in oldToggleState) {
    oldToggleState[singleToggler] = true
  }

  obj.setState({
    toggleState: {
      ...oldToggleState,
      [type]: !obj.state.toggleState[type]
    }
  })
  // obj.props.onAddSearch({
  //   toggleState: {
  //     ...oldToggleState,
  //     [type]: !obj.state.toggleState[type]
  //   }
  // })
}

export const paymentTypeHandler = (event, obj) => {
  // let payments = obj.state.paymentTypes
  // let payments = obj.props.searchParams.paymentTypes
  // if (payments.includes(event.target.value)) {
  //   payments = payments.filter(payment => payment !== event.target.value)
  // } else {
  //   payments = [...obj.props.searchParams.paymentTypes, event.target.value]
  // }
  
  // obj.setState({
  //   ...obj.state,
  //   paymentTypes: payments,
  //   [[event][0].target.value]: !obj.state[[[event][0].target.value]]
  // })
  obj.props.onAddSearch({
    // ...obj.state,
    // paymentTypes: payments,
    [[event][0].target.value]: !obj.props.searchParams[[[event][0].target.value]]
  })
}
/**
 * Handel change price
 */
export const handleChangeDownpayment = (value, obj) => {
  // obj.setState({ rangeInputDownpaymentValue: value })
  obj.props.onAddSearch({ rangeInputDownpaymentValue: value })
}
/**
 * Handel change price
 */
export const handleChangeMonthlyPayment = (value, obj) => {
  // obj.setState({ rangeInputMonthlyPaymentValue: value })
  obj.props.onAddSearch({ rangeInputMonthlyPaymentValue: value })
}

/**
 * @param {Object} obj
 * @fires setState
 * @listens minifymin method
 * @description This method used to decrease the value of 'minSpaceVal' property by one
 */
// export const minifymin = obj => {
//   if (obj.state.minSpaceVal > 0) {
//     obj.setState({ minSpaceVal: parseInt(obj.state.minSpaceVal) - 50 })
//   }
// }

/**
 * @param {Object} obj
 * @fires setState
 * @listens plusifymin method
 * @description This method used to increase the value of 'minSpaceVal' property by one
 */
// export const plusifymin = obj => {
//   if (obj.state.maxSpaceVal > obj.state.minSpaceVal) {
//     obj.setState({ minSpaceVal: parseInt(obj.state.minSpaceVal) + 50 })
//   }
// }

/**
 * @param {Object} obj
 * @fires setState
 * @listens minifymax method
 * @description This method used to decrease the value of 'maxSpaceVal' property by one
 */
// export const minifymax = obj => {
//   if (obj.state.maxSpaceVal > 0) {
//     obj.setState({ maxSpaceVal: parseInt(obj.state.maxSpaceVal) - 50 })
//   }
// }

/**
 * @param {Object} obj
 * @fires setState
 * @listens plusifymax method
 * @description This method used to increase the value of 'maxSpaceVal' property by one
 */
// export const plusifymax = obj => {
//   obj.setState({ maxSpaceVal: parseInt(obj.state.maxSpaceVal) + 50 })
// }

export const handleLocationSubmit = (event, obj, obj2, landLocation = {}) => {
  if (event) {
    obj2.props.addNewFilter('place', [event.target.dataset.value])
  } else {
    obj2.props.addNewFilter('city', landLocation)
  }

  // obj2.setState({
  //   locationState: {
  //     triggered: true,
  //     selected: 0,
  //     value: event ? event.target.dataset.value : landLocation[0]
  //   }
  // })
  obj2.props.onAddSearch({
    locationState: {
      triggered: true,
      selected: 0,
      value: event ? event.target.dataset.value : landLocation[0]
    }
  })
  for (let r = 0; r < obj2.state.cities.length; r++) {
  // for (let r = 0; r < obj2.props.searchParams.cities.length; r++) {
    let _refBtn = 'locationCollapseBtn' + r
    let _ref = 'locationCollapse' + r
    obj.refs[_refBtn].setAttribute('aria-expanded', 'false')
    obj.refs[_ref].classList.remove('show')
  }
  if (event) {
    obj2.toggleSearchHandler('location')
  }
}
export const handleDeveloperSubmit = (event, obj, obj2) => {
  obj2.props.addNewFilter('developer_name', [event.target.dataset.value])

  // obj2.setState({
  //   developerState: {
  //     triggered: true,
  //     selected: 0,
  //     value: event.target.dataset.value
  //   }
  // })
  obj2.props.onAddSearch({
    developerState: {
      triggered: true,
      selected: 0,
      value: event.target.dataset.value
    }
  })
  obj2.toggleSearchHandler('developer')
}
export const handleCompoundSubmit = (event, obj, obj2) => {
  obj2.props.addNewFilter('compound_name', [event.target.dataset.value])

  // obj2.setState({
  //   compoundState: {
  //     triggered: true,
  //     selected: 0,
  //     value: event.target.dataset.value
  //   }
  // })
  obj2.props.onAddSearch({
    compoundState: {
      triggered: true,
      selected: 0,
      value: event.target.dataset.value
    }
  })
  obj2.toggleSearchHandler('compound')
}
/**
 * @param {Object} event
 * @param {Object} obj
 * @fires setState
 * @listens handleChange method
 * @description This method used to change the state of the search field being modified
 */
export const handleChange = (event, obj, searchParams = {}) => {
  if (event) {
    // let oldState = { ...obj.state[[event][0].target.dataset.statetype] }
    let oldState = { ...obj.props.searchParams[[event][0].target.dataset.statetype] }
    let oldValues = oldState.value
    const inputValue = event.target.value

    // to know whether the value is being added or removed
    // true = added
    if (event.target.checked === true) {
      // obj.setState({
      //   [event.target.dataset.statetype]: {
      //     ...oldState,
      //     value: [...oldValues, inputValue]
      //   }
      // })
      obj.props.onAddSearch({
        [event.target.dataset.statetype]: {
          ...oldState,
          value: [...oldValues, inputValue]
        }
      })
    }
    // to know whether the value is being added or removed
    // false = removed
    else if (event.target.checked === false) {
      const index = oldValues.indexOf(inputValue)
      if (index > -1) {
        oldValues.splice(index, 1)
      }
      // obj.setState({
      //   [event.target.dataset.statetype]: {
      //     ...oldState,
      //     value: [...oldValues]
      //   }
      // })
      obj.props.onAddSearch({
        [event.target.dataset.statetype]: {
          ...oldState,
          value: [...oldValues]
        }
      })
    }
  } 
  // old code when connecting landing to buy search
  // else {
  //   alert("!!!!!!!!!!!!!!!!!!!!!!!")
  //   if (searchParams.propertyTypeParams) {
  //     // console.log(searchParams.propertyTypeParams);
  //     // obj.setState({
  //     //   propertyTypeState: {
  //     //     triggered: true,
  //     //     selected: 0,
  //     //     value: searchParams.propertyTypeParams
  //     //   }
  //     // })
  //     obj.props.onAddSearch({
  //       propertyTypeState: {
  //         triggered: true,
  //         selected: 0,
  //         value: searchParams.propertyTypeParams
  //       }
  //     })
  //   }
  //   if (searchParams.locationParams) {
  //     // console.log(searchParams.locationParams);
  //     // obj.setState({
  //     //   textlocationState: {
  //     //     triggered: true,
  //     //     selected: 0,
  //     //     value: searchParams.locationParams
  //     //   }
  //     // })
  //     obj.props.onAddSearch({
  //       textlocationState: {
  //         triggered: true,
  //         selected: 0,
  //         value: searchParams.locationParams
  //       }
  //     })
  //   }
  // }
}
